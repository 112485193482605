import React from 'react';

export function Reports() {

  return (
    <div>
      <h1>Reports</h1>
      <p>Page is under construction</p>
    </div>
  );
}
