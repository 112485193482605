import React, { useCallback, useEffect } from "react";
import { IManageProjectModel } from "../../ProjectDetail/IProject";
import ProjectServices from "../../ProjectDetail/ProjectServices";
import { Autocomplete, Box, Tab, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Loading } from "../../Shared/Loading";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Messages } from "../../Shared/constant";
import dayjs, { Dayjs } from 'dayjs';
import { IManageActivityPageReportModel, IActivityPageSummaryValue, ISummaryReportModel, ISummaryReportRequest, IReportNotes, IChartData, IActivityPageReportRequest } from "../IReports";
import { DataGrid } from "devextreme-react";
import ReportServices from "../ReportsServices";
import { Column, Sorting } from "devextreme-react/data-grid";
import { formatNumber } from "devextreme/localization";
import LineAxisIcon from '@mui/icons-material/LineAxis';
import IsoIcon from '@mui/icons-material/Iso';
import { SummaryOverrideModalDialog } from '../Summary/SummaryOverridesModal';
import { ForecastOverrideModalDialog } from '../Summary/ForecastOverridesModal';
import { CommonSeriesSettings, Grid, Legend, Series, ValueAxis, Chart, Tooltip, Label, Point } from "devextreme-react/chart";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const defaultString: string[] = [];
const defaultValues: any = {
    activityId: 0, combinedName: "", actualProduction: 0, toDateProduction: 0, projectedProduction: 0, budgetProduction: 0, requiredProduction: 0,
    actualCrewRate: 0, toDateCrewRate: 0, projectedCrewRate: 0, budgetCrewRate: 0, dateValues: ""
};

export default function ActivityOnAPageReport() {
    const [isLoadReportData, setIsLoadReportData] = React.useState<boolean>(false);
    const [projects, setProjects] = React.useState<IManageProjectModel[]>([]);
    const [manageProjectId, setManageProjectId] = React.useState<number>(0);
    const [manageStartDate, setManageStartDate] = React.useState<any>(null);
    const [manageEndDate, setManageEndDate] = React.useState<any>(null);
    const [groupList, setGroupList] = React.useState<string[]>(defaultString);
    const [groupScopeList, setGroupScopeList] = React.useState<string[]>(defaultString);
    const [groupActivityList, setGroupActivityList] = React.useState<string[]>(defaultString);
    const [groupAreaList, setGroupAreaList] = React.useState<string[]>(defaultString);
    const [groupCostCodeList, setCostCodeList] = React.useState<string[]>(defaultString);
    const [searchFilter, setSearchFilter] = React.useState({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
    const [errProjectCode, setErrProjectCode] = React.useState<boolean>(false);
    const [errStartDate, setErrStartDate] = React.useState<boolean>(false);
    const [errEndDate, setErrEndDate] = React.useState<boolean>(false);
    const [errMessageProjectCode, setErrMessageProjectCode] = React.useState("");
    const [errMessageStartDate, setErrMessageStartDate] = React.useState("");
    const [errMessageEndDate, setErrMessageEndDate] = React.useState("");
    const [manageActivityData, setManageActivityData] = React.useState<ISummaryReportModel[]>([]);
    const [manageActivityDataList, setmanageActivityDataList] = React.useState<ISummaryReportModel[]>([]);
    const [manageReportData, setManageReportData] = React.useState<IManageActivityPageReportModel[]>([]);
    const [reloadModalPopup, setReloadModalPopup] = React.useState<boolean>(false);
    const [manageActivity, setManageActivity] = React.useState(defaultValues);
    const [manageForecast, setManageForecast] = React.useState(defaultValues);
    const [openSummaryModaldialog, setOpenSummaryModal] = React.useState<boolean>(false);
    const [openForecastModaldialog, setOpenForecastModal] = React.useState<boolean>(false);
    const [isSingleActivity, setIsSingleActivity] = React.useState<boolean>(false);
    const summaryColumn: string[] = ['Budget', 'To-Date', 'Date Filtered', 'Remaining', 'Projected', 'Forecasted'];
    const summaryRow: string[] = ['Hours', 'Dollars', 'Qty (Units)', 'Rate (Units)', 'Crew Rate', 'To-Date Crew Rate Saving'];
    const [manageReportNotes, setManageReportNotes] = React.useState<IReportNotes[]>([]);
    const [manageReportChartData, setManageReportChartData] = React.useState<IChartData[]>([]);
    const [manageSeriesTitle, setManageSeriesTitle] = React.useState("");
    const [manageTab, setManageTab] = React.useState('1');
    const isNullUndefined = (value: any) => value === null || value === undefined || value === "";

    useEffect(() => {
        const prevMonday = getPreviousWeekDays(true);
        const prevSunday = getPreviousWeekDays(false);
        setManageStartDate(dayjs(prevMonday).format("YYYY-MM-DDThh:mm:ss"));
        setManageEndDate(dayjs(prevSunday).format("YYYY-MM-DDThh:mm:ss"));
        getProjects();
    }, []);

    useEffect(() => {
        if (manageActivityData.length > 0) {
            bindDropdowns(searchFilter);
            setReportData();
            const isSingleActivity = manageActivityData.map(item => item.activityId).filter((value, index, current_value) => current_value.indexOf(value) === index).length === 1 ? true : false;
            setIsSingleActivity(isSingleActivity);
        }
    }, [manageActivityData]);

    useEffect(() => {
        //bindDropdowns(searchFilter);
        setReportData();

        if (isSingleActivity) {
            const activityId = manageActivityData[0].activityId;
            const productionRate = calculateSummaryValues(summaryRow[3].toLocaleLowerCase(), summaryColumn[0].toLocaleLowerCase());
            const unit = manageActivityData[0].units;
            setManageSeriesTitle("Production Rate \n Budget | " + formatNumber(productionRate, "###,##0.00;(###,##0.00)") + " " + unit);

            getReportNotes(activityId);
            getReportChartData(activityId);
        }
    }, [isSingleActivity])

    const getPreviousWeekDays = (isMonday: boolean) => {
        const currentDate = new Date();
        const date = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        const day = date.getDay();
        const prevMonday = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        if (day === 0) {
            prevMonday.setDate(date.getDate() - 6);
        }
        else {
            prevMonday.setDate(date.getDate() - (day - 1));
        }

        return isMonday ? prevMonday : prevMonday.setDate(prevMonday.getDate() + 6);
    }

    const getProjects = async () => {
        ProjectServices.GetProjectDetails(0).then((res) => {
            if (!res.hasErrors && res.value) {
                const localProjects = res.value.filter((x) => x.parent === null && x.isActive === true && x.isTracked === true);
                setProjects(localProjects);
            }
        });
    };

    const getReportData = async () => {
        setIsLoadReportData(true);
        const summaryReportRequest: ISummaryReportRequest = {
            projectId: manageProjectId,
            startDate: manageStartDate,
            endDate: manageEndDate
        };

        await ReportServices.GetSummaryReport(summaryReportRequest).then((res) => {
            if (!res.hasErrors && res.value) {
                setManageActivityData(res.value);
                setmanageActivityDataList(res.value);
            }
        });
    }

    const getReportNotes = async (activityId: number) => {
        const activityPageReportRequest: IActivityPageReportRequest = {
            activityId: activityId,
            startDate: manageStartDate,
            endDate: manageEndDate
        };

        await ReportServices.GetReportNotes(activityPageReportRequest).then((res) => {
            if (!res.hasErrors && res.value) {
                setManageReportNotes(res.value);
            }
        });
    }

    const getReportChartData = async (activityId: number) => {
        await ReportServices.GetReportChartData(activityId).then((res) => {
            if (!res.hasErrors && res.value) {
                let data = res.value;
                data.forEach(item => {
                    //item.productionRate = formatProductionRate(item.unit, item.actualProduction);
                    item.productionLabelRate = formatProductionRate(item.unit, item.actualProduction);
                    item.timelineLabelHrs = formatTimelineHrs(item.hours);
                    item.timelineLabelQty = item.qty === 0 ? "-" : item.qty + ' ' + item.unit;
                    item.timelinePerCompHrs = +(item.percentageCompleteHrs * 100).toFixed();
                    item.timelinePerCompQty = +(item.percentageCompleteQty * 100).toFixed();
                });
                setManageReportChartData(data);
            }
        });
    }

    const formatProductionRate = (unit: string, actualProduction: number) => {
        let value = "";
        if (actualProduction === 0) {
            value = '-';
        } else {
            if (unit === 'wks' && actualProduction === 1) {
                value = '1 hr';
            } else if (unit === 'wks' && actualProduction !== 1) {
                value = formatNumber(actualProduction, "###,##0;(###,##0)") + ' hrs';
            } else {
                value = formatNumber(actualProduction, "###,##0;(###,##0)") + ' ' + unit + '/hr';
            }
        }
        return value;
    }

    const formatTimelineHrs = (hours: number) => {
        let value = "";
        if (hours === 0) {
            value = '-';
        } else {
            if (hours === 1) {
                value = '1 hr';
            } else {
                value = formatNumber(hours, "###,##0;(###,##0)") + ' hrs';
            }
        }
        return value;
    }

    const resetErrorState = async () => {
        setErrProjectCode(false);
        setErrStartDate(false);
        setErrEndDate(false);
        setGroupList(defaultString);
        setGroupScopeList(defaultString);
        setGroupActivityList(defaultString);
        setGroupAreaList(defaultString);
        setCostCodeList(defaultString);
        setSearchFilter({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
    };

    const loadSummaryReport = async () => {
        resetErrorState();
        let isValid = true;
        if (manageProjectId === 0) {
            setErrProjectCode(true);
            setErrMessageProjectCode(Messages.Reports.requiredProjectCode);
            isValid = false;
        }

        if (manageStartDate !== null && manageStartDate.toLowerCase() === 'invalid date') {
            setErrStartDate(true);
            setErrMessageStartDate(Messages.Reports.invalidStartDate);
            isValid = false;
        }

        if (manageStartDate !== null && manageEndDate === null) {
            setErrEndDate(true);
            setErrMessageEndDate(Messages.Reports.requiredEndDate);
            isValid = false;
        }

        if (manageEndDate !== null && manageEndDate.toLowerCase() === 'invalid date') {
            setErrEndDate(true);
            setErrMessageEndDate(Messages.Reports.invalidEndDate);
            isValid = false;
        }

        if (manageEndDate !== null && manageStartDate === null) {
            setErrStartDate(true);
            setErrMessageStartDate(Messages.Reports.requiredStartDate);
            isValid = false;
        }

        if (manageStartDate !== null && manageEndDate !== null) {
            if (manageEndDate < manageStartDate) {
                setErrEndDate(true);
                setErrMessageEndDate(Messages.Reports.dateRangeValidation);
                isValid = false;
            }
        }

        if (isValid) {
            getReportData();
        }
    }

    const handleChange = (value: Dayjs | any, cntrlName: string) => {
        if (value === undefined || value === null || value === "" || value === 0) {
            switch (cntrlName) {
                case 'ProjectCode':
                    setErrProjectCode(true);
                    setErrMessageProjectCode(Messages.Reports.requiredProjectCode);
                    setManageProjectId(0);
                    break;
                case 'StartDate':
                    setErrStartDate(true);
                    setErrMessageStartDate(Messages.Reports.requiredStartDate);
                    setManageStartDate(null);
                    break;
                case 'EndDate':
                    setErrEndDate(true);
                    setErrMessageEndDate(Messages.Reports.requiredEndDate);
                    setManageEndDate(null);
                    break;
            }
        } else {
            switch (cntrlName) {
                case 'ProjectCode':
                    const pId = projects.filter(p => p.projectCode === value).map(p => p.id);
                    setManageProjectId(pId[0]);
                    break;
                case 'StartDate':
                    setManageStartDate(dayjs(value).format("YYYY-MM-DDThh:mm:ss"));
                    break;
                case 'EndDate':
                    setManageEndDate(dayjs(value).format("YYYY-MM-DDThh:mm:ss"));
                    break;
            }
        }
    };

    const searchActivity = (value: string | null, controlName: string) => {
        setFilterCriteria(value, controlName);
        bindDropdowns(searchFilter);

        const filterData = manageActivityDataList.filter(f => f.group === (!isNullUndefined(searchFilter.group) ? searchFilter.group : f.group) &&
            f.scope === (!isNullUndefined(searchFilter.groupScope) ? searchFilter.groupScope : f.scope) &&
            f.activity === (!isNullUndefined(searchFilter.groupActivity) ? searchFilter.groupActivity : f.activity) &&
            f.area === (!isNullUndefined(searchFilter.groupArea) ? searchFilter.groupArea : f.area) &&
            f.costCode === (!isNullUndefined(searchFilter.groupCostCode) ? searchFilter.groupCostCode : f.costCode));

        setManageActivityData(filterData);
        setReportData();
    }

    // function to bind activity dropdowns
    const bindDropdowns = (filterObj: any) => {
        const dropdownList = manageActivityDataList.filter(f => f.group === (!isNullUndefined(filterObj.group) ? filterObj.group : f.group)
            && f.scope === (!isNullUndefined(filterObj.groupScope) ? filterObj.groupScope : f.scope)
            && f.activity === (!isNullUndefined(filterObj.groupActivity) ? filterObj.groupActivity : f.activity)
            && f.area === (!isNullUndefined(filterObj.groupArea) ? filterObj.groupArea : f.area)
            && f.costCode === (!isNullUndefined(filterObj.groupCostCode) ? filterObj.groupCostCode : f.costCode));
        setGroupList(dropdownList.map(item => item.group).filter((value, index, current_value) => current_value.indexOf(value) === index));
        setGroupScopeList(dropdownList.map(item => item.scope).filter((value, index, current_value) => current_value.indexOf(value) === index));
        setGroupActivityList(dropdownList.map(item => item.activity).filter((value, index, current_value) => current_value.indexOf(value) === index));
        setGroupAreaList(dropdownList.map(item => item.area).filter((value, index, current_value) => current_value.indexOf(value) === index));
        setCostCodeList(dropdownList.map(item => item.costCode).filter((value, index, current_value) => current_value.indexOf(value) === index));
    }

    // set filter criteria
    const setFilterCriteria = (value: string | null, controlName: string) => {
        switch (controlName) {
            case "Group":
                searchFilter.group = value as string;
                break;
            case "Group Scope":
                searchFilter.groupScope = value as string;
                break;
            case "Group Activity":
                searchFilter.groupActivity = value as string;
                break;
            case "Group Area":
                searchFilter.groupArea = value as string;
                break;
            case "Group Cost Code":
                searchFilter.groupCostCode = value as string;
                break;
        }

        setSearchFilter(searchFilter);
    }

    const setReportData = () => {
        let reportData: IManageActivityPageReportModel[] = [];
        summaryRow.forEach(rowItem => {
            let data: IActivityPageSummaryValue = { budget: 0, todate: 0, dataFilter: 0, remaining: 0, projected: 0, forecasted: 0 };
            let rowData: IManageActivityPageReportModel = { summary: '', summaryValue: data };
            rowData.summary = rowItem;
            rowData.summaryValue.budget = calculateSummaryValues(rowItem.toLocaleLowerCase(), summaryColumn[0].toLocaleLowerCase());
            rowData.summaryValue.todate = calculateSummaryValues(rowItem.toLocaleLowerCase(), summaryColumn[1].toLocaleLowerCase());
            rowData.summaryValue.dataFilter = calculateSummaryValues(rowItem.toLocaleLowerCase(), summaryColumn[2].toLocaleLowerCase());
            rowData.summaryValue.remaining = calculateSummaryValues(rowItem.toLocaleLowerCase(), summaryColumn[3].toLocaleLowerCase());
            rowData.summaryValue.projected = calculateSummaryValues(rowItem.toLocaleLowerCase(), summaryColumn[4].toLocaleLowerCase());
            rowData.summaryValue.forecasted = calculateSummaryValues(rowItem.toLocaleLowerCase(), summaryColumn[5].toLocaleLowerCase());
            reportData.push(rowData);
        });

        setManageReportData(reportData);
        setIsLoadReportData(false);
    }

    const calculateSummaryValues = (rowCategory: string, colCategory: string) => {
        let total = 0;
        if (rowCategory.toLocaleLowerCase() === summaryRow[0].toLocaleLowerCase().toString()) {
            switch (colCategory) {
                case summaryColumn[0].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((budget, o) => budget + o.budgetHours, 0);
                    break;
                case summaryColumn[1].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((toDate, o) => toDate + o.toDateHours, 0);
                    break;
                case summaryColumn[2].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((actual, o) => actual + o.actualHours, 0);
                    break;
                case summaryColumn[3].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((remaining, o) => remaining + o.remainingMhrs, 0);
                    break;
                case summaryColumn[4].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((projected, o) => projected + o.projectedOverUnderMhrs, 0);
                    break;
                case summaryColumn[5].toLocaleLowerCase().toString():
                    const budgetHours = manageActivityData.reduce((budget, o) => budget + o.budgetHours, 0);
                    const toDateHours = manageActivityData.reduce((toDate, o) => toDate + o.toDateHours, 0);
                    const remainingQty = manageActivityData.reduce((remaining, o) => remaining + o.remainingQty, 0);
                    const forecastedProduction = manageActivityData.reduce((forecasted, o) => forecasted + o.forecastedProduction, 0);
                    const temp = isFinite(remainingQty / forecastedProduction) ? (remainingQty / forecastedProduction) : 0;
                    total = budgetHours - (temp + toDateHours);
                    break;
            }
        }

        if (rowCategory.toLocaleLowerCase() === summaryRow[1].toLocaleLowerCase().toString()) {
            switch (colCategory) {
                case summaryColumn[0].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((budget, o) => budget + o.budgetDollar, 0);
                    break;
                case summaryColumn[1].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((toDate, o) => toDate + o.toDateDollar, 0);
                    break;
                case summaryColumn[2].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((actual, o) => actual + o.actualDollar, 0);
                    break;
                case summaryColumn[3].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((remaining, o) => remaining + o.remainingDollar, 0);
                    break;
                case summaryColumn[4].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((projected, o) => projected + o.projectedOverUnderDollar, 0);
                    break;
                case summaryColumn[5].toLocaleLowerCase().toString():
                    const budgetDollar = manageActivityData.reduce((budget, o) => budget + o.budgetDollar, 0);
                    const toDateDollar = manageActivityData.reduce((toDate, o) => toDate + o.toDateDollar, 0);
                    const remainingQty = manageActivityData.reduce((remaining, o) => remaining + o.remainingQty, 0);
                    const forecastedProduction = manageActivityData.reduce((forecasted, o) => forecasted + o.forecastedProduction, 0);
                    const forecastedCrewRate = manageActivityData.reduce((forecasted, o) => forecasted + o.forecastedCrewRate, 0);
                    const temp = isFinite(remainingQty / forecastedProduction) ? (remainingQty / forecastedProduction) : 0;
                    total = budgetDollar - (forecastedCrewRate * temp + toDateDollar);
                    break;
            }
        }

        if (rowCategory.toLocaleLowerCase() === summaryRow[2].toLocaleLowerCase().toString()) {
            switch (colCategory) {
                case summaryColumn[0].toLocaleLowerCase().toString():
                    manageActivityData.forEach(b => {
                        total += b.unit === 'wks' ? +b.budgetQty.toFixed(1) : +b.budgetQty.toFixed();
                    });
                    //total = manageActivityData.reduce((budget, o) => budget + o.budgetQty, 0);
                    break;
                case summaryColumn[1].toLocaleLowerCase().toString():
                    manageActivityData.forEach(t => {
                        total += t.unit === 'wks' ? +t.toDateQty.toFixed(1) : +t.toDateQty.toFixed();
                    });
                    //total = manageActivityData.reduce((toDate, o) => toDate + o.toDateQty, 0);
                    break;
                case summaryColumn[2].toLocaleLowerCase().toString():
                    manageActivityData.forEach(a => {
                        total += a.unit === 'wks' ? +a.actualQty.toFixed(1) : +a.actualQty.toFixed();
                    });
                    //total = manageActivityData.reduce((actual, o) => actual + o.actualQty, 0);
                    break;
                case summaryColumn[3].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((remaining, o) => remaining + o.remainingQty, 0);
                    break;
                case summaryColumn[4].toLocaleLowerCase().toString():
                    total = 0;
                    break;
                case summaryColumn[5].toLocaleLowerCase().toString():
                    total = 0;
                    break;
            }
        }

        if (rowCategory.toLocaleLowerCase() === summaryRow[3].toLocaleLowerCase().toString()) {
            const unit = manageActivityData.map(item => item.unit)[0];
            switch (colCategory) {
                case summaryColumn[0].toLocaleLowerCase().toString():
                    const budgetHours = manageActivityData.reduce((budget, o) => budget + o.budgetHours, 0);
                    let budgetQty = 0;// manageActivityData.reduce((budget, o) => budget + o.budgetQty, 0);
                    manageActivityData.forEach(b => {
                        budgetQty += b.unit === 'wks' ? +b.budgetQty.toFixed(1) : +b.budgetQty.toFixed();
                    });
                    //total = isFinite(budgetQty / budgetHours) ? (budgetQty / budgetHours) : 0;

                    if (isSingleActivity && (unit === 'wks' || unit === 'ea' || unit === '%')) {
                        const budProd = isFinite(budgetQty / budgetHours) ? (budgetQty / budgetHours) : 0;
                        total = isFinite(1 / budProd) ? (1 / budProd) : 0;
                    } else {
                        total = isFinite(budgetQty / budgetHours) ? (budgetQty / budgetHours) : 0;
                    }
                    break;
                case summaryColumn[1].toLocaleLowerCase().toString():
                    const toDateHours = manageActivityData.reduce((toDate, o) => toDate + o.toDateHours, 0);
                    let toDateQty = 0;// manageActivityData.reduce((toDate, o) => toDate + o.toDateQty, 0);
                    manageActivityData.forEach(t => {
                        toDateQty += t.unit === 'wks' ? +t.toDateQty.toFixed(1) : +t.toDateQty.toFixed();
                    });
                    //total = isFinite(toDateQty / toDateHours) ? (toDateQty / toDateHours) : 0;

                    if (isSingleActivity && (unit === 'wks' || unit === 'ea' || unit === '%')) {
                        const toDateProd = isFinite(toDateQty / toDateHours) ? (toDateQty / toDateHours) : 0;
                        total = isFinite(1 / toDateProd) ? (1 / toDateProd) : 0;
                    } else {
                        total = isFinite(toDateQty / toDateHours) ? (toDateQty / toDateHours) : 0;
                    }
                    break;
                case summaryColumn[2].toLocaleLowerCase().toString():
                    const actualHours = manageActivityData.reduce((actual, o) => actual + o.actualHours, 0);
                    let actualQty = 0;// manageActivityData.reduce((actual, o) => actual + o.actualQty, 0);
                    manageActivityData.forEach(a => {
                        actualQty += a.unit === 'wks' ? +a.actualQty.toFixed(1) : +a.actualQty.toFixed();
                    });
                    //total = isFinite(actualQty / actualHours) ? (actualQty / actualHours) : 0;

                    if (isSingleActivity && (unit === 'wks' || unit === 'ea' || unit === '%')) {
                        const actualProd = isFinite(actualQty / actualHours) ? (actualQty / actualHours) : 0;
                        total = isFinite(1 / actualProd) ? (1 / actualProd) : 0;
                    } else {
                        total = isFinite(actualQty / actualHours) ? (actualQty / actualHours) : 0;
                    }

                    break;
                case summaryColumn[3].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((required, o) => required + o.requiredProduction, 0);
                    break;
                case summaryColumn[4].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((projected, o) => projected + o.projectedProduction, 0);
                    break;
                case summaryColumn[5].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((forecasted, o) => forecasted + o.forecastedProduction, 0);
                    break;
            }
        }

        if (rowCategory.toLocaleLowerCase() === summaryRow[4].toLocaleLowerCase().toString()) {
            switch (colCategory) {
                case summaryColumn[0].toLocaleLowerCase().toString():
                    const budgetHours = manageActivityData.reduce((budget, o) => budget + o.budgetHours, 0);
                    const budgetDollar = manageActivityData.reduce((budget, o) => budget + o.budgetDollar, 0);
                    total = isFinite(budgetDollar / budgetHours) ? (budgetDollar / budgetHours) : 0;
                    break;
                case summaryColumn[1].toLocaleLowerCase().toString():
                    const toDateHours = manageActivityData.reduce((toDate, o) => toDate + o.toDateHours, 0);
                    const toDateDollar = manageActivityData.reduce((toDate, o) => toDate + o.toDateDollar, 0);
                    total = isFinite(toDateDollar / toDateHours) ? (toDateDollar / toDateHours) : 0;
                    break;
                case summaryColumn[2].toLocaleLowerCase().toString():
                    const actualHours = manageActivityData.reduce((actual, o) => actual + o.actualHours, 0);
                    const actualDollar = manageActivityData.reduce((actual, o) => actual + o.actualDollar, 0);
                    total = isFinite(actualDollar / actualHours) ? (actualDollar / actualHours) : 0;
                    break;
                case summaryColumn[3].toLocaleLowerCase().toString():
                    total = 0;
                    break;
                case summaryColumn[4].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((projected, o) => projected + o.projectedCrewRate, 0);
                    break;
                case summaryColumn[5].toLocaleLowerCase().toString():
                    total = manageActivityData.reduce((forecasted, o) => forecasted + o.forecastedCrewRate, 0);
                    break;
            }
        }
        return total;
    }

    const onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataType === 'string') {
            if (e.row.data.summary === summaryRow[2].toString()) {
                if (e.columnIndex === 0) {
                    const innerText = e.cellElement.innerText;
                    const unit = manageActivityData.map(item => item.unit)[0];
                    e.cellElement.innerHTML = isSingleActivity ? e.cellElement.innerText.replace('(Units)', '(' + unit + ')') : innerText;
                }
            }

            if (e.row.data.summary === summaryRow[3].toString()) {
                if (e.columnIndex === 0) {
                    const innerText = e.cellElement.innerText;
                    const units = manageActivityData.map(item => item.units)[0];
                    e.cellElement.innerHTML = isSingleActivity ? e.cellElement.innerText.replace('(Units)', '(' + units + ')') : innerText;
                }
            }

            if (e.row.data.summary === summaryRow[5].toString()) {
                //e.cellElement.innerHTML = "";
                if (e.columnIndex === 0) {
                    let crewRateSaving = "";
                    const budgetHours = manageActivityData.reduce((budget, o) => budget + o.budgetHours, 0);
                    const budgetDollar = manageActivityData.reduce((budget, o) => budget + o.budgetDollar, 0);
                    const toDateHours = manageActivityData.reduce((toDate, o) => toDate + o.toDateHours, 0);
                    const toDateDollar = manageActivityData.reduce((toDate, o) => toDate + o.toDateDollar, 0);
                    const budgetTotal = isFinite(budgetDollar / budgetHours) ? (budgetDollar / budgetHours) : 0;
                    const toDateTotal = isFinite(toDateDollar / toDateHours) ? (toDateDollar / toDateHours) : 0;
                    const total = budgetTotal - toDateTotal;

                    if (budgetTotal > toDateTotal) {
                        crewRateSaving = "To-Date Crew Rate Savings: " + formatNumber(total, { type: "currency", precision: 0 });
                    } else if (budgetTotal < toDateTotal) {
                        crewRateSaving = "To-Date Crew Rate Overrun: " + formatNumber(total, { type: "currency", precision: 0 });
                    } else {
                        crewRateSaving = "";
                    }
                    e.cellElement.className = "total-crew-rate";
                    e.cellElement.innerHTML = crewRateSaving;
                }
            }
        }
        if (e.rowType === "data" && e.column.dataType === 'number') {
            if (e.row.data.summary === summaryRow[5].toString()) {
                e.cellElement.className = "total-crew-rate";
                if (e.columnIndex === 1) {
                    e.cellElement.colspan = 6;
                    e.cellElement.innerHTML = "";
                } else {
                    //e.cellElement.style.display = "none";
                }
            }

            if (e.value !== 0) {
                const existingClass = e.cellElement.className;
                if (e.value < 0) {
                    e.cellElement.className = existingClass + " negative-number";
                } else if (e.value > 0) {
                    e.cellElement.className = existingClass + " positive-number";
                } else {
                    e.cellElement.className = existingClass;
                }
            }
        }
    };

    //#region Summary modal
    const openSummaryOverride = (event, data) => {
        event.preventDefault();
        setManageActivity({
            ...manageActivity,
            activityId: data[0].activityId,
            combinedName: data[0].combinedName
        });
        setOpenSummaryModal(true);
        setReloadModalPopup(true);
    }

    const openSummaryModalEdit = () => {
        setIsLoadReportData(false);
    };

    const closeSummaryModal = () => {
        setOpenSummaryModal(false);
    };

    const handlecloseSummaryModal = () => {
        setOpenSummaryModal(false);
        setSearchFilter({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
        bindDropdowns(searchFilter);
        getReportData();
    };
    //#endregion

    //#region Forecast modal 
    const openForecastOverride = async (event, data) => {
        event.preventDefault();
        const tempStartDate = new Date(manageStartDate);
        const tempEndDate = new Date(manageEndDate);
        setManageForecast({
            ...manageForecast,
            activityId: data[0].activityId,
            combinedName: data[0].combinedName,
            actualProduction: data[0].actualProduction,
            toDateProduction: data[0].toDateProduction,
            projectedProduction: data[0].projectedProduction,
            budgetProduction: data[0].budgetProduction,
            requiredProduction: data[0].requiredProduction,
            actualCrewRate: data[0].actualCrewRate,
            toDateCrewRate: data[0].toDateCrewRate,
            projectedCrewRate: data[0].projectedCrewRate,
            budgetCrewRate: data[0].budgetCrewRate,
            dateValues: "between (" + `${('0' + (tempStartDate.getMonth() + 1)).slice(-2)}/${('0' + tempStartDate.getDate()).slice(-2)}/${tempStartDate.getFullYear()})` + " and "
                + `(${('0' + (tempEndDate.getMonth() + 1)).slice(-2)}/${('0' + tempEndDate.getDate()).slice(-2)}/${tempEndDate.getFullYear()})`
        });
        setOpenForecastModal(true);
        setReloadModalPopup(true);
    }

    const openForecastModalEdit = () => {
        setIsLoadReportData(false);
    };

    const closeForecastModal = () => {
        setOpenForecastModal(false);
    };

    const handlecloseForecastModal = () => {
        setOpenForecastModal(false);
        setSearchFilter({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
        bindDropdowns(searchFilter);
        getReportData();
    };
    //#endregion 

    const renderSummaryCell = (data: any) => {
        if (data.row.data.summary !== summaryRow[5].toString()) {
            if (isSingleActivity) {
                return (
                    <><div className="float-start cursor-pointer text-dark" hidden={!(data.row.data.summary === summaryRow[2].toString())} onClick={(e) => openSummaryOverride(e, manageActivityData)} title="Update Qty"><IsoIcon /></div><div className="float-end">{formatCell(data)}</div></>
                );
            } else {
                return formatCell(data);
            }
        } else {
            return "";
        }
    }

    const renderForecastCell = (data: any) => {
        if (data.row.data.summary !== summaryRow[5].toString()) {
            if (isSingleActivity) {
                return (
                    <><div className="float-start cursor-pointer text-dark" hidden={!(data.row.data.summary === summaryRow[3].toString()) && !(data.row.data.summary === summaryRow[4].toString())} onClick={(e) => openForecastOverride(e, manageActivityData)} title="Update Forecast"><LineAxisIcon /></div><div className="float-end">{formatCell(data)}</div></>
                );
            } else {
                return formatCell(data);
            }
        } else {
            return "";
        }
    }

    const renderCell = (data: any) => {
        return formatCell(data);
    }

    const formatCell = (data: any) => {
        let newValue = "";
        if (data.row.data.summary !== summaryRow[5].toString()) {
            if (data.row.data.summary === summaryRow[0].toString() || data.row.data.summary === summaryRow[1].toString() || data.row.data.summary === summaryRow[2].toString()) {
                newValue = data.value !== 0 ? formatNumber(data.value, "###,##0;(###,##0)") : '-';
            } else if (data.row.data.summary === summaryRow[3].toString()) {
                newValue = data.value !== 0 ? formatNumber(data.value, "###,##0.00;(###,##0.00)") : '-';
            } else if (data.row.data.summary === summaryRow[4].toString()) {
                newValue = data.value !== 0 ? formatNumber(data.value, { type: "currency", precision: 2 }) : '-';
            } else {
                newValue = data.value;
            }
        }

        return newValue;
    }

    const customizeLabel = useCallback((arg: { seriesName: string, value: number; data: any }) => {
        if (arg.seriesName === "productionRate") {
            if (arg.data.actualProduction === 0) {
                return {
                    visible: false
                }
            } else {
                if (arg.value > 0) {
                    return {
                        visible: true,
                        backgroundColor: '#ffc400',
                        customizeText(e) {
                            return `${arg.data.productionLabelRate}`;
                        },
                    };
                } else {
                    return {
                        visible: false
                    }
                }
            }
        }

    }, []);

    const customizeHrQtyLabel = useCallback((arg: { seriesName: string, value: number; data: any }) => {
        if (arg.seriesName === "Hours") {
            if (arg.data.actualProduction === 0) {
                return {
                    visible: false
                }
            } else {
                if (arg.value > 0) {
                    return {
                        visible: true,
                        backgroundColor: '#ffc400',
                        customizeText(e) {
                            return `${arg.data.timelineLabelHrs}`;
                        },
                    };
                } else {
                    return {
                        visible: false
                    }
                }
            }
        }

        if (arg.seriesName === "Qty") {
            if (arg.data.actualProduction === 0) {
                return {
                    visible: false
                }
            } else {
                if (arg.value > 0) {
                    return {
                        visible: true,
                        backgroundColor: '#ffc400',
                        customizeText(e) {
                            return `${arg.data.timelineLabelQty}`;
                        },
                    };
                } else {
                    return {
                        visible: false
                    }
                }
            }
        }
    }, []);

    const TooltipTemplate = (info: any) => {
        if (info.point.data.actualProduction !== 0) {
            const sDate = new Date(info.point.data.wkStartDate);
            const eDate = new Date(info.point.data.wkEndDate);
            const percentageCompleteHours = formatNumber(info.point.data.percentageCompleteHrs, "###,##0%");
            const percentageCompleteQuantity = formatNumber(info.point.data.percentageCompleteQty, "###,##0%");
            const budget = formatNumber(info.point.data.budgetProduction, "###,##0.00;(###,##0.00)");
            const actual = formatNumber(info.point.data.actualProduction, "###,##0.00;(###,##0.00)");
            const gainedHours = formatNumber(Math.abs(info.point.data.hrsGained), "###,##0.00");
            const lostHours = formatNumber(Math.abs(info.point.data.hrsLost), "###,##0.00;(###,##0.00)");
            return (
                <div className="bar-tooltip">
                    <p>{info.point.data.wkNumber}<br />
                        Start: Mon {`${('0' + (sDate.getMonth() + 1)).slice(-2)}/${('0' + sDate.getDate()).slice(-2)}/${sDate.getFullYear()}`}<br />
                        End: Sun {`${('0' + (eDate.getMonth() + 1)).slice(-2)}/${('0' + eDate.getDate()).slice(-2)}/${eDate.getFullYear()}`}</p>
                    <p>Hours: {info.point.data.hours} | % Complete Hrs: {percentageCompleteHours}<br />
                        Qty: {info.point.data.qty} | % Complete Qty: {percentageCompleteQuantity}</p>
                    <p>Production<br />
                        Budget: {budget}<br />
                        Actual: {actual}<br />
                        {info.seriesName === 'HoursLost' ? "Hours Lost" : "Hours Saved"}: {info.seriesName === 'HoursLost' ? '(' + lostHours + ')' : gainedHours}</p>
                </div>
            )
        }
    }

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        setManageTab(newValue);
    };

    return (
        <>
            <div className="page-title-box">
                <h1 className="page-title">Activity-on-a-Page</h1>
            </div>

            <div className="card user-detail-card pages-detail-card activity-on-a-page-card">
                <div className="card-body">
                    <div className="dataTables_wrapper">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 d-flex mb-2 gap-3">
                                <Autocomplete
                                    limitTags={2}
                                    id="drpProjects"
                                    className="Autocomplete-tight "
                                    options={projects.map((option) => option.projectCode)}
                                    filterSelectedOptions
                                    sx={{ minWidth: 108 }}
                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleChange(newValue, "ProjectCode");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Project"
                                            required
                                            helperText={errProjectCode ? errMessageProjectCode : ""}
                                            error={errProjectCode}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                                placeholder: "Project",
                                                endAdornment: null,
                                            }} />
                                    )} />
                                <Autocomplete
                                    id="drpGroup"
                                    className="Autocomplete-tight"
                                    autoHighlight={true}
                                    autoComplete={false}
                                    autoSelect={true}
                                    popupIcon={null}
                                    fullWidth={true}
                                    filterSelectedOptions={true}
                                    options={groupList}
                                    sx={{ minwidth: 140, maxWidth: 280 }}
                                    value={searchFilter.group}
                                    onChange={(event: any, newValue: string | null) => {
                                        searchActivity(newValue, "Group");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Group"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    id="drpScope"
                                    className="Autocomplete-tight"
                                    autoHighlight={true}
                                    autoComplete={false}
                                    autoSelect={true}
                                    popupIcon={null}
                                    fullWidth={true}
                                    filterSelectedOptions={true}
                                    options={groupScopeList}
                                    sx={{ minwidth: 200, maxWidth: 400 }}
                                    value={searchFilter.groupScope}
                                    onChange={(event: any, newValue: string | null) => {
                                        searchActivity(newValue, "Group Scope");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Scope"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    id="drpActivity"
                                    className="Autocomplete-tight"
                                    autoHighlight={true}
                                    autoComplete={false}
                                    autoSelect={true}
                                    popupIcon={null}
                                    fullWidth={true}
                                    filterSelectedOptions={true}
                                    options={groupActivityList}
                                    sx={{ minwidth: 200, maxWidth: 400 }}
                                    value={searchFilter.groupActivity}
                                    onChange={(event: any, newValue: string | null) => {
                                        searchActivity(newValue, "Group Activity");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Activity"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    id="drpArea"
                                    className="Autocomplete-tight"
                                    autoHighlight={true}
                                    autoComplete={false}
                                    autoSelect={true}
                                    popupIcon={null}
                                    fullWidth={true}
                                    filterSelectedOptions={true}
                                    options={groupAreaList}
                                    sx={{ minwidth: 120, maxWidth: 240 }}
                                    value={searchFilter.groupArea}
                                    onChange={(event: any, newValue: string | null) => {
                                        searchActivity(newValue, "Group Area");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Area"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    id="drpCostCode"
                                    className="Autocomplete-tight"
                                    autoHighlight={true}
                                    autoComplete={false}
                                    autoSelect={true}
                                    popupIcon={null}
                                    fullWidth={true}
                                    filterSelectedOptions={true}
                                    options={groupCostCodeList}
                                    sx={{ minwidth: 100, maxWidth: 120 }}
                                    value={searchFilter.groupCostCode}
                                    onChange={(event: any, newValue: string | null) => {
                                        searchActivity(newValue, "Group Cost Code");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cost Code"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                            }}
                                        />
                                    )}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="MM/DD/YYYY"
                                        className="datafilter-section-date"
                                        value={manageStartDate}
                                        onChange={(e) => { handleChange(e, 'StartDate') }}
                                        renderInput={(params) => <TextField {...params}
                                            inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                                            helperText={errStartDate ? errMessageStartDate : ""}
                                            //required
                                            error={errStartDate}
                                            onBlur={(e) => {
                                                //handleErrors(e.target.value, 'StartDate');
                                            }} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="MM/DD/YYYY"
                                        className="datafilter-section-date"
                                        value={manageEndDate}
                                        onChange={(e) => { handleChange(e, 'EndDate') }}
                                        renderInput={(params) => <TextField {...params}
                                            inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                                            //required
                                            helperText={errEndDate ? errMessageEndDate : ""}
                                            error={errEndDate}
                                            onBlur={(e) => {
                                                //handleErrors(e.target.value, 'EndDate');
                                            }} />}
                                    />
                                </LocalizationProvider>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-icon btn-sm btn-view-report"
                                        onClick={loadSummaryReport}
                                    >
                                        View Report
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid_wrapper autoheightcell">
                        <Box
                            sx={{
                                height: 500,
                                minHeight: 200,
                                width: "100%",
                                "& .actions": {
                                    color: "text.secondary",
                                },
                                "& .textPrimary": {
                                    color: "text.primary",
                                },
                            }}
                        >
                            {!isLoadReportData ? (
                                <div>
                                    <div>
                                        <DataGrid
                                            className="activity-on-page-report"
                                            dataSource={manageReportData}
                                            showBorders={true}
                                            onCellPrepared={onCellPrepared}
                                            wordWrapEnabled={true}
                                        >
                                            <Sorting mode="none" />
                                            <Column
                                                dataField="summary"
                                                caption=""
                                                dataType="string"
                                                fixed={true}
                                                fixedPosition="left"
                                                allowFiltering={true} />
                                            <Column
                                                dataField="summaryValue.budget"
                                                caption="Budget"
                                                dataType="number"
                                                cellRender={(e) => renderCell(e)} />
                                            <Column
                                                dataField="summaryValue.todate"
                                                caption="To-Date"
                                                dataType="number"
                                                cellRender={(e) => renderSummaryCell(e)} />
                                            <Column
                                                dataField="summaryValue.dataFilter"
                                                caption="Date Filtered"
                                                dataType="number"
                                                cellRender={(e) => renderSummaryCell(e)} />
                                            <Column
                                                dataField="summaryValue.remaining"
                                                caption="Remaining"
                                                dataType="number"
                                                cellRender={(e) => renderCell(e)} />
                                            <Column
                                                dataField="summaryValue.projected"
                                                caption="Projected"
                                                dataType="number"
                                                cellRender={(e) => renderCell(e)} />
                                            <Column
                                                dataField="summaryValue.forecasted"
                                                caption="Forecasted"
                                                dataType="number"
                                                cellRender={(e) => renderForecastCell(e)} />
                                        </DataGrid>
                                    </div>
                                    <div hidden={!isSingleActivity} className="mt-3">
                                        <TabContext value={manageTab}>
                                            <TabList onChange={handleTab}>
                                                <Tab label="Charts" value="1" className="fw-bolder" />
                                                <Tab label="Notes" value="2" className="fw-bolder" />
                                            </TabList>
                                            <TabPanel value="1" className="tab-container">
                                                <div className="dv-report-chart">
                                                    <Chart
                                                        id="chart"
                                                        palette="Vintage"
                                                        dataSource={manageReportChartData}
                                                        customizeLabel={customizeLabel}
                                                        barGroupPadding={0.3}
                                                    >
                                                        <CommonSeriesSettings
                                                            argumentField="wkNumber"
                                                            type="stackedbar"
                                                        />
                                                        <Series name="HoursGained" valueField="hrsGained" axis="hrsgainlost" color="#54ca8a" />
                                                        <Series name="HoursLost" valueField="hrsLost" axis="hrsgainlost" color="#ff5454" />
                                                        <Series axis="productionRate" type="spline" valueField="actualProduction" name="productionRate" color="transparent">
                                                            <Label visible={false}></Label>
                                                            <Point color="#ffc400"></Point>
                                                        </Series>
                                                        <ValueAxis name="hrsgainlost" position="left" title="Hours Gained / Hrs Lost">
                                                            <Grid visible={true} />
                                                        </ValueAxis>
                                                        <ValueAxis name="productionRate" position="right" title={manageSeriesTitle}>
                                                            <Grid visible={true} />
                                                        </ValueAxis>
                                                        <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={false} />
                                                        <Tooltip
                                                            enabled={true}
                                                            contentRender={TooltipTemplate}
                                                        >
                                                        </Tooltip>
                                                    </Chart>
                                                </div>
                                                <div className="border-dott my-7"></div>
                                                <div className="dv-report-chart">
                                                    <Chart
                                                        id="chart"
                                                        palette="Vintage"
                                                        dataSource={manageReportChartData}
                                                        customizeLabel={customizeHrQtyLabel}
                                                        barGroupPadding={0.6}
                                                    >
                                                        <CommonSeriesSettings
                                                            argumentField="wkNumber"
                                                            type="bar"
                                                            hoverMode="allArgumentPoints"
                                                            selectionMode="allArgumentPoints"
                                                        />
                                                        <Series name="Hours" valueField="hours" axis="hoursqty" color="#4472c4" />
                                                        <Series name="Qty" valueField="qty" axis="hoursqty" color="#ed7d31" />
                                                        <Series axis="percentagecompleteHrsqty" type="spline" valueField="timelinePerCompHrs" name="% Complete Hrs" color="#00a7fa">
                                                            <Point visible={false}></Point>
                                                        </Series>
                                                        <Series axis="percentagecompleteHrsqty" type="spline" valueField="timelinePerCompQty" name="% Complete Qty" color="#ffc000">
                                                            <Point visible={false}></Point>
                                                        </Series>
                                                        <ValueAxis name="hoursqty" position="left" title="Hours / Qty">
                                                            <Grid visible={true} />
                                                        </ValueAxis>
                                                        <ValueAxis name="percentagecompleteHrsqty" position="right" title="% Complete: Hrs / Qty">
                                                            <Grid visible={true} />
                                                        </ValueAxis>
                                                        <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true} columnCount={4} />
                                                        <Tooltip
                                                            enabled={true}
                                                            contentRender={TooltipTemplate}
                                                        >
                                                        </Tooltip>
                                                    </Chart>
                                                </div>

                                            </TabPanel>
                                            <TabPanel value="2" className="tab-container">
                                                <div className="dv-report-notes">
                                                    {
                                                        manageReportNotes.map((note, index) =>
                                                            note.actionCategory !== '' ? (
                                                                <div key={index} className="py-3">
                                                                    <p className="mb-0"><strong>{note.actionDate}: {note.value} {note.unit}</strong></p>
                                                                    <p className="mb-0"><strong>{note.actionCategory} {note.action}</strong></p>
                                                                    <p className="mb-0 border-dott"><strong>{note.inputBy}</strong></p>
                                                                </div>
                                                            ) : (
                                                                <div key={index} className="py-3">
                                                                    <p className="mb-0"><strong>{note.actionDate}</strong></p>
                                                                    <p className="mb-0"><strong>{note.notes}</strong></p>
                                                                    <p className="mb-0 border-dott"><strong>{note.inputBy}</strong></p>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </TabPanel>
                                        </TabContext>

                                    </div>
                                </div>
                            ) : (
                                <div className="loader custom-loader">
                                    <Loading />
                                </div>
                            )}
                        </Box>
                    </div>
                </div>
            </div>
            <SummaryOverrideModalDialog
                Open={openSummaryModaldialog}
                DialogTitle={"Summary Overrides"}
                DialogContentText={Messages.ManageUser.confirmMessage}
                ButtonYes={"Save"}
                ButtonNo={"Cancel"}
                onCancel={closeSummaryModal}
                onClose={handlecloseSummaryModal}
                rowData={manageActivity}
                OpenSummaryModalEdit={openSummaryModalEdit}
                setReloadModalPopup={setReloadModalPopup}
                ReloadModalPopup={reloadModalPopup}
            />
            <ForecastOverrideModalDialog
                Open={openForecastModaldialog}
                DialogTitle={"Forecast Overrides"}
                DialogContentText={Messages.ManageUser.confirmMessage}
                ButtonYes={"Save"}
                ButtonNo={"Cancel"}
                onCancel={closeForecastModal}
                onClose={handlecloseForecastModal}
                rowData={manageForecast}
                OpenForecastModalEdit={openForecastModalEdit}
                setReloadModalPopup={setReloadModalPopup}
                ReloadModalPopup={reloadModalPopup}
            />
        </>
    )
}

