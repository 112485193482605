export const REACT_APP_MuiLicenseKey_User1 = '82403efd15a09996358b7b03fd6f49fcT1JERVI6NDA5NzksRVhQSVJZPTE2ODA0NjU4MDAwMDAsS0VZVkVSU0lPTj0x';

export const NotifyType =
{
    success: "success",
    error: "error",
    warn: "warn",
    info: "info"
};

export const Messages =
{
    Crew:
    {
        saved: 'Crew saved successfully.',
        updated: 'Crew updated successfully.',
        error: 'Something went wrong!'
    },
    CrewList:
    {
        success: 'Crew List data updated successfully.',
        update: 'Crew member data updated successfully.',
        error: 'Something went wrong!',
        employeeName: 'Employee Name is required.',
        employeeNumber: 'Employee Number is required.',
        area: 'Area is required.',
        unionCode: 'Union code is required.',
        title: 'Title is required.',
        lastChekedDate: 'Last checked date is required.',
        terminationDate: 'Termination date is required.',
        hourlyRate: 'Hourly Rate is required.',
        overTime: 'Overtime is required.',
        doubleTime: 'Double time is required.',
        exists: 'Employee number is already exists.',
    },
    ActivityBudgetList:
    {
        success: 'Activity Budget List data updated successfully.',
        error: 'Something went wrong!',
    },
    ManageUser:
    {
        saved: 'User saved successfully.',
        updated: 'User updated successfully.',
        error: 'Something went wrong!',
        deleted: 'User removed successfully.',
        confirm: 'Remove user confirmation.',
        confirmMessage: 'Are you sure to remove this user?',
        exists: 'Email already exists.',
        emailNotAvailable: 'Email Address is not available for this user.',
        userAlert: 'User registration alert.',
        noUserExists: 'There is no user that exists with this name.',
        requiredFirstName: "First name is required.",
        requiredLastName: "Last name is required.",
        requiredEmail: "Email is required.",
        requiredRole: "Please select any role.",
        requiredProjects: "Please assign any Project.",
        requiredDelegate: "Please select delegate end date.",
        emailInvalid: 'Email Address is invalid.',
    },
    ManageProject:
    {
        requiredProjectCode: "Project Number is required.",
        requiredProjectTitle: "Project title is required.",
        requiredDescription: "Description is required.",
        requiredStartDate: "Start date is required.",
        requiredEndDate: "End date is required.",
        existsProjectCode: 'Project number already exists.',
        invalidProjectCode: 'Invalid project number.',
        invalidProjectCodeLength: 'Project number length should be of 8 digit.',
        greaterThanEndDate: 'End date should be greater than Start date',
        confirm: 'Remove project confirmation',
        confirmMessage: 'Are you sure to remove this project number :',
        deleted: 'Project deleted successfully.',
        error: 'Something went wrong!',
        saved: 'Project saved successfully.',
        updated: 'Project updated successfully.',
    },
    ProjectSelection:
    {
        upsert: "Project upsert successfully"
    },
    Reports:
    {
        requiredProjectCode: "Select project",
        requiredWeedendDate: "Select weekend date",
        requiredStartDate: "Select start date",
        requiredEndDate: "Select end date",
        dateRangeValidation: "End date should be greater than start date",
        invalidStartDate: 'Invalid start date',
        invalidEndDate: 'Invalid end date'
    },
    TimeEntry:
    {
        crewMembersAdded: 'Crew member added successfully.',
        activitiesAdded: 'Activity added successfully.',
    },
    SummaryOverride:{
        saved: 'Summary Override saved successfully.',
        requiredAction: "Please select any action.",
        quantityRequired: "Quantity is required.",
        requiredNotes: "Note is required.",
        requiredSubmissionDate: "Submission date is required.",
    },
    ForecastOverride:{
        saved: 'Forecast Override saved successfully.',
    }
}

export const UserRoleType =
{
    Admin: 1,
    Supervisor: 2,
    ProjectManager: 3,
    Crew: 4,
    Accountant: 5,
    ProjectEngineer: 6,
    FieldSupervisor: 7
}

export const Roles =
{
    Admin: "Admin",
    Analyst: "Analyst",
    Approver: "Approver",
    SuperAdmin: "SuperAdmin",
}