import { AccountInfo } from '@azure/msal-browser';
import React, { Suspense, useEffect } from 'react';
import UserServices from "../services/UserServices";
import UserNotFound from './UserNotFound';
import { useIsAuthenticated } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode'
import { Loading } from './Loading';

export interface IUserAuthenticateProps {
  Isauthenticated: boolean,
  AccountInfo: AccountInfo;
  setAuthorized: any;
  //setIsLoad: (isload:boolean) => void;  
}
const UserAuthenticate = (props: IUserAuthenticateProps) => {
  /** Authenticated flag */
  const authenticated: boolean = props.Isauthenticated;
  const [Isauthenticated, setAuthenticated] = React.useState<boolean>(true);
  let isDataLoaded = false;
  localStorage.removeItem("accesstoken");

  const _isAuthenticated = useIsAuthenticated(props.AccountInfo);
  useEffect(() => {
    window.AccountInfo = props.AccountInfo;
    async function loadUser() {
      await AuthenticateUser();
    }
    loadUser();
  });
  async function AuthenticateUser() {
    if (_isAuthenticated) {
      const token = jwtDecode(window.AccountInfo.idToken!);
      var res = await UserServices.Authenticate(props.AccountInfo.username);
      if (!res.hasErrors && res.value) {
        isDataLoaded = true;
        setAuthenticated(true);
        window.UserId = res.value.id.toString();
        window.RoleId = res.value.roleId.toString();
        window.UserName = res.value.firstName.toString() + ' ' + res.value.lastName.toString()
        //window.RoleType = res.value.roleType;
        window.RoleName = res.value.roleName;
        window.accessToken = res.value.jwtToken;
        localStorage.setItem("accesstoken", res.value.jwtToken);
        props.setAuthorized(true);
      }
      else { setAuthenticated(false); }
      // props.setIsLoad(true);
    }
    else {
      // window.location.href = "https://www.google.com";
      props.setAuthorized(false);
    }
    isDataLoaded = true;
  }

  return (
    <React.Fragment>{
      Isauthenticated ? <div>
      </div> : <div><UserNotFound /></div>
    }
    </React.Fragment>
  )
};

export default UserAuthenticate;


