import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

const Layout: React.FC<any> = ({ children}: any) => {
    return (
        <>
            <div>
                <NavMenu />
                <div className="page-container">
                    <Container tag="main">
                        {children}
                    </Container>    
                </div>
                
            </div>
        </>
    );
};

export default Layout;