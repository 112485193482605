import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';


export type IProps = {
    DialogTitle: string;
    DialogContentText: string;
    Open: boolean;
    OnSave: any | null;
    OnCancel?: any | null;
    IsEditMode: boolean;
    ActivityName: string;
  };


export const AddActivityNotesModalDialog: React.FunctionComponent<IProps> = (props) => {
    const [updatedNotes, setUpdatedNotes] = React.useState<string>("");
    React.useEffect(() => {
    }, []);

    const textChange = (txtValue: string) => {
        setUpdatedNotes(txtValue);
    }

    const handleSave = () => {
        props.OnSave(updatedNotes, props.ActivityName);
    }

    return (
        <div>
          <Dialog
            open={props.Open}
            onClose={props.OnCancel}
            className="confirm-dialog addForm custom-modal"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {props.DialogTitle}
              <IconButton
                aria-label="close"
                onClick={props.OnCancel}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 13,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
            <div>
                  <div className="form-group mb-4">
                    <TextareaAutosize
                      defaultValue={props.DialogContentText}
                      minRows={5}
                      className="col-12"
                      onChange={(e) => textChange(e.target.value)}
                      placeholder="Activity Notes"
                      maxLength={500}
                    />
                  </div>
                </div>
            </DialogContent>
            <DialogActions>
              <button
                type="button"
                className="btn btn-secondary btn-icon btn-sm"
                onClick={props.OnCancel}
              >
                Close <CloseIcon />
              </button>
              {!props.IsEditMode ? (
                <button
                  type="button"
                  className="btn btn-primary btn-icon btn-sm"
                  onClick={handleSave} //validateUser
                >
                  Add <NoteAddIcon />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-icon btn-sm"
                  onClick={handleSave} //validateUser
                >
                  Update <NoteAddIcon />
                </button>
              )}
            </DialogActions>
          </Dialog>
        </div>
    );
};