import { IAccessTokenResponse, IUserModel, IManageUserModel, IProjectDropDownModel, IRoleModel, IActionModel } from "../models/IUser";
import { Result } from "../models/Result";
import { ServiceBase } from "./../services/ServiceBase";


export default class UserServices extends ServiceBase {

    public static async Authenticate(username?: string): Promise<Result<IAccessTokenResponse>> {
        var result = await this.requestJson<IAccessTokenResponse>({
            url: `api/user/authenticate`,
            data: { "username": username},
            method: "POST"
        });
        return result;
    }

    public static async GetById(id: number): Promise<Result<IManageUserModel>> {
        var result = await this.requestJson<IManageUserModel>({
            url: `api/user/getUserById/${id}`,
            method: "GET"
        });
        
        return result;
    }

    public static async ManageUser(manageUser: IManageUserModel): Promise<Result<IUserModel>> {
        var result = await this.requestJson<IUserModel>({
            url: `api/user/manageUser`,
            method: "POST",
            data: manageUser
        });

        return result;
    }

    public static async GetRoles(): Promise<Result<IRoleModel[]>> {
        var result = await this.requestJson<IRoleModel[]>({
            url: `api/user/role`,
            method: "GET"
        });

        return result;
    }

    public static async GetProjects(): Promise<Result<IProjectDropDownModel[]>> {
        var result = await this.requestJson<IProjectDropDownModel[]>({
            url: `api/project/getProjectsForDropdown/`,
            method: "GET"
        });

        return result;
    }

    public static async GetUsers(): Promise<Result<IUserModel[]>> {
        var result = await this.requestJson<IUserModel[]>({
            url: `api/user/`,
            method: "GET"
        });
        return result;
    }

    public static async SaveUser(manageUser: IManageUserModel): Promise<Result<IUserModel>> {
        var result = await this.requestJson<IUserModel>({
            url: `api/user/add`,
            method: "POST",
            data: manageUser
        });

        return result;
    }

    public static async DeleteUser(userId: any) {
        var result = await this.requestJson<IUserModel>({
            url: `api/user/delete/${userId}`,
            method: "GET"
        });

        return result;
    }

    public static async DeActiveUser(id: string): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `api/user/deActiveUser/${id}`,
            method: "POST"
        });
        return result;
    }

    public static async ValidateEmail(email: string): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `api/user/validateEmail/${email}`,
            method: "GET"
        });
        return result;
    }

    public static async GetProjectByRoleId(roleId: number): Promise<Result<IProjectDropDownModel[]>> {
        var result = await this.requestJson<IProjectDropDownModel[]>({
            url: `api/project/getProjectsForDropDownByRoleId/${roleId}`,
            method: "GET"
        });

        return result;
    }

    public static async GetUsersByRoleType(roleType: string): Promise<Result<IUserModel[]>> {
        var result = await this.requestJson<IUserModel[]>({
            url: `api/user/getUsersByRoleType/${roleType}`,
            method: "GET"
        });
        return result;
    }

    public static async DownloadAPK(): Promise<Result<Response>> {
        var result = await this.downloadFiles<Response>({
            url: `api/mobile/apk`,
            method: "GET",
            data: { FileName: "hswc.apk" }
        });
        return result;
    }

    public static async DownloadIOS(): Promise<Result<Response>> {
        var result = await this.downloadFiles<Response>({
            url: `api/mobile/ios`,
            method: "GET",
            data: { FileName: "hswc.ios" }
        });
        return result;
    }
}