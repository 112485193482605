import React, { createContext } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { msalConfig } from './authConfig';
//import UserServices from './components/UserManagement/UserServices';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

interface RequestInterceptorProps {
    children: JSX.Element,
    accounts: AccountInfo[],
    instance: IPublicClientApplication
}

export const UserContext = createContext({});

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children, accounts, instance }: RequestInterceptorProps) => {
    //  const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async (config) => {
        if (!account) {
            // throw Error('No active account! Verify a user has been signed in.');
            window.location.href = process.env.REACT_APP_postLogoutRedirectUri ?? "/";
        }

        let request = {
            scopes: [msalConfig.auth.scope]
        };

        instance.setActiveAccount(instance.getAccountByUsername(accounts[0].username));
        // let accessToken = authToken ? authToken.accessToken : "";  
        let accessToken = localStorage.getItem("accesstoken") ?? window.accessToken;

        if (accessToken === "")
            window.location.reload();

        // const bearer = `Bearer ${accessToken}`;

        const bearer = `Bearer ${accessToken}`;

        if (config?.headers?.Authorization)
            config.headers.Authorization = bearer;

        return config;
    });
    /* eslint-enable no-param-reassign */

    return (
        <UserContext.Provider value={{ account }}>
            {children}
        </UserContext.Provider>
    );
};

export default RequestInterceptor;

