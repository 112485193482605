import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from '@mui/icons-material/Create';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  IRoleModel,
} from "../models/IUser";
import { Loading } from "../Shared/Loading";
import { Messages, NotifyType } from "../Shared/constant";
import { AlertDialogBox } from "../Shared/AlertDialogBox";
import Notify from "../Shared/notify";
import UserServices from "../services/UserServices";
import { GridRowsProp } from "@mui/x-data-grid";
import { ICrewMemberModel, IManageCrewModel } from "../CrewDetail/ICrew";
import CrewListServices from "./CrewListServices";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';

//#region Model Declares
export type IProps = {
  DialogTitle: string;
  DialogContentText: string;
  ButtonYes: string;
  ButtonNo: string;
  onCancel?: any | null;
  Open: boolean;
  onClose?: any | null;
  rowData: ICrewMemberModel;
  OpenCrewMemberModalEdit?: any | null;
  setReloadModalPopup: any;
  ReloadModalPopup: boolean;
};

const defaultValues: ICrewMemberModel = {
  id: 0,
  employeeName: "",
  employeeNumber: "",
  area: "",
  unionCode: 0,
  title: "",
  lastCheckedDate: "",
  terminationDate: "",
  hourlyRate: 0,
  overtime: 0,
  doubleTime: 0,
};
const initialRows: GridRowsProp = [];

//#endregion
export const CrewMemberModalDialog: React.FunctionComponent<IProps> = (props) => {
  //#region Variable Declaration

  //const declaration
  const { onClose } = props;
  const { onCancel } = props;
  const [manageCrewMember, setManageCrewMember] = React.useState(defaultValues);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoadSaveCrewMember, setIsLoadSaveCrewMember] = React.useState<boolean>(false);

  const [errEmployeeName, setErrEmployeeName] = React.useState<boolean>(false);
  const [errMessageEmployeeName, setErrMessageEmployeeName] = React.useState("");

  const [errEmployeeNumber, setErrEmployeeNumber] = React.useState<boolean>(false);
  const [errMessageEmployeeNumber, setErrMessageEmployeeNumber] = React.useState("");

  const [errArea, setErrArea] = React.useState<boolean>(false);
  const [errMessageArea, setErrMessageArea] = React.useState("");

  const [errUnionCode, setErrUnionCode] = React.useState<boolean>(false);
  const [errMessageUnionCode, setErrMessageUnionCode] = React.useState("");

  const [errTitle, setErrTitle] = React.useState<boolean>(false);
  const [errMessageTitle, setErrMessageTitle] = React.useState("");

  const [errLastCheckedDate, setErrLastCheckedDate] = React.useState<boolean>(false);
  const [errMessageLastCheckedDate, setErrMessageLastCheckedDate] = React.useState("");

  const [errTerminationDate, setErrTerminationDate] = React.useState<boolean>(false);
  const [errMessageTerminationDate, setErrMessageTerminationDate] = React.useState("");

  const [errHourlyRate, setErrHourlyRate] = React.useState<boolean>(false);
  const [errMessageHourlyRate, setErrMessageHourlyRate] = React.useState("");

  const [errOvertime, setErrOvertime] = React.useState<boolean>(false);
  const [errMessageOvertime, setErrMessageOvertime] = React.useState("");

  const [errDoubleTime, setErrDoubleTime] = React.useState<boolean>(false);
  const [errMessageDoubleTime, setErrMessageDoubleTime] = React.useState("");



  const [roles, setRoles] = React.useState<IRoleModel[]>([]);
  let [rows, setRows] = React.useState(initialRows);
  //#endregion

  //#region UserEffect
  React.useEffect(() => {
    setManageCrewMember(defaultValues);
  }, []);

  React.useEffect(() => {
    setManageCrewMember(defaultValues);
    getCrewMembers();
    if (props.rowData.id > 0) {
      setManageCrewMember({
        ...manageCrewMember,
        id: props.rowData.id,
        employeeName: props.rowData.employeeName,
        employeeNumber: props.rowData.employeeNumber,
        area: props.rowData.area,
        unionCode: props.rowData.unionCode,
        title: props.rowData.title,
        lastCheckedDate: props.rowData.lastCheckedDate,
        terminationDate: props.rowData.terminationDate,
        hourlyRate: props.rowData.hourlyRate,
        overtime: props.rowData.overtime,
        doubleTime: props.rowData.doubleTime,
      });
      props.setReloadModalPopup(false);

      props.OpenCrewMemberModalEdit();
    }
  }, [props.rowData.id, props.ReloadModalPopup]);
  //#endregion

  //#region PageLoad Methods

  const getCrewMembers = async () => {
    setIsLoadSaveCrewMember(true);
    CrewListServices.GetCrewMembers().then((res) => {
      if (!res.hasErrors && res.value) {
        setRows(res.value);
        
        setIsLoadSaveCrewMember(false);
      }
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setManageCrewMember({
      ...manageCrewMember,
      [name]: value,
    });
  };

  const validateCrewMember = async () => {   
    resetErrorState();
    let isValid: boolean = true;

    if (
      manageCrewMember.employeeName === "" ||
      manageCrewMember.employeeName === null
    ) {
      setErrEmployeeName(true);
      setErrMessageEmployeeName(Messages.CrewList.employeeName);
      isValid = false;
    }
    if (
      manageCrewMember.employeeNumber === "" ||
      manageCrewMember.employeeNumber === undefined ||
      manageCrewMember.employeeNumber === null
    ) {
      setErrEmployeeNumber(true);
      setErrMessageEmployeeNumber(Messages.CrewList.employeeNumber);
      isValid = false;
    }
    else {
      setErrEmployeeNumber(false);
      const filterRows = rows.filter((x) => x.employeeNumber !== parseInt(props.rowData.employeeNumber));

      const isExist = filterRows.filter((x) => x.employeeNumber === parseInt(manageCrewMember.employeeNumber)).length > 0;
      if (isExist) {
        setErrEmployeeNumber(true);
        setErrMessageEmployeeNumber(Messages.CrewList.exists);
        isValid = false;
      }
    }
    if (
      manageCrewMember.area === "" ||
      manageCrewMember.area === undefined ||
      manageCrewMember.area === null
    ) {
      setErrArea(true);
      setErrMessageArea(Messages.CrewList.area);
      isValid = false;
    }

    if (
      manageCrewMember.unionCode === 0 ||
      manageCrewMember.unionCode === undefined ||
      manageCrewMember.unionCode === null
    ) {
      setErrUnionCode(true);
      setErrMessageUnionCode(Messages.CrewList.unionCode);
      isValid = false;
    }

    if (
      manageCrewMember.title === "" ||
      manageCrewMember.title === undefined ||
      manageCrewMember.title === null
    ) {
      setErrTitle(true);
      setErrMessageTitle(Messages.CrewList.unionCode);
      isValid = false;
    }

    if (
      manageCrewMember.lastCheckedDate === "" ||
      manageCrewMember.lastCheckedDate === undefined ||
      manageCrewMember.lastCheckedDate === null ||
      manageCrewMember.lastCheckedDate === 0
    ) {
      setErrLastCheckedDate(true);
      setErrMessageLastCheckedDate(Messages.CrewList.lastChekedDate);
      isValid = false;
    }

    if (
      manageCrewMember.terminationDate === "" ||
      manageCrewMember.terminationDate === undefined ||
      manageCrewMember.terminationDate === null ||
      manageCrewMember.terminationDate === 0
    ) {
      setErrTerminationDate(true);
      setErrMessageTerminationDate(Messages.CrewList.terminationDate);
      isValid = false;
    }

    if (
      manageCrewMember.hourlyRate === 0 ||
      manageCrewMember.hourlyRate === undefined ||
      manageCrewMember.hourlyRate === null
    ) {
      setErrHourlyRate(true);
      setErrMessageHourlyRate(Messages.CrewList.hourlyRate);
      isValid = false;
    }
    if (
      manageCrewMember.overtime === 0 ||
      manageCrewMember.overtime === undefined ||
      manageCrewMember.overtime === null
    ) {
      setErrOvertime(true);
      setErrMessageOvertime(Messages.CrewList.overTime);
      isValid = false;
    }
    if (
      manageCrewMember.doubleTime === 0 ||
      manageCrewMember.doubleTime === undefined ||
      manageCrewMember.doubleTime === null
    ) {
      setErrDoubleTime(true);
      setErrMessageDoubleTime(Messages.CrewList.doubleTime);
      isValid = false;
    }


    if (isValid) {
      saveCrewMember();
    }
  };

  const saveCrewMember = async () => {
    let saveCrewMember: ICrewMemberModel = {
      id: manageCrewMember.id,
      employeeName: manageCrewMember.employeeName,
      employeeNumber: manageCrewMember.employeeNumber,
      area: manageCrewMember.area,
      unionCode: manageCrewMember.unionCode,
      title: manageCrewMember.title,
      lastCheckedDate: manageCrewMember.lastCheckedDate,
      terminationDate: manageCrewMember.terminationDate,
      hourlyRate: manageCrewMember.hourlyRate,
      overtime: manageCrewMember.overtime,
      doubleTime: manageCrewMember.doubleTime,
      modifiedBy: parseInt(window.UserId),

    };
    setIsLoadSaveCrewMember(true);
    var response = await CrewListServices.UpdateCrewMember(saveCrewMember);
    if (response.success && !response.hasErrors && response.value) {
      setIsLoadSaveCrewMember(false);
      Notify(
        NotifyType.success, Messages.CrewList.update
      );
      getCrewMembers();
      setManageCrewMember(defaultValues);
      onClose();
    } else {
      setIsLoadSaveCrewMember(false);
      Notify(NotifyType.error, Messages.ManageUser.error);
    }
  };

  //#endregion

  //#region internal methods
  const resetErrorState = async () => {
    setErrEmployeeName(false);
    setErrEmployeeNumber(false);
    setErrArea(false);
    setErrUnionCode(false);
    setErrTitle(false);
    setErrLastCheckedDate(false);
    setErrTerminationDate(false);
    setErrHourlyRate(false);
    setErrOvertime(false);
    setErrDoubleTime(false);
  };

  const resetCrewMember = async () => {
    resetErrorState();
    setManageCrewMember(defaultValues);
    onCancel();
  };

  const closeAlertDialogBox = () => {
    setAlertOpen(false);
    setAlertMessage("");
  };
  //#endregion

  //#region Validation Starts

  const handleChange = (newValue: Dayjs | null, cntrlName: string) => {
    if (newValue === undefined || newValue === null) {
      switch (cntrlName) {
        case 'LastCheckedDate':
          setErrLastCheckedDate(true);
          setErrMessageLastCheckedDate(Messages.CrewList.lastChekedDate);
          break;
        case 'TerminationDate':
          setErrTerminationDate(true);
          setErrMessageTerminationDate(Messages.CrewList.terminationDate);
          break;
      }
    } else {
      switch (cntrlName) {
        case 'LastCheckedDate':
          setManageCrewMember({
            ...manageCrewMember,
            lastCheckedDate: dayjs(newValue).format("YYYY-MM-DDThh:mm:ss"),
          });
          setErrLastCheckedDate(false);
          break;
        case 'TerminationDate':
          setManageCrewMember({
            ...manageCrewMember,
            terminationDate: dayjs(newValue).format("YYYY-MM-DDThh:mm:ss"),
          });
          setErrTerminationDate(false);
          break;
      }
    }
  };

  const handleErrors = (value: any, cntrlName: string) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      switch (cntrlName) {
        case 'EmployeeName':
          setErrEmployeeName(true);
          setErrMessageEmployeeName(Messages.CrewList.employeeName);
          break;
        case 'EmployeeNumber':
          setErrEmployeeNumber(true);
          setErrMessageEmployeeNumber(Messages.CrewList.employeeNumber);
          break;
        case 'Area':
          setErrArea(true);
          setErrMessageArea(Messages.CrewList.area);
          break;
        case 'UnionCode':
          setErrUnionCode(true);
          setErrMessageUnionCode(Messages.CrewList.unionCode);
          break;
        case 'Title':
          setErrTitle(true);
          setErrMessageTitle(Messages.CrewList.title);
          break;
        case 'HourlyRate':
          setErrHourlyRate(true);
          setErrMessageHourlyRate(Messages.CrewList.hourlyRate);
          break;
        case 'Overtime':
          setErrOvertime(true);
          setErrMessageOvertime(Messages.CrewList.overTime);
          break;
        case 'DoubleTime':
          setErrDoubleTime(true);
          setErrMessageDoubleTime(Messages.CrewList.doubleTime);
          break;
        case 'LastCheckedDate':
          setErrLastCheckedDate(true);
          setErrMessageLastCheckedDate(Messages.CrewList.lastChekedDate);
          break;
        case 'TerminationDate':
          setErrTerminationDate(true);
          setErrMessageTerminationDate(Messages.CrewList.terminationDate);
          break;

      }
    }
    // else {
    //   switch (cntrlName) {
    //     case 'EmployeeNumber':
    //       setErrEmployeeNumber(false);
    //       const isExist = rows.filter((x) => x.employeeNumber === manageCrewMember.employeeNumber).length > 0;
    //       if (isExist) {
    //         setErrEmployeeNumber(true);
    //         setErrMessageEmployeeNumber(Messages.CrewList.exists);
    //       }
    //       break;
    //   }
    // }
  }
  //#endregion

  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.onCancel}
        className="confirm-dialog addForm custom-modal"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {props.DialogTitle}
          <IconButton
            aria-label="close"
            onClick={resetCrewMember}
            sx={{
              position: "absolute",
              right: 10,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          {!isLoadSaveCrewMember ? (
            <div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Employee Name"
                  name="employeeName"
                  value={manageCrewMember.employeeName}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errEmployeeName}
                  helperText={errEmployeeName ? errMessageEmployeeName : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'EmployeeName');
                  }}
                />
              </div>

              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Employee Number"
                  name="employeeNumber"
                  value={manageCrewMember.employeeNumber}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errEmployeeNumber}
                  helperText={errEmployeeNumber ? errMessageEmployeeNumber : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'EmployeeNumber');
                  }}
                />
              </div>

              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Area"
                  name="area"
                  value={manageCrewMember.area}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errArea}
                  helperText={errArea ? errMessageArea : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'Area');
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Union Code"
                  name="unionCode"
                  value={manageCrewMember.unionCode}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errUnionCode}
                  helperText={errUnionCode ? errMessageUnionCode : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'UnionCode');
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Title"
                  name="title"
                  value={manageCrewMember.title}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errTitle}
                  helperText={errTitle ? errMessageTitle : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'Title');
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Last Checked Date"
                    inputFormat="MM/DD/YYYY"
                    value={manageCrewMember.lastCheckedDate}
                    onChange={(e) => { handleChange(e, 'LastCheckedDate') }}
                    renderInput={(params) => <TextField {...params}
                      inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                      helperText={errLastCheckedDate ? errMessageLastCheckedDate : ""}
                      required
                      error={errLastCheckedDate}
                      onBlur={(e) => {
                        handleErrors(e.target.value, 'LastCheckedDate');
                      }}
                    />
                    }

                  />
                </LocalizationProvider>
              </div>
              <div className="form-group mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Termination Date"
                    inputFormat="MM/DD/YYYY"
                    value={manageCrewMember.terminationDate}
                    onChange={(e) => { handleChange(e, 'TerminationDate') }}
                    renderInput={(params) => <TextField {...params}
                      inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                      helperText={errTerminationDate ? errMessageTerminationDate : ""}
                      required
                      error={errTerminationDate}
                      onBlur={(e) => {
                        handleErrors(e.target.value, 'TerminationDate');
                      }}
                    />
                    }

                  />
                </LocalizationProvider>
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Hourly Rate"
                  name="hourlyRate"
                  type="number"
                  value={manageCrewMember.hourlyRate}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  fullWidth
                  error={errHourlyRate}
                  helperText={errHourlyRate ? errMessageHourlyRate : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'HourlyRate');
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Overtime"
                  name="overtime"
                  type="number"
                  value={manageCrewMember.overtime}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errOvertime}
                  helperText={errOvertime ? errMessageOvertime : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'Overtime');
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Double Time"
                  name="doubleTime"
                  type="number"
                  value={manageCrewMember.doubleTime}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errDoubleTime}
                  helperText={errDoubleTime ? errMessageDoubleTime : ""}
                  onBlur={(e) => {
                    handleErrors(e.target.value, 'DoubleTime');
                  }}
                />
              </div>

            </div>
          ) : (
            <div className="loader custom-loader">
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary btn-icon btn-sm"
            onClick={resetCrewMember}
          >
            Close <CloseIcon />
          </button>

          <button
            type="button"
            className="btn btn-primary btn-icon btn-sm"
            onClick={validateCrewMember}
          >
            Update <CreateIcon />
          </button>

        </DialogActions>
      </Dialog>
      <AlertDialogBox
        Open={alertOpen}
        DialogTitle={Messages.ManageUser.userAlert}
        DialogContentText={alertMessage}
        onClose={closeAlertDialogBox}
      />
    </div>
  );
};
