import React from "react";
import { useEffect } from "react";
import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProjectSelectionServices from "../../ProjectSelectionServices";
import TimeEntryDetails from "../../../TimeEntry/TimeEntryDetails";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function TimeEntrySelection() {
  const location = useLocation();
  const [isLoadTimeEntrySelectionData, setIsLoadTimeEntrySelectionData] = React.useState<boolean>(false);
  const [selectedCrewId, setSelectedCrewId] = React.useState<number>(0);
  const [projectTitle, setProjectTitle] = React.useState<string>("");
  const [crewTitle, setCrewTitle] = React.useState<string>("");
  const [crewSelectionPageURL, setCrewSelectionPageURL] = React.useState<string>("");
  const [cookies, setCookie] = useCookies(["projectSelection"]);
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/projectSelection">
      Projects
    </Link>,
    <Link underline="hover" key="2" color="inherit" href={crewSelectionPageURL}>
      {projectTitle}
    </Link>,
    <span key={crewTitle}>{crewTitle}</span>
  ];

  useEffect(() => {
    let myParam = new URLSearchParams(location.search).get("crewId");
    if (myParam === null || myParam === undefined) {
      if (cookies.projectSelection !== undefined) {
        const arrOfObj = cookies.projectSelection.split("&");
        arrOfObj.map((element: any) => {
          const keyValue = element.split(":");
          if (keyValue[0] === 'crewId') {
            myParam = keyValue[1];
          }
        });
      } else {
        navigate("/projectSelection");
      }
    }

    setSelectedCrewId(myParam === null ? 0 : parseInt(myParam));
    getBreadcrumbsDetails(myParam === null ? 0 : parseInt(myParam));
  }, []);

  const getBreadcrumbsDetails = async (crewId: number) => {
    setIsLoadTimeEntrySelectionData(false);
    ProjectSelectionServices.GetProjectsCrewsForBreadcrumb().then((res) => {
      if (!res.hasErrors && res.value) {
        const crew = res.value;
        setCrewTitle(crew.filter((x) => x.crewId == crewId).map((fc) => fc.crewName).toLocaleString());
        setProjectTitle(
          crew
            .filter((x) => x.crewId == crewId)
            .map((fp) => fp.projectCode + "-" + fp.projectTitle)
            .toLocaleString()
        );
        setCrewSelectionPageURL(
          "/crewSelection?projectId=" +
          crew
            .filter((x) => x.crewId == crewId)
            .map((x) => x.projectId)
            .toLocaleString()
        );
        setIsLoadTimeEntrySelectionData(true);
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="row">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div className="col-12">
          <TimeEntryDetails crewId={selectedCrewId} isFromSelection={true}></TimeEntryDetails>
        </div>
      </div>
    </>
  );
}
