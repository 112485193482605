import { Button } from '@mui/material';
import * as React from 'react';

interface ParentProps {
  title?: string;
  message?: React.ReactNode;
  statusCode?: number;
}

type Props = ParentProps;

const UnAuthorized: React.FC<Props> = (props) => {
  const { message } = props;
  return (
    <React.Fragment>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">        
        <div className="container login-wrap">
        <div className='row justify-content-center unauthorized'>
       
        </div>
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-lg-6">
              <div className="card">
                <div className='card-header'>
                  <div className="text-center">
                    {/* <a href="#">
                      <span><img src={logoHSWC} alt="" /></span>
                    </a> */}
                  </div>
                </div>
                <div className="card-body">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-center mt-3">You don't have access of this page!</h4>
                    {/* <p className="mb-4">Please sign in below to continue to HSWC.</p> */}
                  </div>
                  <form action="#">
                    <div className="mb-0 text-center signin">
                      
                    </div>
                  </form>

                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p>2024 © HSWC - Power platform 
                    {/* <a href="pages-login.html" className="text-muted ms-1"><b>About</b></a> | <a href="pages-login.html" className="text-muted ms-1"><b>Support</b></a> | <a href="pages-login.html" className="text-muted ms-1"><b>Contact</b></a> */}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnAuthorized;