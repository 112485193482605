export interface IAutoCompleteModel {
    label: string;
    value: string;
}
export interface IAutoCompleteInputDataModel {
    value1: string;
    value2: string;
}
export const CreateAutoCompleteData = (inputdata: IAutoCompleteInputDataModel[]): IAutoCompleteModel[] => {
    let data: IAutoCompleteModel[] = [];
    inputdata.forEach(element => {
        data.push({
            label: element.value1 + ' - ' + element.value2,
            value: element.value2
        });
    });
    return data;
}
export const CreateAutoCompleteClientData = (inputdata: IAutoCompleteInputDataModel[]): IAutoCompleteModel[] => {
    let data: IAutoCompleteModel[] = [];
    inputdata.forEach(element => {
        data.push({
            label: element.value1,
            value: element.value2
        });
    });
    return data;
}
export const CreateAutoCompleteCurrencyData = (inputdata: IAutoCompleteInputDataModel[]): IAutoCompleteModel[] => {
    let data: IAutoCompleteModel[] = [];
    inputdata.forEach(element => {
        data.push({
            label: element.value1 + ' (' + element.value2 + ')',
            value: element.value2
        });
    });
    return data;
}

export const numberFormat = (value: number | bigint | null, symbol?: string) => {
    if (value != null) {
        if (value >= 0) {
            return symbol + ' ' + new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 4
            }).format(value).replace("$", "").trim();
        } else {
            return '- ' + symbol + ' ' + new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 4
            }).format(value).replace("$", "").replace("-", "").trim();
        }
    }
    return "";
}

export const numberFormatTwoDecimalPlaces = (value: number | bigint | null, symbol?: string) => {
    if (value != null) {
        if (value >= 0) {
            return symbol + ' ' + new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2
            }).format(value).replace("$", "").trim();
        } else {
            return '- ' + symbol + ' ' + new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2
            }).format(value).replace("$", "").replace("-", "").trim();
        }
    }
    return "";
}

export const formatToDate = (dateVal: string | number | Date) => {
    let date, month, year;
    var inputDate = new Date(dateVal);
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

    return `${month}/${date}/${year}`;
}
