import React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid, GridColDef, GridRowsProp, GridRowModesModel, GridEventListener, GridRowEditStopReasons, GridRowModel, GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import AddUser from "@mui/icons-material/PersonAdd";
import { IManageProjectModel, IDeleteProjectModel } from "./IProject";
import ProjectServices from "./ProjectServices";
import { ProjectModalDialog } from "./AddProjectModal";
import { Loading } from "../Shared/Loading";
import { Messages, NotifyType, UserRoleType } from "../Shared/constant";
import Notify from "../Shared/notify";
import { formatToDate } from '../Shared/Utils';
import { AlertDialog } from '../Shared/ConfirmationAlertDialog';
import UnAuthorized from "../Shared/UnAuthorized";

const initialRows: GridRowsProp = [];
const defaultValues: IManageProjectModel = { id: 0, projectCode: "", projectTitle: "", description: "", parent: null, isTracked: false, isActive: false, startDate: undefined, endDate: undefined, createdBy: +window.UserId };

export default function ProjectDetails() {
  const [rows, setRows] = React.useState(initialRows);
  const [projectID, setProjectID] = React.useState<number>(0);
  const [manageProject, setManageProject] = React.useState(defaultValues);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [openProjectModaldialog, setOpenProjectModal] = React.useState<boolean>(false);
  const [isLoadProjectDetailData, setIsLoadProjectDetailData] = React.useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [reloadModalPopup, setReloadModalPopup] = React.useState<boolean>(false);
  const [gridRowDataList, setGridRowDataList] = React.useState<IManageProjectModel[]>([]);
  const [openConfirmation, setopenConfirmation] = React.useState<boolean>(false);
  const [projectNumber, setProjectNumber] = React.useState<string>("");

  useEffect(() => {
    setManageProject(defaultValues);
    getProjectDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getProjectDetails = async () => {
    setIsLoadProjectDetailData(false);
    ProjectServices.GetProjectDetails(0).then((res) => {
      if (!res.hasErrors && res.value) {
        const projects = res.value.filter(p => p.parent === null);
        projects.forEach((element) => {
          setManageProject({
            ...manageProject,
            id: element.id,
            projectCode: element.projectCode,
            projectTitle: element.projectTitle,
            description: element.description,
            parent: element.parent,
            isTracked: element.isTracked,
            isActive: element.isActive,
            startDate: element.startDate,
            endDate: element.endDate,
          });
        });
        setRows(projects);
        setGridRowDataList(projects);
        setIsLoadProjectDetailData(true);
      }
    });
  };

  const openProjectModalEdit = () => {
    setIsLoadProjectDetailData(true);
  };

  const closeProjectModal = () => {
    setOpenProjectModal(false);
    setProjectID(0);
  };

  const handlecloseUserModal = () => {
    setOpenProjectModal(false);
    setProjectID(0);
    getProjectDetails();
  };

  const handleClickOpenProjectModal = () => {
    setIsEditMode(false);
    setOpenProjectModal(true);
    setManageProject(defaultValues);
    setProjectID(0);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setProjectID(+id);
    const rowData = rows.filter(x => x.id === id)[0];
    setManageProject({
      ...manageProject,
      id: rowData.id,
      projectCode: rowData.projectCode,
      projectTitle: rowData.projectTitle,
      description: rowData.description,
      startDate: rowData.startDate,
      endDate: rowData.endDate,
      isTracked: rowData.isTracked,
      isActive: rowData.isActive,
    });
    setReloadModalPopup(true);
    setIsEditMode(true);
    setOpenProjectModal(true);
  };

  const handleDeleteClick = (pid: GridRowId) => async () => {
    setProjectID(+pid);
    const rowData = rows.filter(x => x.id === pid)[0];
    setProjectNumber(rowData.projectCode);
    setopenConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setopenConfirmation(false);
  };

  const removeProject = async () => {
    setopenConfirmation(false);
    setIsLoadProjectDetailData(false);
    setRows(rows.filter((row) => row.id !== projectID));

    let deleteProject: IDeleteProjectModel = {
      id: +projectID,
      createdBy: +window.UserId
    };

    var response = await ProjectServices.DeleteProject(deleteProject);
    if (response.success && !response.hasErrors && response.value) {
      getProjectDetails();
      Notify(NotifyType.success, Messages.ManageProject.deleted);
    } else {
      Notify(NotifyType.error, Messages.ManageProject.error);
    }

    setIsLoadProjectDetailData(true);
  }

  const columns: GridColDef[] = [
    {
      field: "projectCode",
      headerName: "Project Number",
      width: 200,
      editable: false,
    },
    {
      field: "projectTitle",
      headerName: "Project Title",
      width: 200,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      editable: false,
    },
    {
      field: "parentCode",
      headerName: "Parent",
      width: 140,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 160,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        } else {
          return formatToDate(params.value);
        }
      }
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 160,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        } else {
          return formatToDate(params.value);
        }
      }
    },
    {
      field: "isTracked",
      headerName: "Tracked",
      width: 100,
      editable: false,
      cellClassName: 'isactive-col',
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 100,
      editable: false,
      cellClassName: 'isactive-col',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params: any) => {
        //const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (params.row.parent === null && params.row.isActive) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(params.id)}
              color="inherit"
            />
          ];
        }
        else if (params.row.parent === null && !params.row.isActive) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(params.id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="inherit"
              disabled={true}
            />
          ];
        }
        else {
          return [];
        }
      }
    }
  ];

  return (
    <>
      {window.RoleId === UserRoleType.Admin.toString() || window.RoleId === UserRoleType.ProjectManager.toString() || window.RoleId === UserRoleType.Accountant.toString() ? (
        <><div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h1 className="page-title">Project Management</h1>
            </div>
          </div>
        </div><div className="row">
            <div className="col-12">
              <div className="card user-detail-card project-detail-card">
                <div className="card-body">
                  {/* <div className="section-header">
      <h4 className="header-title">Project Details</h4>
      <p className="text-muted font-14">
        Add, modify or remove Project Details.
      </p>
    </div> */}

                  <div className="dataTables_wrapper">
                    <div className="row">
                      <div className="col-sm-12 col-md-8"></div>
                      <div className="col-sm-12 col-md-4">
                        <div className="dataTables_filter">
                          {isLoadProjectDetailData ? (
                            <Button
                              color="primary"
                              startIcon={<AddUser />}
                              className="btn-icon-link btn mx-3 add-user-button"
                              onClick={handleClickOpenProjectModal}
                            >
                              Add Parent Project
                            </Button>
                          ) : null}

                          {/* <label>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={autoCompleteData}
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        onChange={handleInputChange}
        className="autocomplete form-control"
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Search User"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </label> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '100%', width: '100%' }} className="grid_wrapper">
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                      }}
                    >
                      {isLoadProjectDetailData ? (
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          editMode="row"
                          className="user-details-grid custom-details-grid gridPrjMgmtTbl"
                          rowModesModel={rowModesModel}
                          onRowModesModelChange={handleRowModesModelChange}
                          onRowEditStop={handleRowEditStop}
                          processRowUpdate={processRowUpdate}
                          slotProps={{
                            toolbar: { setRows, setRowModesModel },
                          }} />
                      ) : (
                        <div className="loader">
                          <Loading />
                        </div>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div><AlertDialog
            Open={openConfirmation}
            DialogTitle={Messages.ManageProject.confirm}
            DialogContentText={Messages.ManageProject.confirmMessage + "  " + projectNumber + " ?"}
            ButtonYes={"Yes"}
            ButtonNo={"No"}
            Confirm={removeProject}
            Cancel={closeConfirmationModal} />
            <ProjectModalDialog
            Open={openProjectModaldialog}
            DialogTitle={isEditMode ? "Edit Parent Project" : "Add New Parent Project"}
            DialogContentText={Messages.ManageProject.confirmMessage}
            ButtonYes={"Yes"}
            ButtonNo={"No"}
            onCancel={closeProjectModal}
            onClose={handlecloseUserModal}
            IsEditMode={isEditMode}
            rowData={manageProject}
            OpenProjectModalEdit={openProjectModalEdit}
            setReloadModalPopup={setReloadModalPopup}
            ReloadModalPopup={reloadModalPopup}
            gridRowData={gridRowDataList} /></>
      ) : (
        <div>
          <UnAuthorized />
        </div>
      )}
    </>
  );
}