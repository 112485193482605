import { ITimeEntryDownloadResponseModel } from "../TimeEntry/ITimeEntry";
import { Result } from "../models/Result";
// import * as jsonToUrl from "json-to-url";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
//import {ERROR_STATE} from "../layout/GlobalErrorController";
//import { msalConfig } from '../authConfig';


export interface IRequestOptions {
    url: string;
    data?: any;
    method: "GET" | "POST" | "PUT" | "DELETE";
    fileType?: string;
    responseType?: string;
}

export interface ISendFormDataOptions {
    url: string;
    data: FormData;
    method: "POST" | "PUT" | "PATCH";
}

/**
 * Represents base class of the isomorphic service.
 */
export abstract class ServiceBase {

    /**
     * Make request with JSON data.
     * @param opts
     */
    public static async requestJson<T>(opts: IRequestOptions): Promise<Result<T>> {
        let axiosResponse: AxiosResponse | null = null;

        var result: Result<T> | null = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${(data)}`;
            }
            return url;
        };

        Axios.defaults.baseURL = 'http://localhost:3000/login/callback'; //msalConfig.auth.redirectUri;
        let tokenHolder = localStorage.getItem("accesstoken") ?? ''; //window.accessToken;
        const headers = {
            Authorization: `Bearer ${tokenHolder}`,
            'Accept': 'application/json'
        };


        var axiosRequestConfig: AxiosRequestConfig;
        axiosRequestConfig = {
            url: process.env.REACT_APP_baseUrl,
            baseURL: process.env.REACT_APP_baseUrl,
            headers: headers,
            responseType: opts.responseType != null ? 'blob' : 'json'
        };

        try {
            switch (opts.method) {
                case "GET":
                    axiosResponse = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResponse = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResponse = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResponse = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
            }

            if (!axiosResponse)
                throw new Error('No Result');
            
            let filename = '';
            let res = axiosResponse.data;
            if(axiosResponse.headers['content-disposition']){
                filename = axiosResponse.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '').replace('"', '');

                res = {fileContent: axiosResponse.data, fileName: filename.substring(1, filename.length - 1) };
            }
            
            result = new Result<T>(res as T, true, [], null);

        } catch (error: any) {
            //ServiceBase.dispatchGlobalError(error.message);
            if (error.response) {
                result = new Result<T>(null, false, [error.response.data], null);
            } else {
                result = new Result<T>(null, false, [], null);
            }
        }
        return result;
    }

    private static dispatchGlobalError(message: string) {
        console.log('error: ' + message);
        /*Cache.setItem(ERROR_STATE, { title: 'Error', description: message});
        Hub.dispatch(
          'ErrorChannel',
          {
            event: 'errorOccurred',
            data: {hasError:true},
            message:''
          });*/
    }

    /**
     * Allows you to send files to the server.
     * @param opts
     */
    public static async sendFormData<T>(opts: ISendFormDataOptions): Promise<Result<T>> {
        let axiosResponse: AxiosResponse | null = null;
        let axiosResData: any = null;
        var result: Result<T> | null = null;

        let tokenHolder = localStorage.getItem("accesstoken");
        const headers = { Authorization: `Bearer ${tokenHolder}`, 'Content-Type': 'multipart/form-data' };


        var axiosRequestConfig: AxiosRequestConfig;
        axiosRequestConfig = {
            baseURL: process.env.REACT_APP_baseUrl,
            headers: headers
        };


        try {
            switch (opts.method) {
                case "POST":
                    axiosResponse = await Axios.post(opts.url, opts.data, axiosRequestConfig);                    
                    break;
                case "PUT":
                    axiosResponse = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
            }

            if (!axiosResponse)
                throw new Error('No Result');
            axiosResData = axiosResponse.data;
            result = new Result<T>(axiosResData as T, axiosResData.success, axiosResData.customErrors, null);

        } catch (error: any) {
            result = new Result<T>(null, false, axiosResData.customErrors, null);
        }

        return result;
    }

    public static async downloadFiles<T>(opts: IRequestOptions): Promise<Result<T>> {
        let axiosResponse: AxiosResponse | null = null;

        var result: Result<T> | null = null;

        Axios.defaults.baseURL = 'http://localhost:3000/login/callback'; //msalConfig.auth.redirectUri;
        let tokenHolder = localStorage.getItem("accesstoken");
        const headers = {
            Authorization: `Bearer ${tokenHolder}`,
            'Accept': 'application/json'
        };


        var axiosRequestConfig: AxiosRequestConfig;
        axiosRequestConfig = {
            url: process.env.REACT_APP_baseUrl,
            baseURL: process.env.REACT_APP_baseUrl,
            headers: headers,
            responseType: "blob"
        };

        // Add a request interceptor to handle headers
        Axios.interceptors.response.use(undefined, err => {
            const error = err.response;
            // if error is 401 
            if (error.status === 401) {
                //window.location.reload();
            }
        });

        try {            
            switch (opts.method) {
                case "GET":
                    await Axios.get(opts.url, axiosRequestConfig).then((response) => {//processQuery(opts.url, opts.data)
                        console.log(response.headers);
                        axiosResponse = response;
                        const url = window.URL.createObjectURL(new Blob([response.data as BlobPart]));
                        const link = document.createElement('a');
                        link.href = url;
                        console.log(opts);
                        if (opts.fileType === "PAcsv") {
                            link.setAttribute('download', `${opts.data.FileName}`);
                        }
                        else if (opts.fileType === "csv") {
                            link.setAttribute('download', opts.data.isUserAudit ? `AuditReport.xlsx` : `UserAuditReport.csv`);
                        }
                        else if (opts.fileType === "zip") {
                            link.setAttribute('download', `${opts.data.ProjectNumber}.zip`);
                        }
                        else if (opts.fileType === "message") {
                            link.setAttribute('download', `${opts.data.ProjectNumber + "-" + opts.data.MessageId}.zip`);
                        }
                        else {
                            link.setAttribute('download', `${opts.data.FileName}`);
                        }
                        document.body.appendChild(link);
                        link.click();
                    });;
                    break;
            }

            if (!axiosResponse)
                throw new Error('No Result');

            result = new Result<T>(axiosResponse as T, true, [], null);

        } catch (error: any) {

            console.log(error);
            //ServiceBase.dispatchGlobalError(error.message);
            result = new Result<T>(null, false, [], null);

        }

        return result;
    }    

    public static async downloadPDF<T>(opts: IRequestOptions): Promise<Result<T>> {
        let axiosResponse: AxiosResponse | null = null;

        var result: Result<T> | null = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${(data)}`;
            }
            return url;
        };

        Axios.defaults.baseURL = 'http://localhost:3000/login/callback'; //msalConfig.auth.redirectUri;
        let tokenHolder = localStorage.getItem("accesstoken") ?? ''; //window.accessToken;
        const headers = {
            Authorization: `Bearer ${tokenHolder}`,
            'Accept': 'application/json'
        };


        var axiosRequestConfig: AxiosRequestConfig;
        axiosRequestConfig = {
            url: process.env.REACT_APP_baseUrl,
            baseURL: process.env.REACT_APP_baseUrl,
            headers: headers,
            responseType: 'blob'
        };

        try {
            switch (opts.method) {                
                case "POST":
                    axiosResponse = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;                
            }

            if (!axiosResponse)
                throw new Error('No Result');
            
            let filename = '';
            if(axiosResponse.headers['content-disposition']){
                filename = axiosResponse.headers['content-disposition']
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .trim();
            }
            
            result = new Result<T>(axiosResponse.data as T, true, [], null);

        } catch (error: any) {
            //ServiceBase.dispatchGlobalError(error.message);
            if (error.response) {
                result = new Result<T>(null, false, [error.response.data], null);
            } else {
                result = new Result<T>(null, false, [], null);
            }
        }
        return result;
    }
}