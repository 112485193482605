import * as React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading: React.FunctionComponent<any> = (props) => (
    <React.Fragment>
        <Box>
            <div className="overlay">
                <div className="overlay__inner">
                    <div className="overlay__content">
                    <CircularProgress size={100}/>
                    </div>
                </div>
            </div>
            
        </Box>
    </React.Fragment>
);