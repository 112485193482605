  import { Result } from "../models/Result";
  import { ServiceBase } from "../services/ServiceBase";
  import { IBreadcrumbModel, IFavouriteProjectModel, IUpsertFavProjectModel } from "./IProjectSelection";
  
  export default class ProjectSelectionServices extends ServiceBase {
    public static async GetFavouriteProjects(userId: number): Promise<Result<IFavouriteProjectModel[]>> {
      var result = await this.requestJson<IFavouriteProjectModel[]>({
        url: `api/project/getProjectSelection?userId=${userId}`,
        method: "GET",
      });
      return result;
    }

    public static async upsertFavouriteProject(manageProject: IUpsertFavProjectModel): Promise<Result<IUpsertFavProjectModel>> {
      var result = await this.requestJson<IUpsertFavProjectModel>({
        url: `api/project/upsertFavouriteProject`,
        method: "POST",
        data: manageProject,
      });
      return result;
    }

    public static async GetProjectsCrewsForBreadcrumb(): Promise<Result<IBreadcrumbModel[]>> {
      var result = await this.requestJson<IBreadcrumbModel[]>({
        url: `api/project/GetProjectsCrewsForBreadcrumb`,
        method: "GET",
      });
      return result;
    }
  }
  