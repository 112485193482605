import React, { version } from 'react';
import { Routes, Route, Router } from 'react-router';
import Layout from './components/pages/Layout';
import AppRoutes from './AppRoutes';
import { loginRequest } from "./authConfig";
import RequestInterceptor from "./RequestInterceptor";
import { injectStyle } from "react-toastify/dist/inject-style";
import { Button, CircularProgress, Switch } from "@mui/material";
import { MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Configuration, IPublicClientApplication } from '@azure/msal-browser';
import { ToastContainer } from "react-toastify";
import logoHSWC from '../src/assets/images/header-logo.svg';
import UserAuthenticate from "./components/Shared/UserAuthentication";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import "./assets/css/style.css";
import 'react-toastify/dist/ReactToastify.css';
import 'devextreme/dist/css/dx.light.css';
import { Loading } from './components/Shared/Loading';


if (typeof window !== "undefined") {
  injectStyle();
}

export const user = {
  authenticated: false
}


const MainContent = () => {

  const [isAuthorized, setAuthorized] = React.useState<boolean>(false);
  const [isloadData, setIsLoadData] = React.useState<boolean>(false);
  //const [isLoad, setIsLoad] = React.useState<boolean>(false);
  const { instance, accounts } = useMsal();

  const login = () => {
    setIsLoadData(true);
    instance.loginRedirect(loginRequest)
    setIsLoadData(false);
  };

  return (

    <div>
      {/* {isloadData ? ( */}
        <div className="App">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <UserAuthenticate Isauthenticated={true} AccountInfo={accounts[0]} setAuthorized={setAuthorized} />

          {
            isAuthorized ?
              <RequestInterceptor accounts={accounts} instance={instance}>
                <div className="main-container">
                  {/* <Router routes={routes} /> */}
                  <Layout>
                    <Routes>
                      {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                      })}
                    </Routes>
                  </Layout>
                </div>
              </RequestInterceptor>
              :
              <UnauthenticatedTemplate>
                <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                  <div className="container login-wrap">
                    <div className="row justify-content-center">
                      <div className="col-xxl-5 col-lg-6">
                        <div className="card">
                          <div className='card-header'>
                            <div className="text-center">
                              <a href="javascript:;">
                                <span><img src={logoHSWC} alt="" /></span>
                              </a>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="text-center w-75 m-auto">
                              <h4 className="text-center mt-3">Hi there!</h4>
                              <p className="mb-4">Please sign in below to continue to HSWC time entry.</p>
                            </div>
                            <form action="#">
                              <div className="mb-0 text-center signin">
                                <Button className="btn btn-primary" type="button" onClick={login}><i className='bi bi-box-arrow-in-left me-1'></i>Sign in with Microsoft</Button>
                              </div>
                            </form>

                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-12 text-center">
                            {/* <p>2022 © Alorica NextGen - alorica.com <a href="pages-login.html" className="text-muted ms-1"><b>About</b></a> | <a href="pages-login.html" className="text-muted ms-1"><b>Support</b></a> | <a href="pages-login.html" className="text-muted ms-1"><b>Contact</b></a></p> */}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </UnauthenticatedTemplate>
          }

        </div>
    
    </div>
  );
};

type AppProps = {
  pca?: IPublicClientApplication
};

export default function App({ pca }: AppProps) {

  const isProduction = process.env.NODE_ENV === 'development';

  return (
    <MsalProvider instance={pca}>
      <MainContent />
    </MsalProvider>
  );
}

