import React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowsProp, GridRowModesModel, GridEventListener, GridRowEditStopReasons, GridRowModel, GridColumnVisibilityModel, GridValueFormatterParams } from "@mui/x-data-grid";
import { Loading } from "../../Shared/Loading";
import { Breadcrumbs, Button, IconButton, Link, TextField } from "@mui/material";
import { Messages } from "../../Shared/constant";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CrewSelectionServices from "./CrewSelectionServices";
import { useLocation, useNavigate } from "react-router-dom";
import { CrewSelectionModalDialog } from "./AddCrewSelectionModal";
import AddUser from "@mui/icons-material/PersonAdd";
import { IManageCrewModel } from "../../CrewDetail/ICrew";
import { ICrewSelectionModel } from "./ICrewSelection";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProjectSelectionServices from "../ProjectSelectionServices";
import { useCookies } from "react-cookie";

const initialRows: GridRowsProp = [];
const defaultValues: IManageCrewModel = { id: 0, name: "", projectId: 0, projectName: "", projectTitle: "", supervisorName: "", supervisor: 0, isActive: false };

export default function CrewSelection() {
  const location = useLocation();
  const [rows, setRows] = React.useState(initialRows);
  const [crewList, setCrewList] = React.useState(initialRows);
  const [isLoadCrewSelectionData, setIsLoadCrewSelectionData] = React.useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({ id: false });
  const [manageCrew, setManageCrew] = React.useState(defaultValues);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [openCrewModaldialog, setOpenCrewModal] = React.useState<boolean>(false);
  const [crewID, setCrewID] = React.useState<number>(0);
  const [reloadModalPopup, setReloadModalPopup] = React.useState<boolean>(false);
  const [gridRowDataList, setGridRowDataList] = React.useState<ICrewSelectionModel[]>([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState<number>(0);
  const [projectTitle, setProjectTitle] = React.useState<string>("");
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["projectSelection"]);

  function setCookies(userId: number, projectId: number, crewId: number) {
    setCookie("projectSelection", `userId:${userId}&projectId:${projectId}&crewId:${crewId}`,
      {
        path: "/",
      }
    );
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/projectSelection">
      Projects
    </Link>,
    <span key={projectTitle}>
      {projectTitle}
    </span>,
  ];

  useEffect(() => {
    const myParam = new URLSearchParams(location.search).get("projectId");
    setSelectedProjectId(myParam === null ? 0 : parseInt(myParam));
    getCrewDetails(myParam === null ? 0 : parseInt(myParam));
    getBreadcrumbsDetails(myParam === null ? 0 : parseInt(myParam));
  }, []);

  const getCrewDetails = async (projectId: number) => {
    setIsLoadCrewSelectionData(false);
    CrewSelectionServices.GetCrewSelectionByProjectId(projectId).then((res) => {
      if (!res.hasErrors && res.value) {
        const crews = res.value;
        setCrewList(crews);
        setRows(crews);
        setIsLoadCrewSelectionData(true);
      }
    });
  };

  const getBreadcrumbsDetails = async (projectId: number) => {
    ProjectSelectionServices.GetProjectsCrewsForBreadcrumb().then((res) => {
      if (!res.hasErrors && res.value) {
        const project = res.value.filter((x) => x.projectId === projectId)[0];
        setProjectTitle(project.projectCode + "-" + project.projectTitle);
      }
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  function search(value: string) {
    if (value !== "") {
      setRows(crewList.filter((x) => x.crewName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        x.supervisor.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
    } else {
      setRows(crewList);
    }
  }

  const handleClickOpenCrewModal = () => {
    setIsEditMode(false);
    setOpenCrewModal(true);
    setManageCrew(defaultValues);
    setCrewID(0);
  };

  const closeUserModal = () => {
    setOpenCrewModal(false);
    setCrewID(0);
  };

  const handlecloseUserModal = () => {
    setOpenCrewModal(false);
    setCrewID(0);
    getCrewDetails(selectedProjectId);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    setCookies(+window.UserId, selectedProjectId, parseInt(params.id.toString()));
    setCrewID(parseInt(params.id.toString()));
    navigate(`/timeEntrySelection`); //?crewId=${params.id}
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Crew Id",
      width: 100,
      editable: false,
    },
    {
      field: "crewName",
      headerName: "Crew Name",
      width: 300,
      editable: false,
    },
    {
      field: "supervisor",
      headerName: "Supervisor/Created By",
      width: 220,
      editable: false,
      renderCell: (params) => {
        return (
          <div className="text-start">
            <span
              dangerouslySetInnerHTML={{
                __html: `${params.value.split(":")[0]}:` + "<br/>",
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${params.value.split(":")[1]}`,
              }}
            />
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      editable: false,
    },
    {
      field: "lastTimesheet",
      headerName: "Last Time Sheet",
      width: 200,
      editable: false,
    },
  ];

  return (
    <>
      <div className="breadcrumb-section">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div className="page-title-box">
        <h1 className="page-title">Crew Selection</h1>
      </div>
      <div className="card user-detail-card pages-detail-card">
        <div className="card-body">
          <div className="dataTables_wrapper">
            <div className="row mb-2">
              <div className="col-sm-12 col-md-8 d-flex">
                <TextField
                  id="standard-bare"
                  variant="outlined"
                  placeholder="Search..."
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 justify-content-end d-flex">
                {isLoadCrewSelectionData ? (
                  <Button
                    color="primary"
                    startIcon={<AddUser />}
                    className="btn-icon-link btn mx-3 add-user-button"
                    onClick={handleClickOpenCrewModal}
                  >
                    Add Crew
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="grid_wrapper autoheightcell">
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              {isLoadCrewSelectionData ? (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  editMode="row"
                  className="user-details-grid custom-details-grid"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  columnVisibilityModel={columnVisibilityModel}
                  processRowUpdate={processRowUpdate}
                  onRowClick={handleRowClick}
                  autoHeight={true}
                  slotProps={{
                    toolbar: { setRows, setRowModesModel },
                  }}
                  sx={{
                    // disable cell selection style
                    ".MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    // pointer cursor on ALL rows
                    "& .MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              ) : (
                <div className="loader">
                  <Loading />
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>

      <CrewSelectionModalDialog
        Open={openCrewModaldialog}
        DialogTitle={isEditMode ? "Edit Crew" : "Add New Crew"}
        DialogContentText={Messages.ManageUser.confirmMessage}
        ButtonYes={"Yes"}
        ButtonNo={"No"}
        onCancel={closeUserModal}
        onClose={handlecloseUserModal}
        IsEditMode={isEditMode}
        rowData={manageCrew}
        // OpenCrewModalEdit={openCrewModalEdit}
        setReloadModalPopup={setReloadModalPopup}
        ReloadModalPopup={reloadModalPopup}
        gridRowData={gridRowDataList}
        projectId={selectedProjectId}
      />
    </>
  );
}
