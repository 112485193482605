
import React from 'react';
import UserDetails from "./components/pages/UserDetails";
import { Reports } from './components/pages/Reports';
import CrewDetails from './components/CrewDetail/CrewDetails';
import CrewList from './components/CrewList/CrewList';
import ProjectDetails from './components/ProjectDetail/ProjectDetails';
import ActivityBudgetList from './components/ActivityAndBudget/ActivityBudgetList';
import ProjectSelection from './components/ProjectSelection/ProjectSelection';
import CrewSelection from './components/ProjectSelection/CrewSelection/CrewSelection';
import TimeEntrySelection from './components/ProjectSelection/CrewSelection/TimeEntry/TimeEntrySelection';
import AccountSummary from './components/Reports/AccountSummary/AccountSummary';
import TimeEntryExport from './components/Reports/TimeEntryDataExport/TimeEntryExport';
import SummaryReport from './components/Reports/Summary/SummryReport';
import ActivityOnAPageReport from './components/Reports/WorkPackage/ActivityOnAPageReport';

const AppRoutes = [
  {
    index: true,
    element: <TimeEntrySelection />
  },
  {
    path: '/projectSelection',
    element: <ProjectSelection />
  },
  {
    path: '/userDetails',
    element: <UserDetails />
  },
  {
    path: '/timeEntry',
    element: <TimeEntrySelection />
  },
  {
    path: '/reports',
    element: <Reports />
  },
  {
    path: '/projectDetails',
    element: <ProjectDetails /> 
  },
  {
    path: '/crewDetails',
    element: <CrewDetails /> 
  },
  {
    path: '/crewList',
    element: <CrewList />
  },
  {
    path: '/activityBudgetList',
    element: <ActivityBudgetList /> 
  },
  {
    path: '/crewSelection',
    element: <CrewSelection />
  },
  {
    path: '/timeEntrySelection',
    element: <TimeEntrySelection />
  },
  {
    path: '/accountSummary',
    element: <AccountSummary /> 
  },
  {
    path: '/timeEntryExport',
    element: <TimeEntryExport /> 
  },
  {
    path: '/summaryReport',
    element: <SummaryReport /> 
  },
  {
    path: '/activityPageReport',
    element: <ActivityOnAPageReport /> 
  }
];

export default AppRoutes;