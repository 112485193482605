import { Result } from "../models/Result";
import { ServiceBase } from "../services/ServiceBase";
import { ITimeEntryRequestModel, IManageTimeEntryModel, ITimeEntrySaveRequestModel, ITimeEntryCrewMemberRequestModel, ITimeEntryCrewMemberResponseModel, ITimeEntryActivityResponseModel, ITimeEntryActivityRequestModel, ITimeEntrySubmitRequestModel, ISaveActivityNotesRequestModel,ITimeEntryDownloadResponseModel } from "./ITimeEntry";

export default class TimeEntryServices extends ServiceBase {
    public static async GetTimeEntry(manageTimeENtry: ITimeEntryRequestModel): Promise<Result<IManageTimeEntryModel[]>> {
        var result = await this.requestJson<IManageTimeEntryModel[]>({
            url: `api/timeentry`,
            method: "POST",
            data: manageTimeENtry,
        });

        return result;
    }

    public static async SaveTimeEntry(saveRequestModel: ITimeEntrySaveRequestModel): Promise<Result<IManageTimeEntryModel[]>> {
        var result = await this.requestJson<IManageTimeEntryModel[]>({
            url: `api/timeentry/save`,
            method: "POST",
            data: saveRequestModel
        });
        return result;
    }

    public static async GetCrewMembers(crewmMemberRequestModel: ITimeEntryCrewMemberRequestModel): Promise<Result<ITimeEntryCrewMemberResponseModel[]>> {
        var result = await this.requestJson<ITimeEntryCrewMemberResponseModel[]>({
            url: `api/crewmember`,
            method: "POST",
            data: crewmMemberRequestModel,
        });

        return result;
    }

    public static async GetActivities(activityRequestModel: ITimeEntryActivityRequestModel): Promise<Result<ITimeEntryActivityResponseModel[]>> {
        var result = await this.requestJson<ITimeEntryActivityResponseModel[]>({
            url: `api/activity`,
            method: "POST",
            data: activityRequestModel,
        });

        return result;
    }

    public static async SubmitTimeEntry(submitRequestModel: ITimeEntrySubmitRequestModel): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `api/timeentry/submit`,
            method: "POST",
            data: submitRequestModel
        });
        return result;
    }

    public static async UnLockTimeEntry(submitRequestModel: ITimeEntrySubmitRequestModel): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `api/timeentry/unlock`,
            method: "POST",
            data: submitRequestModel
        });
        return result;
    }

    public static async SaveActivityNotes(saveNotesRequestModel: ISaveActivityNotesRequestModel): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `api/timeentry/savenotes`,
            method: "POST",
            data: saveNotesRequestModel
        });
        return result;
    }

    public static async DownloadTimeEntry(timeEntryRequest: ITimeEntryRequestModel): Promise<Result<ITimeEntryDownloadResponseModel>> {
        var result = await this.requestJson<ITimeEntryDownloadResponseModel>({
            url: `api/timeentry/downloadtimeentry`,
            method: "POST",
            data: timeEntryRequest,
            responseType: 'blob'
        });

        return result;
    }
}