import React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridRowModesModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridColumnVisibilityModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridApi,
} from "@mui/x-data-grid";
import { Loading } from "../../Shared/Loading";
import ReportServices from "../ReportsServices";
import CrewServices from "../../CrewDetail/CrewServices";
import { IProjectsModel } from "../../models/IUser";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { IAccountSummaryInput } from "../IReports";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Messages, UserRoleType } from "../../Shared/constant";
import SearchIcon from "@mui/icons-material/Search";
import UnAuthorized from "../../Shared/UnAuthorized";

const initialRows: GridRowsProp = [];

//#region ToolBar

interface EditToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
  title: "";
  handleFilesChange: any;
  isReadOnly: boolean;
  isDisplayAutoButton: boolean;
}

let selectedProjectCode = "";
let selectedWeekendDate = "";

function EditToolbar(props: EditToolbarProps) {
  const { apiRef, handleFilesChange, isReadOnly, isDisplayAutoButton } = props;

  return (
    <>
      <div className="row mt-2 align-items-center">
        <div className="col">
          <h5 className="py-3">{props.title}</h5>
        </div>
        <div className="col d-flex justify-content-end">
          <GridToolbarContainer>
            <GridToolbarExport
              color="primary"
              className="btn-icon-link btn"
              csvOptions={{
                fileName: `AccountingExport_${selectedWeekendDate}_${selectedProjectCode}`,
                utf8WithBom: true,
              }}
              printOptions={{ disableToolbarButton: true }}
            />
          </GridToolbarContainer>
        </div>
      </div>
    </>
  );
}
//#endregion

const defaultValues: IAccountSummaryInput = {
  projectCode: "",
  weekEndDate: "",
  isSubmitted: 3
};

export default function AccountSummary() {
  const [rows, setRows] = React.useState(initialRows);
  const [isLoadAccountData, setIsLoadAccountData] =
    React.useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({ id: false });
  const [projects, setProjects] = React.useState<IProjectsModel[]>([]);
  const [inputPrams, setInputParams] = React.useState(defaultValues);
  const [errWeekendDate, setErrWeekendDate] = React.useState<boolean>(false);
  const [errMessageWeekendDate, setErrMessageWeekendDate] = React.useState("");
  const [errProjects, setErrProjects] = React.useState<boolean>(false);
  const [errMessageProject, setErrMessageProject] = React.useState("");

  useEffect(() => {
    getProjects();
  }, []);

  const getAccountSummaryDetails = async (
    projectId: number,
    weekEndDate: string,
    IsSubmitted: number
  ) => {
    ReportServices.GetAccountSummary(projectId, weekEndDate, IsSubmitted).then((res) => {
      if (!res.hasErrors && res.value) {
        const accounts = res.value;
        setRows(accounts);
        setIsLoadAccountData(true);
      }
    });
  };

  const getProjects = async () => {
    setIsLoadAccountData(false);
    CrewServices.GetProjects(0).then((res) => {
      if (!res.hasErrors && res.value) {
        setProjects(
          res.value.filter((x) => x.parent == null && x.isActive == true && x.isTracked == true)
        );
        setIsLoadAccountData(true);
      }
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleChange = (value: any, cntrlName: string) => {
    if (value === undefined || value === null || value === "" || value === 0 || value.length === 0) {
      switch (cntrlName) {
        case "WeekEndDate":
          setErrWeekendDate(true);
          setErrMessageWeekendDate(Messages.Reports.requiredWeedendDate);
          setInputParams({
            ...inputPrams,
            weekEndDate: "",
        });
          break;
        case "ProjectCode":
          setErrProjects(true);
          setErrMessageProject(Messages.Reports.requiredProjectCode);
          setInputParams({
            ...inputPrams,
            projectCode: "",
        });
          break;
      }
    } else {
      switch (cntrlName) {
        case "WeekEndDate":
          setErrWeekendDate(false);
          setInputParams({
            ...inputPrams,
            weekEndDate: getNextSundayDate(
              dayjs(value).format("YYYY-MM-DDThh:mm:ss")
            ).toLocaleString(),
          });
          selectedWeekendDate = convert(
            getNextSundayDate(
              dayjs(value).format("YYYY-MM-DDThh:mm:ss")
            ).toString()
          );
          setErrWeekendDate(false);
          break;
        case "ProjectCode":
          setInputParams({
            ...inputPrams,
            projectCode: value,
          });
          setErrProjects(false);
          break;
      }
    }
  };

  const handleIsSubmittedChange = (event: SelectChangeEvent) => {
    setInputParams({
      ...inputPrams,
      isSubmitted: parseInt(event.target.value)
    });
  };

  const handleErrors = (value: any, cntrlName: string) => {
    resetErrorState();
    if (value === undefined || value === null || value === "" || value === 0) {
      switch (cntrlName) {
        case "ProjectCode":
          setErrProjects(true);
          setErrMessageProject(Messages.Reports.requiredProjectCode);
          break;
        case "WeekendDate":
          setErrWeekendDate(true);
          setErrMessageWeekendDate(Messages.Reports.requiredWeedendDate);
          break;
      }
    }
  };

  const resetErrorState = async () => {
    setErrProjects(false);
    setErrWeekendDate(false);
  };

  const validateSearchForm = (inputs: IAccountSummaryInput) => {
    resetErrorState();
    let isValid = true;

    if (
      inputs.projectCode === undefined ||
      inputs.projectCode === null ||
      inputs.projectCode === ""
    ) {
      setErrProjects(true);
      setErrMessageProject(Messages.Reports.requiredProjectCode);
      isValid = false;
    }

    if (
      inputs.weekEndDate === undefined ||
      inputs.weekEndDate === null ||
      inputs.weekEndDate === ""
    ) {
      setErrWeekendDate(true);
      setErrMessageWeekendDate(Messages.Reports.requiredWeedendDate);
      isValid = false;
    }

    if (isValid) {
      return true;
    } else {
      return false;
    }
  };
  const searchAccounts = async () => {
    if (validateSearchForm(inputPrams)) {
      setIsLoadAccountData(false);
      selectedProjectCode = inputPrams.projectCode;
      getAccountSummaryDetails(
        projects.find((x) => x.projectCode === inputPrams.projectCode)?.id ?? 0,
        inputPrams.weekEndDate, inputPrams.isSubmitted
      );
    }
  };

  const convert = (strDate: string) => {
    var date = new Date(strDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return (date.getFullYear() + mnth + day).toString();
  };

  const getNextSundayDate = (selectedDate: string) => {
    let date = new Date(selectedDate);
    if (date.getDay() !== 0) {
      date.setDate(date.getDate() + 7 - date.getDay());
    }
    return date;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      width: 75,
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      width: 300,
      editable: false,
    },
    {
      field: "employeeNumber",
      headerName: "Employee ID",
      width: 175,
      editable: false,
    },
    {
      field: "payCode",
      headerName: "Pay Code",
      width: 100,
      editable: false,
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 75,
      editable: false,
    },
    {
      field: "workDate",
      headerName: "Work Date",
      width: 150,
      editable: false,
    },
    {
      field: "jobNumber",
      headerName: "Job Number",
      width: 125,
      editable: false,
    },
    {
      field: "fullAccountNumber",
      headerName: "Full Account Number",
      width: 250,
      editable: false,
    },
    {
      field: "subType",
      headerName: "Sub Type",
      width: 100,
      editable: false,
    },
    {
      field: "subLedger",
      headerName: "Sub Ledger",
      width: 100,
      editable: false,
    },
    {
      field: "overridePayRate",
      headerName: "Override Pay Rate",
      width: 250,
      editable: false,
    },
    {
      field: "combinedName",
      headerName: "Combined Name",
      width: 350,
      editable: false,
    },
    {
      field: "group",
      headerName: "Group",
      width: 100,
      editable: false,
    },
    {
      field: "scope",
      headerName: "Scope",
      width: 175,
      editable: false,
    },
    {
      field: "activity",
      headerName: "Activity",
      width: 100,
      editable: false,
    },
    {
      field: "area",
      headerName: "Area",
      width: 100,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      editable: false,
    },
    {
      field: "pay",
      headerName: "Pay",
      width: 100,
      editable: false,
    },
    {
      field: "crewTitle",
      headerName: "Crew Title",
      width: 150,
      editable: false,
    },
    {
      field: "supervisor",
      headerName: "Supervisor",
      width: 200,
      editable: false,
    },
    {
      field: "submittedBy",
      headerName: "Submitted By",
      width: 200,
      editable: false,
    },
    {
      field: "submittedDateTime",
      headerName: "Submitted DateTime",
      width: 250,
      editable: false,
      valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : ""
    },
    {
      field: "weekEndDate",
      headerName: "Week EndDate",
      width: 175,
      editable: false,
    },
  ];

  return (
    <>
      {window.RoleId !== UserRoleType.FieldSupervisor.toString() ? (
        <>
          <div className="page-title-box">
            <h1 className="page-title">Accounting Data</h1>
          </div>
          <div className="card user-detail-card pages-detail-card">
            <div className="card-body">
              <div className="dataTables_wrapper">
                <div className="row">
                  <div className="col-sm-12 col-md-8 d-flex mb-2 gap-3">
                    <Autocomplete
                      id="drpProjects"
                      autoHighlight
                      options={projects.map((option) => option.projectCode)}
                      sx={{ width: 300 }}
                      onChange={(event: any, newValue: string | null) => {
                        handleChange(newValue, "ProjectCode");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Project Number"
                          required
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          error={errProjects}
                          helperText={errProjects ? errMessageProject : ""}
                          />
                      )} />
                    {/* <FormControl
  sx={{ minWidth: 250, maxWidth: "100%" }}
  error={errProjects && true}
>
  <InputLabel id="drpProjectID">Project Number</InputLabel>
  <Select
    labelId="demo-simple-select-standard-label"
    id="drpProjects"
    name="projectId"
    value={inputPrams.projectId}
    onChange={(e: any) => {
      handleChange(e, "ProjectCode");
    }}
    onBlur={(e) => {
      handleErrors(e.target.value, "ProjectCode");
    }}
    label="Project Number"
  >
    {projects.map((project: IProjectsModel) => (
      <MenuItem value={project.id}>
        {project.projectCode}
      </MenuItem>
    ))}
  </Select>
  {errProjects && (
    <FormHelperText>
      {errProjects
        ? Messages.Reports.requiredProjectCode
        : ""}
    </FormHelperText>
  )}
</FormControl> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Weekend Date"
                        inputFormat="MM/DD/YYYY"
                        value={inputPrams.weekEndDate}
                        onChange={(e: Dayjs | null) => {
                          handleChange(e, "WeekEndDate");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "MM/DD/YYYY",
                            }}
                            helperText={errWeekendDate ? errMessageWeekendDate : ""}
                            //required
                            error={errWeekendDate}
                            onBlur={(e) => {
                              handleErrors(e.target.value, "WeekendDate");
                            }} />
                        )} />
                    </LocalizationProvider>
                    <FormControl sx={{ minWidth: 100 }}>
                      <InputLabel id="demo-simple-select-label">
                        Submitted
                      </InputLabel>
                      <Select
                        labelId="lblIsSubmitted"
                        id="isSubmitted"
                        defaultValue={"3"}
                        label="Submitted"
                        onChange={handleIsSubmittedChange}
                      >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={2}>No</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                      </Select>
                    </FormControl>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary btn-icon btn-sm"
                        onClick={searchAccounts}
                      >
                        Search <SearchIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid_wrapper">
                <Box
                  sx={{
                    height: 500,
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                  }}
                >
                  {isLoadAccountData ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      autoHeight={true}
                      disableRowSelectionOnClick={true}
                      editMode="row"
                      className="user-details-grid custom-details-grid report-accounting"
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      columnVisibilityModel={columnVisibilityModel}
                      slotProps={{
                        toolbar: { setRows, setRowModesModel },
                      }}
                      slots={{
                        toolbar: EditToolbar,
                      }} />
                  ) : (
                    <div className="loader">
                      <Loading />
                    </div>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <UnAuthorized />
        </div>
      )}
    </>
  );
}
