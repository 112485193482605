import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UploadFile from "@mui/icons-material/UploadFile";
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from "@mui/icons-material/Edit";
import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridApi,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { Messages, UserRoleType } from "../Shared/constant";
import notify from "../Shared/notify";
import CrewListServices from "./CrewListServices";
import { ICrewMemberModel } from "../CrewDetail/ICrew";
import { Loading } from "../Shared/Loading";
import UnAuthorized from "../Shared/UnAuthorized";
import { CrewMemberModalDialog } from "./EditCrewMemberModal";

const defaultValues: ICrewMemberModel = {
  id: 0,
  employeeName: "",
  employeeNumber: "",
  area: "",
  unionCode: 0,
  title: "",
  lastCheckedDate: "",
  terminationDate: "",
  hourlyRate: 0,
  overtime: 0,
  doubleTime: 0,
};

const initialRows: GridRowsProp = [];

//#region ToolBar

interface EditToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
  title: "";
  handleFilesChange: any;
  setPage: any;
  isReadOnly: boolean;
  isDisplayAutoButton: boolean;
  createCurrentYearBillingPeriod: () => void;
}

function EditToolbar(props: EditToolbarProps) {

  return (
    <>
      <div className="row align-items-center mb-3">
        <div className="col">
          <h5 className="py-3">{props.title}</h5>
        </div>
        <div className="col d-flex justify-content-end">
          <GridToolbarContainer>
            <Button
              variant="text"
              component="label"
              color="primary"
              startIcon={<UploadFile />}
              className="btn-icon-link btn"
              data-bs-toggle="modal"
              data-bs-target="#AddBillingPeriodModal"
            >
              Upload CSV
            </Button>
          </GridToolbarContainer>
        </div>
      </div>
    </>
  );
}
//#endregion

export default function CrewList() {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [manageCrewMember, setManageCrewMember] = React.useState(defaultValues);
  const [isLoadCrewMemberData, setIsLoadCrewMemberData] =
    React.useState<boolean>(false);
  const [isUploadFile, setIsUploadFile] = React.useState<boolean>(true);
  const [importCrewListErrorMessages, setImportCrewListErrorMessages] =
    React.useState<string>("");
  const [reloadModalPopup, setReloadModalPopup] =
    React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [openCrewMemberModaldialog, setOpenCrewMemberModal] =
    React.useState<boolean>(false);

  useEffect(() => {
    getCrewMembers();
    setManageCrewMember(defaultValues);
    setOpenCrewMemberModal(false);
  }, []);

  const getCrewMembers = async () => {
    setIsLoadCrewMemberData(false);
    CrewListServices.GetCrewMembers().then((res) => {
      if (!res.hasErrors && res.value) {
        res.value.forEach((element) => {
          setManageCrewMember({
            ...manageCrewMember,
            id: element.id,
            employeeName: element.employeeName,
            employeeNumber: element.employeeNumber,
            area: element.area,
            unionCode: element.unionCode,
            title: element.title,
            lastCheckedDate: element.lastCheckedDate,
            terminationDate: element.terminationDate,
            hourlyRate: element.hourlyRate,
            overtime: element.overtime,
            doubleTime: element.doubleTime,
          });
        });
        setRows(res.value);
        setIsLoadCrewMemberData(true);
      }
    });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  //#region Validation for required cell

  //#endregion Validation

  const columns: GridColDef[] = [
    {
      field: "employeeName",
      headerName: "Employee Name",
      width: 300,
      editable: false,
    },
    {
      field: "employeeNumber",
      headerName: "Employee ID",
      width: 140,
      editable: false,
    },
    {
      field: "area",
      headerName: "Area",
      width: 90,
      editable: false,
    },
    {
      field: "unionCode",
      headerName: "Union Code",
      width: 150,
      editable: false,
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      editable: false,
    },
    {
      field: "lastCheckedDate",
      headerName: "Last Checked Date",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        new Date(params?.value).toLocaleDateString("en-US"),
    },
    {
      field: "terminationDate",
      headerName: "Termination Date",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        new Date(params?.value).toLocaleDateString("en-US"),
    },
    {
      field: "hourlyRate",
      headerName: "Hourly Rate",
      width: 150,
      editable: false,
    },
    {
      field: "overtime",
      headerName: "Overtime",
      width: 150,
      editable: false,
    },
    {
      field: "doubleTime",
      headerName: "Double Time",
      width: 150,
      editable: false,
    },
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Actions",
    //   width: 150,
    //   cellClassName: "actions",
    //   getActions: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<EditIcon />}
    //         label="Edit"
    //         className="textPrimary"
    //         onClick={handleEditClick(id)}
    //         color="inherit"
    //       />,
    //     ];
    //   },
    // },
  ];

  const handleEditClick = (id: GridRowId) => () => {
    const rowData = rows.filter((x) => x.id === id)[0];
    setManageCrewMember({
      ...manageCrewMember,
      id: rowData.id,
      employeeName: rowData.employeeName,
      employeeNumber: rowData.employeeNumber,
      area: rowData.area,
      unionCode: rowData.unionCode,
      title: rowData.title,
      lastCheckedDate: rowData.lastCheckedDate,
      terminationDate: rowData.terminationDate,
      hourlyRate: rowData.hourlyRate,
      overtime: rowData.overtime,
      doubleTime: rowData.doubleTime,
    });
    setReloadModalPopup(true);
    setIsEditMode(true);
    setOpenCrewMemberModal(true);
  };

  const openCrewMemberModalEdit = () => {
    setIsLoadCrewMemberData(true);
  };

  const closeCrewMemberModal = () => {
    setOpenCrewMemberModal(false);
  };
  const handlecloseCrewMemberModal = () => {
    setOpenCrewMemberModal(false);
    getCrewMembers();
  };

  const handleClickOpenCrewMemberModal = () => {
    setIsEditMode(false);
    setOpenCrewMemberModal(true);
    setManageCrewMember(defaultValues);
  };

  //#region Upload CSV
  const handleFilesChange = async (e: any) => {
    if (e.target.files.length !== 0) {
      setIsUploadFile(false);
      let isValid = filesValidate(e.target.files[0]);
      if (isValid) {
        // setDisplayFiles(e.target.files[0]);
        let formData = new FormData();
        formData.append("files", e.target.files[0]);

        let res = (await CrewListServices.UploadCrewList(formData)).value;
        if (res && res.isSuccess) {
          setImportCrewListErrorMessages("");
          notify("success", Messages.CrewList.success);
          getCrewMembers();
          e.target.value = null;
          document.getElementById("bulkCrewListCancel")?.click();
        } else {
          if (res.customerrors.length > 0) {
            let errors = "";
            res.customerrors.forEach((element: string) => {
              const allErrors = element.split("~");
              allErrors.forEach((text, index) => {
                if (index === 0) {
                  errors = errors + text + "<br/><ul>";
                } else {
                  errors = errors + "<li>" + text + "</li>";
                }
              });

              if (allErrors.length > 0) {
                errors = errors + "</ul>";
              }
            });
            setImportCrewListErrorMessages(errors);
          } else {
            notify("error", Messages.CrewList.error);
          }
          e.target.value = "";


        }
      }
      setIsUploadFile(true);
    }
  };

  function filesValidate(results: any) {
    const file = results;

    const _validFileExtensions = ["csv"];
    let isValid =
      _validFileExtensions.indexOf(file.name.split(".").pop().toLowerCase()) >
      -1;
    return isValid;
  }

  const closeModal = () => {
    setImportCrewListErrorMessages("");
  };
  //#endregion

  return (
    <>
      {window.RoleId !== UserRoleType.FieldSupervisor.toString() ? (
        <><div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h1 className="page-title">Craft Employees</h1>
            </div>
          </div>
        </div><div className="row">
            <div className="col-12">
              <div className="card user-detail-card pages-detail-card">
                <div className="card-body">

                  <div className="grid_wrapper autoheightcell">
                    <Box
                      sx={{
                        height: 500,
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                      }}
                    >
                      {isLoadCrewMemberData ? (
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          editMode="row"
                          className="user-details-grid custom-details-grid gridActivityMgmtTbl gridCraftEmployeesTbl"
                          rowModesModel={rowModesModel}
                          // apiRef={apiRef}
                          onRowModesModelChange={handleRowModesModelChange}
                          onRowEditStop={handleRowEditStop}
                          processRowUpdate={processRowUpdate}
                          slots={{
                            toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: { setRows, setRowModesModel },
                          }} />
                      ) : (
                        <div className="loader">
                          <Loading />
                        </div>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
              <CrewMemberModalDialog
                Open={openCrewMemberModaldialog}
                DialogTitle={"Edit crew member"}
                DialogContentText={Messages.ManageUser.confirmMessage}
                ButtonYes={"Save"}
                ButtonNo={"Cancel"}
                onCancel={closeCrewMemberModal}
                onClose={handlecloseCrewMemberModal}
                rowData={manageCrewMember}
                OpenCrewMemberModalEdit={openCrewMemberModalEdit}
                setReloadModalPopup={setReloadModalPopup}
                ReloadModalPopup={reloadModalPopup}
              />
            </div>
          </div><div
            className="modal fade"
            id="AddBillingPeriodModal"
            aria-labelledby="AddBillingPeriodModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="AddBillingPeriodModalLabel">
                    Upload a CSV file to add crew list.
                  </h5>
                  <button
                    type="button"
                    id="bulkCrewListCancel"
                    className="btn-close"
                    onClick={closeModal}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="pt-3 px-3">
                    <div className="form-group">
                      <form>
                        <div className="form-group">
                          <p className="text-muted font-14">
                            The following columns are required: Employee Number,
                            Employee Name, Area, Union Code, Title, Hourly Rate, Overtime,
                            Double Time.
                            <br /> <br /> The date format should be MM/DD/YYYY
                          </p>
                          <hr className="my-4" />
                          {/* <p className="text-muted font-14">
      The date format should be MM/DD/YYYY
    </p> */}
                        </div>
                        {!isUploadFile ? (
                          <div className="loader">
                            <Loading />
                          </div>
                        ) : (
                          <div className="form-group attachFile">
                            <div className="file-input">
                              <label
                                className="file-input__label"
                                htmlFor="file-input"
                              >
                                <UploadIcon />
                                &nbsp;&nbsp;<span>Upload File</span>
                              </label>
                              <input
                                type="file"
                                name="file-input"
                                id="file-input"
                                className="file-input__input"
                                multiple
                                onChange={handleFilesChange} />
                            </div>
                            {importCrewListErrorMessages.length > 0 ? (
                              <div className="card mt-4">
                                <div
                                  className="card-body"
                                  style={{ maxHeight: "200px", overflow: "auto" }}
                                >
                                  <h5 className="card-title">Errors</h5>
                                  <p
                                    className="card-text"
                                    dangerouslySetInnerHTML={{
                                      __html: importCrewListErrorMessages,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></>
      ) : (
        <div>
          <UnAuthorized />
        </div>
      )}
    </>
  );
}
