import { Result } from "../models/Result";
import { ServiceBase } from "../services/ServiceBase";
import { ICrewMemberModel, IManageCrewModel } from "../CrewDetail/ICrew";


export default class CrewListServices extends ServiceBase {
    public static async GetCrewDetails(superintendentId: number): Promise<Result<IManageCrewModel[]>> {
        var result = await this.requestJson<IManageCrewModel[]>({
            url: `api/crew/getCrewDetails/${superintendentId}`,
            method: "GET"
        });
        return result;
    }

    public static async GetCrewMembers(): Promise<Result<ICrewMemberModel[]>> {
        var result = await this.requestJson<ICrewMemberModel[]>({
            url: `api/crew/getCrewMembers`,
            method: "GET"
        });
        return result;
    }

    public static async UploadCrewList(form: FormData): Promise<Result<any>> {
        var result = await this.sendFormData<any>({
            url: `api/crew/fileupload`,
            data: form,
            method: "POST"
        });
        return result;
    }

    public static async UpdateCrewMember(manageCrewMember: ICrewMemberModel): Promise<Result<any>>  {
        var result = await this.requestJson<ICrewMemberModel>({
            url: `api/crew/updatecrew`,
            method: "POST",
            data: manageCrewMember
        });

        return result;
    }
}