import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddUser from "@mui/icons-material/PersonAdd";

import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { CrewModalDialog } from "./AddCrewModal";
import { Messages, UserRoleType } from "../Shared/constant";
import { IManageCrewModel } from "./ICrew";
import CrewServices from "./CrewServices";
import { Loading } from "../Shared/Loading";
import UnAuthorized from "../Shared/UnAuthorized";

//const roles = ["Admin", "Supervisor", "Project Manager"];

const defaultValues: IManageCrewModel = {
  id: 0,
  name: "",
  projectId: 0,
  projectName: "",
  projectTitle: "",
  parent:0,
  supervisorName: "",
  supervisor: 0,
  isActive: false,
};

const initialRows: GridRowsProp = [];

export default function CrewDetails() {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [gridRowDataList, setGridRowDataList] = React.useState<IManageCrewModel[]>([]);
  const [manageCrew, setManageCrew] = React.useState(defaultValues);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [openCrewModaldialog, setOpenCrewModal] = React.useState<boolean>(false);
  const [isLoadCrewDetailData, setIsLoadCrewDetailData] = React.useState<boolean>(false);
  const [reloadModalPopup, setReloadModalPopup] = React.useState<boolean>(false);
  const [crewID, setCrewID] = React.useState<number>(0);

  useEffect(() => {
    setManageCrew(defaultValues);
    getCrewDetails();
    // setOpenUserModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCrewDetails = async () => {
    setIsLoadCrewDetailData(false);
    CrewServices.GetCrewDetails(0).then((res) => {
      if (!res.hasErrors && res.value) {
        res.value.forEach((element) => {
          setManageCrew({
            ...manageCrew,
            id: element.id,
            name: element.name,
            projectId: element.projectId,
            projectName: element.projectName,
            supervisorName: element.supervisorName,
            supervisor: element.supervisor,
            isActive: element.isActive,
          });
        });
        setRows(res.value.filter((x) => x.parent === null));
        setGridRowDataList(res.value.filter((x) => x.parent === null));
        setIsLoadCrewDetailData(true);
      }
    });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setCrewID(+id);
    const rowData = rows.filter((x) => x.id === id)[0];
    setManageCrew({
      ...manageCrew,
      id: rowData.id,
      name: rowData.name,
      projectId: rowData.projectId,
      projectName: rowData.projectName,
      supervisorName: rowData.supervisorName,
      supervisor: rowData.supervisor,
      isActive: rowData.isActive,
    });
    setReloadModalPopup(true);
    setIsEditMode(true);
    setOpenCrewModal(true);
  };

  // const handleSaveClick = (id: GridRowId) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };

  // const handleCancelClick = (id: GridRowId) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });

  //   const editedRow = rows.find((row) => row.id === id);
  //   if (editedRow!.isNew) {
  //     setRows(rows.filter((row) => row.id !== id));
  //   }
  // };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const openCrewModalEdit = () => {
    setIsLoadCrewDetailData(true);
    //  setOpenUserModal(true);
  };

  const closeUserModal = () => {
    setOpenCrewModal(false);
    setCrewID(0);
  };
  const handlecloseUserModal = () => {
    setOpenCrewModal(false);
    setCrewID(0);
    getCrewDetails();
  };

  const handleClickOpenCrewModal = () => {
    setIsEditMode(false);
    setOpenCrewModal(true);
    setManageCrew(defaultValues);
    setCrewID(0);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      editable: false,
    },
    {
      field: "supervisorName",
      headerName: "Supervisor",
      width: 220,
      editable: false,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 220,
      editable: false,
    },
    {
      field: "projectName",
      headerName: "Project Number",
      width: 180,
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 140,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ];
      },
    },
  ];

  return (
    <>
      {window.RoleId !== UserRoleType.FieldSupervisor.toString() ? (
        <><div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h1 className="page-title">Crews</h1>
            </div>
          </div>
        </div><div className="row">
            <div className="col-12">
              <div className="card user-detail-card project-detail-card">
                <div className="card-body">

                  <div className="dataTables_wrapper">
                    <div className="row">
                      <div className="col-sm-12 col-md-8"></div>
                      <div className="col-sm-12 col-md-4">
                        <div className="dataTables_filter">
                          {isLoadCrewDetailData ? (
                            <Button
                              color="primary"
                              startIcon={<AddUser />}
                              className="btn-icon-link btn mx-3 add-user-button"
                              onClick={handleClickOpenCrewModal}
                            >
                              Add Crew
                            </Button>
                          ) : null}

                          {/* <label>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={autoCompleteData}
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        onChange={handleInputChange}
        className="autocomplete form-control"
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Search User"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </label> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid_wrapper autoheightcell">
                    <Box
                      sx={{
                        height: 500,
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                      }}
                    >
                      {isLoadCrewDetailData ? (
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          editMode="row"
                          className="user-details-grid custom-details-grid gridActivityMgmtTbl"
                          rowModesModel={rowModesModel}
                          onRowModesModelChange={handleRowModesModelChange}
                          onRowEditStop={handleRowEditStop}
                          processRowUpdate={processRowUpdate}
                          slotProps={{
                            toolbar: { setRows, setRowModesModel },
                          }} />
                      ) : (
                        <div className="loader">
                          <Loading />
                        </div>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div><CrewModalDialog
            Open={openCrewModaldialog}
            DialogTitle={isEditMode ? "Edit Crew" : "Add New Crew"}
            DialogContentText={Messages.ManageUser.confirmMessage}
            ButtonYes={"Yes"}
            ButtonNo={"No"}
            onCancel={closeUserModal}
            onClose={handlecloseUserModal}
            IsEditMode={isEditMode}
            rowData={manageCrew}
            OpenCrewModalEdit={openCrewModalEdit}
            setReloadModalPopup={setReloadModalPopup}
            ReloadModalPopup={reloadModalPopup}
            gridRowData={gridRowDataList} /></>
      ) : (
        <div>
          <UnAuthorized />
        </div>
      )}
    </>
  );
}
