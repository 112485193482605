  import { Result } from "../models/Result";
  import { ServiceBase } from "../services/ServiceBase";
  import { IManageProjectModel, IDeleteProjectModel } from "./IProject";
  
  export default class ProjectServices extends ServiceBase {
    public static async GetProjectDetails(
      projectId: number
    ): Promise<Result<IManageProjectModel[]>> {
      var result = await this.requestJson<IManageProjectModel[]>({
        url: `api/project?id=${projectId}`,
        method: "GET",
      });
      return result;
    }
  
    public static async SaveProject(manageProject: IManageProjectModel): Promise<Result<IManageProjectModel>> {
      var result = await this.requestJson<IManageProjectModel>({
        url: `api/project`,
        method: "POST",
        data: manageProject,
      });
  
      return result;
    }

    public static async DeleteProject(manageProject: IDeleteProjectModel) {
      var result = await this.requestJson<IDeleteProjectModel>({
          url: `api/project/delete`,
          method: "POST",
          data: manageProject
      });

      return result;
    }
  }
  