import React, { useCallback, useEffect, useRef } from "react";
import { FieldChooser, LoadPanel, PivotGrid, Scrolling, Texts, } from "devextreme-react/pivot-grid";
import { IManageTimeEntryModel, ISaveActivityNotesRequestModel, ITimeEntryActivityRequestModel, ITimeEntryActivityResponseModel, ITimeEntryCrewMemberRequestModel, ITimeEntryCrewMemberResponseModel, ITimeEntryRequestModel, ITimeEntrySaveRequestModel, ITimeEntrySubmitRequestModel } from "./ITimeEntry";
import TimeEntryServices from "./TimeEntryService";
import { Box, Button } from "@mui/material";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { dxPivotGridPivotGridCell } from "devextreme/ui/pivot_grid";
import { Loading } from "../Shared/Loading";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import SaveIcon from "@mui/icons-material/Save";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import { AddTimeEntryCrewMemberModalDialog } from "./AddTimeEntryCrewMemberModal";
import { AddTimeEntryActivityModalDialog } from "./AddTimeEntryActivityModal";
import SnapshotServices, { ISnapshotSaveRequestModel, ISnapshotSaveResponseModel } from "../services/SnapshotServices";
import { WeekPicker } from "../Shared/WeekPicker";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { AlertDialog } from "../Shared/ConfirmationAlertDialog";
import { AlertDialogBox } from "../Shared/AlertDialogBox";
import ReactDomServer from 'react-dom/server';
import { AddActivityNotesModalDialog } from "./AddActivityNotesModal";

export type IProps = { crewId?: number; isFromSelection?: boolean; };

export default function TimeEntryDetails(props: IProps) {
  const [dataSource, setDataSource] = React.useState<PivotGridDataSource>();
  const [isLoadData, setIsLoadData] = React.useState<boolean>(false);
  const [timeEntryData, setTimeEntryData] = React.useState<IManageTimeEntryModel[]>([]);
  const [timeEntryModifiedData, setTimeEntryModifiedData] = React.useState<IManageTimeEntryModel[]>([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = React.useState<string>("");
  const [openCrewMemberModaldialog, setOpenCrewMemberModal] = React.useState<boolean>(false);
  const [openActivityModaldialog, setOpenActivityModal] = React.useState<boolean>(false);
  const [isDisabledControl, setIsDisabledControl] = React.useState<boolean>(false);
  const [timeEntryWeek, setTimeEntryWeek] = React.useState({ firstDay: new Date(), lastDay: new Date() });
  /* Start -- Add crew memebrs states */
  const [crewMemberRows, setCrewMemberRows] = React.useState<ITimeEntryCrewMemberResponseModel[]>([]);
  const [crewMemberList, setCrewMemberList] = React.useState<ITimeEntryCrewMemberResponseModel[]>([]);
  const [selectedCrewMember, setSelectedCrewMember] = React.useState<number[]>([]);

  /* End -- Add crew memebrs states */
  /* Start -- Add activities states */
  const [activityRows, setActivityRows] = React.useState<ITimeEntryActivityResponseModel[]>([]);
  const [activityList, setActivityList] = React.useState<ITimeEntryActivityResponseModel[]>([]);
  const [selectedActivity, setSelectedActivity] = React.useState<number[]>([]);
  const [searchFilter, setSearchFilter] = React.useState({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
  const [groupList, setGroupList] = React.useState<string[]>([]);
  const [groupScopeList, setGroupScopeList] = React.useState<string[]>([]);
  const [groupActivityList, setGroupActivityList] = React.useState<string[]>([]);
  const [groupAreaList, setGroupAreaList] = React.useState<string[]>([]);
  const [groupCostCodeList, setCostCodeList] = React.useState<string[]>([]);
  /* End -- Add activities states */
  /* Start -- Add activity notes */
  const [activityName, setActivityName] = React.useState<string>("");
  const [activityNotes, setActivityNotes] = React.useState<string>("");
  const [openActivityNotesModal, setOpenActivityNotesModal] = React.useState<boolean>(false);
  /* End -- Add activity notes */
  const [cookies, setCookie] = useCookies(["projectSelection"]);
  const [projectId, setProjectId] = React.useState<number>(0);
  const [crewId, setCrewId] = React.useState<number>(0);

  const isNullUndefined = (value: any) => value === null || value === undefined || value === "";
  const isRowCell = (area: string) => area === "row";
  const isColumnCell = (area: string) => area === "column";
  const isDataCell = (area: string | undefined, cell: dxPivotGridPivotGridCell | undefined) => area === "data" && cell?.rowType === "D" && cell?.columnType === "D";
  const isTotalCell = (area: string, cell: { type: string; rowType: string; columnType: string }) => cell.type === "T" || cell.type === "GT" || cell.rowType === "T" || cell.rowType === "GT" || cell.columnType === "T" || cell.columnType === "GT";
  const pivotGridInstance = useRef<PivotGrid>(null);
  const navigate = useNavigate();
  const validationMessages: string[] = ["Please select at least one Crew member.", "Please select at least one Activity.",
    "Seems there are no Activities selected for the Timeentry. Please select at least one Activity.", "Seems there are no Crew members selected for the Timeentry. Please select at least one Crew member."];

  useEffect(() => {
    getCookies();
  }, [])

  useEffect(() => {
    if (crewId > 0) {
      setIsLoadData(true);
      getTimeEntry();
      getTimeEntryCrewMembers();
      getTimeEntryActivities();
    }
  }, [timeEntryWeek, crewId]);

  useEffect(() => {
    setTimeout(() => {
      if (timeEntryData.length === 0) {
        if (crewMemberList.length > 0 && selectedCrewMember.length === 0) {
          setOpenActivityModal(false);
          handleClickOpenCrewMemberModal();
        }

        if (selectedCrewMember.length > 0 && activityList.length > 0 && selectedActivity.length === 0) {
          setOpenCrewMemberModal(false);
          handleClickOpenActivityModal();
        }
      }
    }, 500);
  }, [selectedCrewMember, selectedActivity]);

  useEffect(() => {
    bindDropdowns(searchFilter);
  }, [activityList, searchFilter]);

  useEffect(() => {
    const isTimeEntrySubmit = timeEntryData.filter(t => t.isSubmitted).length > 0;
    if (isTimeEntrySubmit) {
      setIsDisabledControl(true);
    } else {
      setIsDisabledControl(false);
    }
  }, [timeEntryData]);

  const getCookies = () => {
    if (cookies.projectSelection !== undefined) {
      const arrOfObj = cookies.projectSelection.split("&");
      arrOfObj.map((element: any) => {
        const keyValue = element.split(":");
        if (keyValue[0] === 'projectId') {
          setProjectId(+keyValue[1]);
        } else if (keyValue[0] === 'crewId') {
          setCrewId(+keyValue[1]);
        } else {
          // set userId when needed
        }
      });
    } else {
      navigate("/projectSelection");
    }
  }

  /* Start - Pivot grid methods */
  // function to load time entries data for selected week & crew
  const getTimeEntry = async () => {
    setTimeEntryModifiedData([]);
    let timeEntryRequstModel: ITimeEntryRequestModel = {
      crewId: crewId,
      currentDate: weekFirstDate(timeEntryWeek.firstDay),
      isIncludeWeekEnd: true,
    };

    await TimeEntryServices.GetTimeEntry(timeEntryRequstModel).then((res) => {
      if (!res.hasErrors && res.value) {
        let data: IManageTimeEntryModel[] = [];
        res.value.forEach((element) => {
          data.push({
            id: element.id,
            day: element.day,
            date: element.date,
            dt: element.dt,
            ot: element.ot,
            st: element.st,
            employeeName: element.employeeName,
            employeeNumber: element.employeeNumber,
            crewMemberId: element.crewMemberId,
            crewId: element.crewId,
            activity: element.activity,
            activityId: element.activityId,
            weekDay: element.weekDay,
            weeklySnapshotId: element.weeklySnapshotId,
            isSubmitted: element.isSubmitted,
            activityNote: element.activityNote,
            crewMemberSequence: element.crewMemberSequence,
            activitySequence: element.activitySequence
          });
        });

        const pivotDS = new PivotGridDataSource({
          fields: [{
            caption: 'EmployeeName',
            dataField: 'employeeName',
            area: 'row',
            expanded: true,
            sortingMethod: (e1, e2) => {
              const e1Sequence = data.filter(d => d.employeeName.toLowerCase() === e1.value.toString().toLowerCase()).map(c => c.crewMemberSequence)[0];
              const e2Sequence = data.filter(d => d.employeeName.toLowerCase() === e2.value.toString().toLowerCase()).map(c => c.crewMemberSequence)[0];
              if (e1Sequence < e2Sequence) {
                return -1;
              }
            }
          },
          {
            caption: 'Day',
            dataField: 'day',
            area: 'row',
            expanded: true,
            sortingMethod: (a, b) => {
              // auto sorting by ascending
              return 1;
            },
            sortOrder: 'asc'
          },
          {
            caption: 'Date',
            dataField: 'date',
            area: 'row',
            visible: false
          },
          {
            caption: 'Activity',
            dataField: 'activityId',
            area: 'column',
            visible: false
          },
          {
            caption: 'Activity',
            dataField: 'activity',
            area: 'column',
            sortingMethod: (a1, a2) => {
              const a1Sequence = data.filter(d => d.activity.toLowerCase() === a1.value.toString().toLowerCase()).map(c => c.activitySequence)[0];
              const a2Sequence = data.filter(d => d.activity.toLowerCase() === a2.value.toString().toLowerCase()).map(c => c.activitySequence)[0];
              if (a1Sequence < a2Sequence) {
                return -1;
              }
            }
          },
          {
            caption: 'DT',
            dataField: 'dt',
            dataType: 'number',
            summaryType: 'sum',
            area: 'data',
            width: 40,
            customizeText: function (e: any) {
              if (e.value === 0) {
                return "-";
              } else {
                return e.valueText;
              }
            }
          },
          {
            caption: 'OT',
            dataField: 'ot',
            dataType: 'number',
            summaryType: 'sum',
            area: 'data',
            width: 40,
            customizeText: function (e: any) {
              if (e.value === 0) {
                return "-";
              } else {
                return e.valueText;
              }
            }
          },
          {
            caption: 'ST',
            dataField: 'st',
            dataType: 'number',
            summaryType: 'sum',
            area: 'data',
            width: 40,
            customizeText: function (e: any) {
              if (e.value === 0) {
                return "-";
              } else {
                return e.valueText;
              }
            }
          }],
          store: data
        });

        setTimeEntryData(data);
        setDataSource(pivotDS);
        setTimeout(() => {
          setIsLoadData(false);
        }, 500);
      }
    });
  };

  // cell prepared event for the pivot grid
  const onCellPrepared = ({ cell, area, cellElement }: any) => {
    // added visual seperation for header cell 
    if (isColumnCell(area)) {
      if (cell.type === 'D' && cell.dataIndex === 2) {
        cellElement.style.setProperty('border-right', '1px solid #48628d', 'important');
      }

      if (cell.type === 'D' && cell.colspan === 3) {
        cellElement.style.setProperty('position', 'relative');
        cellElement.style.setProperty('padding-right', '35px');

        const notesList = timeEntryData.filter(t => t.activity.toLocaleLowerCase() === cell.text.toLocaleLowerCase()).map(t => t.activityNote);
        if (!isNullUndefined(notesList[0])) {
          const notes = notesList[0].length <= 50 ? notesList[0] : notesList[0].substring(0, 50) + '...';
          if (!isNullUndefined(notes[0])) {
            // adding style to display span in new line when note will be there
            const spanCollection = cellElement.getElementsByTagName("span") as HTMLCollectionOf<HTMLSpanElement>;
            spanCollection[0].style.setProperty('display', 'block');

            // adding notes span and style to the activity column
            let spanNotes = document.createElement("span");
            spanNotes.setAttribute("title", notesList[0]);
            spanNotes.style.setProperty('display', 'block');
            spanNotes.innerText = " [" + notes + "]";
            cellElement.append(spanNotes);
          }
        }

        let addNotesButton = document.createElement("button");
        addNotesButton.className = "button-add-activity-notes";
        addNotesButton.setAttribute('title', 'Add Notes');
        addNotesButton.innerHTML = ReactDomServer.renderToString(<AddIcon />);

        if (!isDisabledControl) {
          addNotesButton.addEventListener('click', function () {
            handleClickOpenActivityNotesModal(cell.text);
          });
        }

        cellElement.append(addNotesButton);
      }

      if (cell.type === 'D' && cell.colspan === 3) {
        cellElement.style.setProperty('border-right', '1px solid #48628d', 'important');
      }

      const gridHead = cellElement.parentElement.parentElement as HTMLHeadElement;
      if (gridHead.classList.contains('dx-pivotgrid-horizontal-headers')) {
        gridHead.style.setProperty('border-right', '8px solid #48628d', 'important');
      }
    }

    if (isTotalCell(area, cell)) {
      // format hours upto 2 decimal points in total cells
      if (cell.rowType === 'GT' || cell.rowType === 'T') {
        const num = +(cell.text);
        if (num > 0) {
          const total = !Number.isInteger(num) ? (Math.round(num * 100) / 100).toFixed(2).replace(/\.?0+$/, "") : num;
          const spanCollection = cellElement?.getElementsByTagName("span") as HTMLCollectionOf<HTMLSpanElement>;
          for (let i = 0; i < spanCollection.length; i++) {
            spanCollection[i].innerText = total.toString();
          }
        }
      }

      // remove total text after employee name
      if (cell.type === 'T') {
        const spanCollection = cellElement?.getElementsByTagName("span") as HTMLCollectionOf<HTMLSpanElement>;
        for (let i = 0; i < spanCollection.length; i++) {
          if (spanCollection[i].textContent?.includes('Total')) {
            const txtContent = spanCollection[i].textContent;
            spanCollection[i].innerText = !isNullUndefined(txtContent) ? txtContent?.replace(' Total', '') as string : '';
          }
        }
      }

      // added background & visual seperation for sub total row first 3 columns
      if (cell.rowType === 'D') {
        if (cell.rowPath.length === 1) {
          cellElement.style.setProperty('background', '#d9d9d9', 'important');
          cellElement.children[0].style.setProperty('background', '#d9d9d9', 'important');
        }
      }

      // added visual seperation for first 3 totals columns from data columns
      if (cell.dataIndex === 2 || cell.colspan === 3) {
        cellElement.style.setProperty('border-right', '1px solid #48628d', 'important');
      }
    }

    if (isRowCell(area)) {
      // tr visual seperation for left side tree view
      if (!isNullUndefined(cellElement.parentElement.parentElement)) {
        const gridBody = cellElement.parentElement.parentElement as HTMLBodyElement;
        if (gridBody.classList.contains('dx-pivotgrid-vertical-headers')) {
          gridBody.style.setProperty('border-bottom', '8px solid #48628d', 'important');
        }
      }
    }

    if (isDataCell(area, cell)) {
      // add class on data tbody which help while saving data using enter key
      if (!isNullUndefined(cellElement.parentElement.parentElement)) {
        const gridBody = cellElement.parentElement.parentElement as HTMLBodyElement;
        if (!gridBody.classList.contains('dx-pivot-grid-original-tbody')) {
          gridBody.className = 'dx-pivot-grid-original-tbody';
          gridBody.style.setProperty('border-right', '8px solid #48628d', 'important');
          gridBody.style.setProperty('border-bottom', '8px solid #48628d', 'important');
        }

        const gridTable = gridBody.parentElement as HTMLTableElement;
        if (!gridTable.classList.contains('dx-pivot-grid-original-table')) {
          gridTable.className = 'dx-pivot-grid-original-table';
        }

        // tr visual seperation
        //const gridTR = cellElement.parentElement as HTMLTableRowElement;
        //gridTR.style.setProperty('height','28px', 'important');
      }

      // added background & visual seperation for cell when row collapse
      if (cell.rowPath.length === 1) {
        cellElement.style.setProperty('background', '#d9d9d9', 'important');
        // td visual seperation
        if (cell.dataIndex === 2) {
          cellElement.style.setProperty('border-right', '1px solid #48628d', 'important');
        }
      }

      if (cell.rowPath.length > 1) {
        const columnValues = cell?.columnPath, rowValues = cell?.rowPath;
        var dataObj = timeEntryData.filter((m) => m.employeeName.toLowerCase() === rowValues![0].toString().toLowerCase() && m.day.toLowerCase() === rowValues![1].toString().toLowerCase() && m.activity.toLowerCase() === columnValues![0].toString().toLowerCase());
        // add custom class on cell to identify specific cell of hours
        if (dataObj[0].isSubmitted) {
          cellElement.className = 'disabled-cell';
        }
        else {
          if (cell.dataIndex === 0) {
            cellElement.className = 'class-data dt-data';
          } else if (cell.dataIndex === 1) {
            cellElement.className = 'class-data ot-data';
          } else if (cell.dataIndex === 2) {
            cellElement.className = 'class-data st-data';
          }
        }

        if (cell.dataIndex === 2) {
          // td visual seperation
          cellElement.style.setProperty('border-right', '1px solid #48628d', 'important');
        }
      }
    }
  };

  // cell click event for the pivot grid
  const onCellClick = useCallback((e: any) => {
    // stop clicking on cell when row in collapse mode
    const rowPathLength = e.cell?.rowPath?.length as number;
    if (isDataCell(e.area, e.cell) && rowPathLength > 1) {
      // get cell rows/columns field value
      const columnValues = e.cell?.columnPath, rowValues = e.cell?.rowPath;
      var dataObj = timeEntryData.filter((m) => m.employeeName.toLowerCase() === rowValues![0].toString().toLowerCase() && m.day.toLowerCase() === rowValues![1].toString().toLowerCase() && m.activity.toLowerCase() === columnValues![0].toString().toLowerCase());

      if (!dataObj[0].isSubmitted) {
        let cellSelected = false;
        let isTabEnterPressed = false;

        // looping each rows/columns to remove existing input element & add span again
        const gridBody = e.cellElement?.parentElement?.parentElement;
        const gridTR = gridBody?.childNodes as NodeListOf<ChildNode>;

        for (let r = 0; r < gridTR?.length; r++) {
          const tr = gridTR[r] as HTMLTableRowElement;
          const gridTD = tr.getElementsByClassName("class-data");
          for (let d = 0; d < gridTD.length; d++) {
            const td = gridTD[d] as HTMLTableColElement;
            for (let c = 0; c < td.children.length; c++) {
              const inputElement = td.getElementsByClassName("inputhours") as HTMLCollectionOf<HTMLInputElement>;
              if (inputElement.length === 1) {
                const span = document.createElement("span");
                span.innerText = inputElement[0].value === null || inputElement[0].value === "0" ? "-" : inputElement[0].value;
                td.removeChild(inputElement[0]);
                td.append(span);
              }
            }
          }
        }

        // remove span which contains the hours
        const spanCollection = e.cellElement?.getElementsByTagName("span") as HTMLCollectionOf<HTMLSpanElement>;
        for (let i = 0; i < spanCollection.length; i++) {
          e.cellElement?.removeChild(spanCollection[i]);
        }

        // creating dynamic input element to modify the hoours
        const input = document.createElement("input");
        const inputId = dataObj[0].activityId + "_" + dataObj[0].crewMemberId + "_" + dataObj[0].employeeNumber + "_" + dataObj[0].id + "_" + dataObj[0].weekDay;
        input.setAttribute("type", "text");
        input.setAttribute("class", "inputhours");
        input.setAttribute("id", inputId);
        input.setAttribute("tabindex", "-1");
        input.value = e.cell?.value;

        // restrict to paste alpha numeric values
        input.addEventListener("paste", function (event) {
          const copiedText = event.clipboardData?.getData("text/plain") as string;
          if (!isNullUndefined(copiedText) && copiedText?.indexOf(".") !== -1) {
            const allowedValue = [25, 50, 75];
            const value = +copiedText?.split(".")[1];
            if (!allowedValue.includes(value)) {
              event.preventDefault();
            }
          }

          const len = copiedText ? copiedText?.length : 0;
          for (var i = 0; i < len; i++) {
            var code = copiedText ? copiedText?.charCodeAt(i) : 0;
            if ((code > 64 && code < 91) || // upper alpha (A-Z)
              (code > 96 && code < 123)
            ) {
              // lower alpha (a-z)
              event.preventDefault();
            }
          }
        });

        // handle delete button 
        input.addEventListener('blur', function(event) {
          if (!isTabEnterPressed) {
            const targetElement = e.cellElement?.children[0] as HTMLInputElement;
            if (+targetElement.value === 0) {
              const parentTargetElement = targetElement.parentElement as HTMLTableCellElement;
              updateTimeEntry(targetElement.id, parentTargetElement.classList[1], +targetElement.value);
            }
          }
        });

        input.addEventListener('keydown', function (event) {
          if (event.which === 13 || event.which === 9) {
            isTabEnterPressed = true;
            const targetElement = e.cellElement?.children[0] as HTMLInputElement;
            const parentTargetElement = targetElement.parentElement as HTMLTableCellElement;
            const parentTRTargetElement = parentTargetElement.parentElement as HTMLTableRowElement;
            let tdIndex = parentTargetElement.cellIndex, trIndex = parentTRTargetElement.rowIndex;
            updateTimeEntry(targetElement.id, parentTargetElement.classList[1], +targetElement.value);

            if (event.which === 13) {
              setTimeout(function () {
                enterKeyHandler(trIndex, tdIndex);
              }, 100);
            } else {
              event.preventDefault();
              setTimeout(function () {
                tabKeyHandler(trIndex, tdIndex);
              }, 100);
            }
          } 
        });

        // restric to write alpha numeric & multiple dots (.) values
        input.addEventListener('keypress', function (event) {
          if (!isTabEnterPressed && !cellSelected) { //13 - Enter & 9 - Tab
            // restrict hours upto .25, .50 & .75
            // if (this.value.indexOf('.') !== -1) {
            //   if (this.value.split('.')[1].length === 0) { // check first place after decimal
            //     if (event.which !== 50 && event.which !== 53 && event.which !== 55) {
            //       event.preventDefault();
            //     }
            //   } else if (this.value.split('.')[1].length == 1) { // check second place after decimal
            //     if (+this.value.split('.')[1] === 2 || +this.value.split('.')[1] === 7) {
            //       if (event.which !== 53) {
            //         event.preventDefault();
            //       }  
            //     }

            //     if (+this.value.split('.')[1] === 5) {
            //       if (event.which !== 48) {
            //         event.preventDefault();
            //       }  
            //     }
            //   }
            // }
            if ((event.which !== 46 || this.value.indexOf('.') !== -1) && (event.which < 48 || event.which > 57)) {
              if ((event.which !== 46 || this.value.indexOf('.') !== -1)) {
                event.preventDefault();
              }
              event.preventDefault();
            }
            if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 1)) {
              event.preventDefault();
            }
          }
          cellSelected = false;
        });

        // update the hours in object when blur the input element
        input.addEventListener('blur', function (e) {
          if (!isTabEnterPressed && !cellSelected) {
            const targetElement = e.currentTarget as HTMLInputElement;
            const parentTargetElement = targetElement.parentElement as HTMLTableCellElement;
            updateTimeEntry(targetElement.id, parentTargetElement.classList[1], +targetElement.value);
            cellSelected = false;
          }
        });

        e.cellElement?.append(input);

        // set focus & select whole text
        setTimeout(function () {
          const element = document.getElementById(inputId) as HTMLInputElement;
          if (!isNullUndefined(element)) {
            cellSelected = true;
            element.select();
            element.focus();
          }
        }, 200);
      }
    }
  }, [timeEntryData]);

  // handle entry key
  const enterKeyHandler = (rowIndex: number, colIndex: number) => {
    let tBodyElement = new DOMParser().parseFromString("<html>...</html>", "text/html").body;
    let tdIndex = colIndex,
      trIndex = rowIndex + 1;

    const querySelector = document.querySelectorAll(".dx-pivot-grid-original-tbody");
    const querySelectorParent = querySelector[querySelector.length - 1]?.parentElement as HTMLTableElement;
    if (querySelectorParent.classList.contains("dx-pivot-grid-original-table")) {
      tBodyElement = querySelector[querySelector.length - 1] as HTMLBodyElement;
    }

    if (!isNullUndefined(tBodyElement)) {
      let nextRowElement = tBodyElement.children[trIndex] as HTMLTableRowElement;
      if (!isNullUndefined(nextRowElement)) {
        for (let nr = trIndex; nr < tBodyElement.children.length; nr++) {
          const nextCellElements = tBodyElement.children[nr].children as HTMLCollectionOf<HTMLTableCellElement>;
          if (nextCellElements[0].classList.contains("dx-row-total")) {
            trIndex++;
          } else {
            break;
          }
        }
        nextRowElement = tBodyElement.children[trIndex] as HTMLTableRowElement;
      } else {
        trIndex = 0;
        tdIndex++;
        for (let nr = trIndex; nr < tBodyElement.children.length; nr++) {
          const nextCellElements = tBodyElement.children[nr].children as HTMLCollectionOf<HTMLTableCellElement>;
          if (nextCellElements[0].classList.contains("dx-row-total")) {
            trIndex++;
          } else {
            break;
          }
        }
        nextRowElement = tBodyElement.children[trIndex] as HTMLTableRowElement;
      }

      let nextCellElement = nextRowElement.children[tdIndex] as HTMLTableCellElement;
      if (isNullUndefined(nextCellElement)) {
        tdIndex = 0;
        for (let nd = tdIndex; nd < nextRowElement.children.length; nd++) {
          if (nextRowElement.children[nd].classList.contains("dx-grandtotal")) {
            tdIndex++;
          } else {
            break;
          }
        }
        nextCellElement = nextRowElement.children[tdIndex] as HTMLTableCellElement;
      }
      nextCellElement.click();
    }
  };

  // handle tab key
  const tabKeyHandler = (rowIndex: number, colIndex: number) => {
    let tBodyElement = new DOMParser().parseFromString("<html>...</html>", "text/html").body;
    let tdIndex = colIndex + 1,
      trIndex = rowIndex;

    const querySelector = document.querySelectorAll(".dx-pivot-grid-original-tbody");
    const querySelectorParent = querySelector[querySelector.length - 1]?.parentElement as HTMLTableElement;
    if (querySelectorParent.classList.contains("dx-pivot-grid-original-table")) {
      tBodyElement = querySelector[querySelector.length - 1] as HTMLBodyElement;
    }

    if (!isNullUndefined(tBodyElement)) {
      let nextRowElement = tBodyElement.children[trIndex] as HTMLTableRowElement;
      let nextCellElement = nextRowElement.children[tdIndex] as HTMLTableCellElement;

      if (!isNullUndefined(nextCellElement)) {
        for (let nd = tdIndex; nd < nextRowElement.children.length; nd++) {
          const nextCellElements = nextRowElement.children[nd].children as HTMLCollectionOf<HTMLTableCellElement>;
          if (nextCellElements[0].classList.contains("dx-grandtotal")) {
            tdIndex++;
          } else {
            break;
          }
        }
        nextCellElement = nextRowElement.children[tdIndex] as HTMLTableCellElement;
      } else {
        tdIndex = 0;
        trIndex++;
        nextRowElement = tBodyElement.children[trIndex] as HTMLTableRowElement;
        if (isNullUndefined(nextRowElement)) {
          trIndex = 0;
          tdIndex = 0;
        }

        for (let nr = trIndex; nr < tBodyElement.children.length; nr++) {
          if (tBodyElement.children[nr].children[0].classList.contains("dx-row-total")) {
            trIndex++;
          } else {
            break;
          }
        }

        nextRowElement = tBodyElement.children[trIndex] as HTMLTableRowElement;
        let nextCellElements = nextRowElement.children as HTMLCollectionOf<HTMLTableCellElement>;
        for (let nd = tdIndex; nd < nextCellElements.length; nd++) {
          if (nextCellElements[nd].classList.contains("dx-grandtotal")) {
            tdIndex++;
          } else {
            break;
          }
        }

        nextCellElement = nextRowElement.children[tdIndex] as HTMLTableCellElement;
      }
      nextCellElement.click();
    }
  };

  // update state for modified time entries data
  const updateTimeEntry = (targetInputId: string, parentClassName: string, hours: number) => {
    const objId = targetInputId.split("_");
    let isUpdate = false;

    if (objId.length > 0) {
      var index = timeEntryData.findIndex((t) => t.id === +objId[3] && t.activityId === +objId[0] &&
        t.crewMemberId === +objId[1] && t.employeeNumber === objId[2] && t.weekDay === +objId[4]);

      const modulo10 = (hours * 10) % 10;
      const module25 = +((hours * 100) % 25).toFixed(2);
      if (!Number.isInteger(hours) && (module25 !== 0) && (module25 !== 20.00 || (modulo10 !== 2 && modulo10 !== 7))) {
        pivotGridInstance.current?.instance.getDataSource().reload();
        setTimeout(() => {
          setDialogMessage("Hours must be numeric or in #.25, #.50 or #.75 format.");
          setDialogOpen(true);
        }, 100);
      } else {
        let tempTimeEntry = timeEntryData.slice();
        if (parentClassName === 'dt-data' && hours !== (tempTimeEntry[index]['dt'] || 0)) {
          tempTimeEntry[index]['dt'] = (modulo10 === 2 || modulo10 === 7) ? (hours + 0.05) : hours;
          isUpdate = true;
        } else if (parentClassName === 'ot-data' && hours !== (tempTimeEntry[index]['ot'] || 0)) {
          tempTimeEntry[index]['ot'] = (modulo10 === 2 || modulo10 === 7) ? (hours + 0.05) : hours;
          isUpdate = true;
        } else if (parentClassName === 'st-data' && hours !== (tempTimeEntry[index]['st'] || 0)) {
          tempTimeEntry[index]['st'] = (modulo10 === 2 || modulo10 === 7) ? (hours + 0.05) : hours;
          isUpdate = true;
        }

        if (isUpdate) {
          setTimeEntryData(tempTimeEntry);
          pivotGridInstance.current?.instance.getDataSource().reload();

          // set time entries objects for saving
          const timeEntryIndex = timeEntryModifiedData.findIndex((t) => t.id === +objId[3] && t.activityId === +objId[0] && t.crewMemberId === +objId[1] &&
            t.employeeNumber === objId[2] && t.weekDay === +objId[4]);
          if (timeEntryIndex === -1) {
            timeEntryModifiedData.push(tempTimeEntry[index]);
          } else {
            timeEntryModifiedData.splice(timeEntryIndex, 1);
            timeEntryModifiedData.push(tempTimeEntry[index]);
          }

          setTimeEntryModifiedData(timeEntryModifiedData);
        }
      }
    }
  };

  // save time entries data
  const saveTimeEntry = async (isFromSubmit: boolean) => {
    const isSaveWeeklySnapshot = timeEntryData.filter(t => t.weeklySnapshotId === 0).length > 0;
    if (isSaveWeeklySnapshot) {
      // save weekly snapshot data
      const snapShots = await saveWeeklySnapShot(true);

      // save activity notes
      selectedActivity.forEach(item => {
        const activityData = timeEntryData.filter(t => t.activityId === item)[0];
        const snapShotIds = snapShots.filter(a => a.activityId === item).map(item => item.weeklySnapshotId).filter((value, index, current_value) => current_value.indexOf(value) === index)
        if (!isNullUndefined(activityData) && !isNullUndefined(activityData.activityNote)) {
          saveActivityNotes(activityData.activityNote, activityData.activity, true, snapShotIds);
        }
      });
    }

    if (timeEntryModifiedData.length > 0) {
      if (!isFromSubmit) {
        setIsLoadData(true);
      }
      
      let timeEntrySaveRequestModel: ITimeEntrySaveRequestModel = {
        timeEntries: timeEntryModifiedData,
        userId: +window.UserId,
      };

      await TimeEntryServices.SaveTimeEntry(timeEntrySaveRequestModel).then(
        (res) => {
          if (!res.hasErrors && res.value) {

            if (!isFromSubmit) {
              getTimeEntry();
            }

            // update the time entry crew members state
            const updatedCrewMember = [];
            for (let cm = 0; cm < selectedCrewMember.length; cm++) {
              let sum = 0;
              timeEntryData.filter(f => f.crewMemberId === selectedCrewMember[cm]).forEach(a => {
                sum += (a.dt + a.ot + a.st);
              });
              updatedCrewMember.push({ CrewMemberId: selectedCrewMember[cm], Sum: sum });
            }

            let tempCrewMemberId = crewMemberList.slice();
            updatedCrewMember.forEach(item => {
              const index = crewMemberList.findIndex((m) => m.id === item.CrewMemberId);
              tempCrewMemberId[index]['hours'] = item.Sum;
            });
            setCrewMemberRows(tempCrewMemberId);

            // update the time entry activities state
            const updatedActivity = [];
            for (let a = 0; a < selectedActivity.length; a++) {
              let sum = 0;
              timeEntryData.filter(f => f.activityId === selectedActivity[a]).forEach(a => {
                sum += (a.dt + a.ot + a.st);
              });
              updatedActivity.push({ ActivityId: selectedActivity[a], Sum: sum });
            }

            let tempActivityId = activityList.slice();
            updatedActivity.forEach(item => {
              const index = activityList.findIndex((m) => m.id === item.ActivityId);
              tempActivityId[index]['hours'] = item.Sum;
            });
            setActivityRows(tempActivityId);
          }
        }
      ).then(() => { setTimeEntryModifiedData([]); }
      );
    }
    else {
      setIsLoadData(false);
    }
  };

  // submit warning for time entry
  const submitWarning = () => {
    setAlertOpen(true);
  };

  const closeAlertDialogBox = () => {
    setAlertOpen(false);
  };

  // submit time entries data
  const submitTimeEntry = async () => {
    setAlertOpen(false);
    setIsLoadData(true);
    await saveTimeEntry(true);

    let timeEntrySubmitRequestModel: ITimeEntrySubmitRequestModel = {
      activityIds: selectedActivity,
      crewMemberIds: selectedCrewMember,
      crewId: crewId,
      date: weekFirstDate(timeEntryWeek.firstDay),
      currentDate: new Date(),
      submittedBy: +window.UserId
    };

    await TimeEntryServices.SubmitTimeEntry(timeEntrySubmitRequestModel).then(
      (res) => {
        if (!res.hasErrors && res.value) {
          getTimeEntry();
        }
      });
  }

  // unlock time entries data
  const unLockTimeEntry = async () => {
    setIsLoadData(true);
    let timeEntrySubmitRequestModel: ITimeEntrySubmitRequestModel = {
      activityIds: selectedActivity,
      crewMemberIds: selectedCrewMember,
      crewId: crewId,
      date: weekFirstDate(timeEntryWeek.firstDay),
      currentDate: new Date(),
      submittedBy: +window.UserId
    };

    await TimeEntryServices.UnLockTimeEntry(timeEntrySubmitRequestModel).then(
      (res) => {
        if (!res.hasErrors && res.value) {
          getTimeEntry();
        }
      });
  }
  /* End - Pivot grid methods */

  /* Start - Crew member functionality */
  // handle crew member popup event
  const handleClickOpenCrewMemberModal = () => {
    setSearchFilter({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
    setCrewMemberRows(crewMemberList);

    // save any updated hours before opening crew member pop-up
    if (timeEntryModifiedData.length > 0) {
      saveTimeEntry(false);
    }
    setOpenCrewMemberModal(true);
  }

  // close event for crew member popup
  const closeCrewMemberModaldialog = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpenCrewMemberModal(false);
    }
  };

  // function to load time entries crew members
  const getTimeEntryCrewMembers = async () => {
    let timeEntryCrewMemberRequestModel: ITimeEntryCrewMemberRequestModel = {
      crewId: crewId,
      currentDate: weekFirstDate(timeEntryWeek.firstDay)
    };
    await TimeEntryServices.GetCrewMembers(timeEntryCrewMemberRequestModel).then((res) => {
      if (!res.hasErrors && res.value) {
        const crewMembers = res.value;
        setSelectedCrewMember(crewMembers.filter(m => m.isSnapshot).map(s => s.id));
        setCrewMemberList(crewMembers);
        setCrewMemberRows(crewMembers);
      }
      else {
        setSelectedCrewMember([]);
        setCrewMemberList([]);
        setCrewMemberRows([]);
      }
    });
  }

  // handle crew member on change event
  const handleCrewMemberOnChange = (event: any, id: number) => {
    const index = crewMemberRows.findIndex((m) => m.id === id);
    let tempCrewMemberId = crewMemberRows.slice();
    const tempIndex = selectedCrewMember.findIndex(f => f === tempCrewMemberId[index]['id']);
    tempCrewMemberId[index]['isSnapshot'] = event.target.checked;
    if (!tempCrewMemberId[index]['isSnapshot']) {
      tempCrewMemberId[index]['crewMemberSequence'] = index;
    }
    (event.target.checked) ? selectedCrewMember.push(id) : selectedCrewMember.splice(tempIndex, 1);

    setSelectedCrewMember(selectedCrewMember);
    setCrewMemberRows(tempCrewMemberId);
  }

  // function to search crew member
  const searchCrewMember = (value: string) => {
    if (value) {
      const filterData = crewMemberList.filter(c => c.employeeName.toLowerCase().includes(value.toLocaleLowerCase()) ||
        c.employeeNumber.includes(value.toLocaleLowerCase()));
      setCrewMemberRows(filterData);
    } else {
      setCrewMemberRows(crewMemberList);
    }
  }

  // save crew member
  const saveCrewMember = () => {
    if (selectedCrewMember.length === 0) {
      setDialogMessage(validationMessages[0]);
      setDialogOpen(true);
      return;
    }
    setOpenCrewMemberModal(false);

    if (selectedActivity.length === 0) {
      setDialogMessage(validationMessages[2]);
      setDialogOpen(true);
    } else {
      saveWeeklySnapShot(false);
    }
  }
  /* End - Crew member functionality */

  /* Start - Activity functionality */
  // handle activity popup event
  const handleClickOpenActivityModal = () => {
    setSearchFilter({ group: "", groupScope: "", groupActivity: "", groupArea: "", groupCostCode: "" });
    setActivityRows(activityList);

    // save any updated hours before opening crew member pop-up
    if (timeEntryModifiedData.length > 0) {
      saveTimeEntry(false);
    }
    setOpenActivityModal(true);
  }

  // close event for activity popup
  const closeActivityModaldialog = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpenActivityModal(false);
    }
  };

  // function to load time entries activities
  const getTimeEntryActivities = async () => {
    let timeEntryActivityRequestModel: ITimeEntryActivityRequestModel = {
      crewId: crewId,
      currentDate: weekFirstDate(timeEntryWeek.firstDay),
      projectId: projectId
    };
    await TimeEntryServices.GetActivities(timeEntryActivityRequestModel).then((res) => {
      if (!res.hasErrors && res.value) {
        const activities = res.value;
        setSelectedActivity(activities.filter(m => m.isSnapshot).map(s => s.id));
        setActivityList(activities);
        setActivityRows(activities);
      }
      else {
        setSelectedActivity([]);
        setActivityList([]);
        setActivityRows([]);
      }
    });
  }

  // handle activity on change event
  const handleActivityOnChange = (event: any, id: number) => {
    const index = activityRows.findIndex((m) => m.id === id);
    let tempActivityId = activityRows.slice();
    const tempIndex = selectedActivity.findIndex(f => f === tempActivityId[index]['id']);
    tempActivityId[index]['isSnapshot'] = event.target.checked;
    (event.target.checked) ? selectedActivity.push(id) : selectedActivity.splice(tempIndex, 1);

    setSelectedActivity(selectedActivity);
    setActivityRows(tempActivityId);
  }

  // function to search activity
  const searchActivity = (value: string | null, controlName: string) => {
    setFilterCriteria(value, controlName);
    bindDropdowns(searchFilter);

    const filterData = activityList.filter(f => f.group === (!isNullUndefined(searchFilter.group) ? searchFilter.group : f.group) &&
      f.groupScope === (!isNullUndefined(searchFilter.groupScope) ? searchFilter.groupScope : f.groupScope) &&
      f.groupActivity === (!isNullUndefined(searchFilter.groupActivity) ? searchFilter.groupActivity : f.groupActivity) &&
      f.groupArea === (!isNullUndefined(searchFilter.groupArea) ? searchFilter.groupArea : f.groupArea) &&
      f.costCode === (!isNullUndefined(searchFilter.groupCostCode) ? searchFilter.groupCostCode : f.costCode));

    setActivityRows(filterData);
  }

  // function to bind activity dropdowns
  const bindDropdowns = (filterObj: any) => {
    const dropdownList = activityList.filter(f => f.group === (!isNullUndefined(filterObj.group) ? filterObj.group : f.group)
      && f.groupScope === (!isNullUndefined(filterObj.groupScope) ? filterObj.groupScope : f.groupScope)
      && f.groupActivity === (!isNullUndefined(filterObj.groupActivity) ? filterObj.groupActivity : f.groupActivity)
      && f.groupArea === (!isNullUndefined(filterObj.groupArea) ? filterObj.groupArea : f.groupArea)
      && f.costCode === (!isNullUndefined(filterObj.groupCostCode) ? filterObj.groupCostCode : f.costCode));
    setGroupList(dropdownList.map(item => item.group).filter((value, index, current_value) => current_value.indexOf(value) === index));
    setGroupScopeList(dropdownList.map(item => item.groupScope).filter((value, index, current_value) => current_value.indexOf(value) === index));
    setGroupActivityList(dropdownList.map(item => item.groupActivity).filter((value, index, current_value) => current_value.indexOf(value) === index));
    setGroupAreaList(dropdownList.map(item => item.groupArea).filter((value, index, current_value) => current_value.indexOf(value) === index));
    setCostCodeList(dropdownList.map(item => item.costCode).filter((value, index, current_value) => current_value.indexOf(value) === index));
  }

  // set filter criteria
  const setFilterCriteria = (value: string | null, controlName: string) => {
    switch (controlName) {
      case "Group":
        searchFilter.group = value as string;
        break;
      case "Group Scope":
        searchFilter.groupScope = value as string;
        break;
      case "Group Activity":
        searchFilter.groupActivity = value as string;
        break;
      case "Group Area":
        searchFilter.groupArea = value as string;
        break;
      case "Group Cost Code":
        searchFilter.groupCostCode = value as string;
        break;
    }

    setSearchFilter(searchFilter);
  }

  // save activity
  const saveActivity = () => {
    if (selectedActivity.length === 0) {
      setDialogMessage(validationMessages[1]);
      setDialogOpen(true);
      return;
    }
    setOpenActivityModal(false);

    if (selectedCrewMember.length === 0) {
      setDialogMessage(validationMessages[3])
      setDialogOpen(true);
    } else {
      saveWeeklySnapShot(false);
    }
  }
  /* End - Activity functionality */

  /* Start - Save Activity Notes */
  // handle activity note popup event
  const handleClickOpenActivityNotesModal = (activityName: string) => {
    const snapshotIdList = timeEntryData.filter(t => t.activity.toLocaleLowerCase() === activityName.toLocaleLowerCase());
    setActivityNotes(snapshotIdList[0].activityNote);
    setActivityName(snapshotIdList[0].activity)
    setOpenActivityNotesModal(true);
  }

  // close event for activity note popup
  const closeActivityNotesModaldialog = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpenActivityNotesModal(false);
    }
  };

  // save activity notes
  const saveActivityNotes = async (notes: string, activity: string, isSaveTimeEntryData: boolean = false, uniqueSnapshotIds: number[] = []) => {
    if (!isSaveTimeEntryData) {
      const snapshotIdList = timeEntryData.filter(t => t.activity.toLocaleLowerCase() === activity.toLocaleLowerCase());
      uniqueSnapshotIds = snapshotIdList.map(item => item.weeklySnapshotId).filter((value, index, current_value) => current_value.indexOf(value) === index);
    }

    let saveActivityNotesRequestModel: ISaveActivityNotesRequestModel = {
      snapshotIds: uniqueSnapshotIds,
      crewId: crewId,
      notes: notes,
      userId: +window.UserId
    };

    await TimeEntryServices.SaveActivityNotes(saveActivityNotesRequestModel).then(
      (res) => {
        if (!res.hasErrors && res.value) {
          if (!isSaveTimeEntryData) {
            let tempSnapshotId = timeEntryData.slice();
            for (let ss = 0; ss < uniqueSnapshotIds.length; ss++) {
              timeEntryData.filter(f => f.weeklySnapshotId === uniqueSnapshotIds[ss] && f.activity.toLocaleLowerCase() === activity.toLocaleLowerCase()).forEach(a => {
                const index = timeEntryData.findIndex((m) => m.weeklySnapshotId === a.weeklySnapshotId && m.crewId === a.crewId
                  && m.date === a.date && m.crewMemberId === a.crewMemberId && m.activityId === a.activityId && m.activity.toLocaleLowerCase() === activity.toLocaleLowerCase());
                tempSnapshotId[index]['activityNote'] = notes;
              });
            }
            setTimeEntryData(tempSnapshotId);
            setOpenActivityNotesModal(false);
            pivotGridInstance.current?.instance.getDataSource().reload();
          }
        }
      }
    );
  }
  /* End - Save Activity Notes */

  /* Start - General */
  // handle popup close event
  const handleClose = () => {
    setDialogOpen(false);
    if (dialogMessage.toLocaleLowerCase().includes(validationMessages[2].toLocaleLowerCase()) || dialogMessage.toLocaleLowerCase().includes(validationMessages[3].toLocaleLowerCase())) {
      if (selectedCrewMember.length === 0) {
        handleClickOpenCrewMemberModal();
      } else if (selectedActivity.length === 0) {
        handleClickOpenActivityModal();
      }
    }
    setDialogMessage("");
  };

  // convert selected week dates into format
  const weekFirstDate = (date: string | number | Date) => {
    const dt = new Date(date);
    const currentdt = new Date();
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), currentdt.getHours(), currentdt.getMinutes(), currentdt.getSeconds(), currentdt.getMilliseconds());
  };

  // handle week change event
  const onWeekChange = (week) => {
    setIsLoadData(true);
    setTimeEntryWeek(week);
  };

  // save weekly snapshot data
  const saveWeeklySnapShot = async (isSaveTimeEntryData: boolean) => {
    let snapShotData: ISnapshotSaveResponseModel[] = [];
    setIsLoadData(true);
    // let reOrderActivity = activityRows.filter(x => x.isSnapshot).map(({ activitySequence, id }) => ({ activitySequence, id }));
    // let reOrderCrewMember = crewMemberRows.filter(x => x.isSnapshot).map(({ crewMemberSequence, id }) => ({ crewMemberSequence, id }));
    //let reOrderActivity = activityList.filter(x => x.isSnapshot).map(({ activitySequence, id }) => ({ activitySequence, id }));
    let reOrderCrewMember: number[] = [];
    let reOrderActivity: number[] = [];
    selectedCrewMember.forEach((item, index) => {
      reOrderCrewMember.push(index);
    })
    selectedActivity.forEach((item, index) => {
      reOrderActivity.push(index);
    })



    let snapshotSaveRequestModel: ISnapshotSaveRequestModel = {
      activityIds: selectedActivity,
      crewMemberIds: selectedCrewMember, // reOrderCrewMember.map(m => m.id), 
      crewMemberSequences: reOrderCrewMember, //.map(s => s.crewMemberSequence),
      activitySequences: reOrderActivity,
      crewId: crewId,
      date: weekFirstDate(timeEntryWeek.firstDay),
      userId: +window.UserId
    };

    await SnapshotServices.SaveSnapshot(snapshotSaveRequestModel).then(
      (res) => {
        if (!res.hasErrors && res.value) {
          if (!isSaveTimeEntryData) {
            getTimeEntry();
          }
          else {
            // update the weeklySnapshotId in the time entry data
            const localSnapShotData = res.value;
            let weeklySnapshotTimeEntry = timeEntryModifiedData.slice();
            selectedCrewMember.map(member => {
              return selectedActivity.map(activity => {
                const weeklySnapshotId = localSnapShotData.filter(s => s.activityId === activity && s.crewMemberId === member && s.crewId === crewId)[0].weeklySnapshotId;
                weeklySnapshotTimeEntry.filter(w => w.activityId === activity && w.crewMemberId === member).forEach(item => {
                  item.weeklySnapshotId = weeklySnapshotId;
                });
              });
            });

            snapShotData = localSnapShotData;
            setTimeEntryModifiedData(weeklySnapshotTimeEntry);
          }
        }
      }
    );
    return snapShotData;
  }

  // download time sheet as pdf
  const downloadPDF = async () => {
    setIsLoadData(true);
    let timeEntryRequstModel: ITimeEntryRequestModel = {
      crewId: crewId,
      currentDate: weekFirstDate(timeEntryWeek.firstDay),
      isIncludeWeekEnd: true,
    };
    await TimeEntryServices.DownloadTimeEntry(timeEntryRequstModel).then(
      (response) => {
        if (!response.hasErrors && response.value) {
          // Create a Blob from the file response
          // Extract file name and content from the response
          // const { FileName, FileContent } = response.value;

          const blob = new Blob([response.value.fileContent], { type: 'application/pdf' }); // Adjust content type if necessary

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element to trigger the download
          const link = document.createElement('a');
          link.href = url;
          // Extract the file name from response headers
          // const fileName = response.headers.get('FileName') || 'time_entry.pdf';

          link.setAttribute('download', response.value.fileName); // Set a default filename or extract it from response headers

          // Append the link to the document body and click it programmatically
          document.body.appendChild(link);
          link.click();

          // Cleanup
          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
        setIsLoadData(false);
      }
    );
  }
  /* End - General */

  return (
    <>
      {!props.isFromSelection ?? (
        <div className="breadcrumb-section">
          <Breadcrumb listTag="div">
            <BreadcrumbItem href="/" tag="a">
              Home
            </BreadcrumbItem>
            <BreadcrumbItem href="/timeEntry" tag="a">
              Timesheet
            </BreadcrumbItem>
            <BreadcrumbItem active tag="span">
              Time Entry Management
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      )}
      <div className="page-title-box">
        <h1 className="page-title">Timesheet</h1>
        <div className="page-button-section timeentry-button-section mb-3">
          <div className="button-wicker-wrapper">
            <WeekPicker onChange={onWeekChange} />
            <div>
              <Button
                color="primary"
                className="btn-icon-link btn mx-2 add-user-button"
                onClick={unLockTimeEntry}
                hidden={!isDisabledControl}
                disabled={!isDisabledControl}
              >
                Unlock
              </Button>
            </div>
          </div>

          <div className="button-wrapper">
            <button
              type="button"
              title="Save"
              className="btn-icon btn-left-icon btn-sm btn-bordered me-2 timeenetry-btn-save"
              onClick={(e) => saveTimeEntry(false)}
              disabled={isDisabledControl}
            >
              <SaveIcon /> Save
            </button>
            <button
              type="button"
              title="Submit"
              className={!isDisabledControl ? "btn-primary btn-icon btn-left-icon btn-sm btn-bordered me-5" : "btn-primary btn-icon btn-left-icon btn-sm btn-bordered me-2"}
              onClick={submitWarning}
              disabled={isDisabledControl || weekFirstDate(timeEntryWeek.firstDay).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)}
            > <PublishIcon /> Submit
            </button>
            {isDisabledControl && <button
              type="button"
              title="Download Timecards"
              className="btn-primary btn-icon btn-left-icon btn-sm btn-bordered me-5"
              onClick={downloadPDF}
            >
              <DownloadIcon />
            </button>}
            <button
              className="btn-icon btn-left-icon btn-sm btn-bordered me-2 timeenetry-btn-addcrew"
              title="Add Crew Member"
              onClick={handleClickOpenCrewMemberModal}
              disabled={isDisabledControl}
            > <PersonAddIcon />Add Crew
            </button>
            <button
              className="btn-icon btn-left-icon btn-sm btn-bordered timeenetry-btn-addactivity"
              title="Add Activity"
              onClick={handleClickOpenActivityModal}
              disabled={isDisabledControl}
            > <NoteAddIcon />Add Activity
            </button>

          </div>
        </div>
      </div>

      <div className="card user-detail-card timeentry-card">
        <div className="card-body">
          <div className="grid_wrapper autoheightcell">
            <Box>
              {
                !isLoadData ?
                  <PivotGrid
                    id="gridTimeEntry"
                    className="gridTimeEntryTbl"
                    ref={pivotGridInstance}
                    allowSorting={false}
                    allowSortingBySummary={false}
                    allowFiltering={true}
                    // height={810}
                    wordWrapEnabled={true}
                    allowExpandAll={true}
                    showBorders={true}
                    showTotalsPrior="both"
                    rowHeaderLayout="tree"
                    dataSource={dataSource}
                    onCellPrepared={onCellPrepared}
                    onCellClick={onCellClick}>

                    <Texts grandTotal="" />
                    <FieldChooser enabled={false} />
                    {/*<Scrolling mode="virtual" />*/}
                    <LoadPanel
                      enabled={false}
                    />
                  </PivotGrid>
                  :
                  <div className="loader">
                    <Loading />
                  </div>
              }
            </Box>
            <AlertDialogBox
              Open={dialogOpen}
              DialogTitle="Warning!"
              DialogContentText={dialogMessage}
              onClose={handleClose}
            />
          </div>
        </div>
      </div>
      <AddTimeEntryCrewMemberModalDialog
        Open={openCrewMemberModaldialog}
        DialogTitle={"Select Crew Member"}
        OnCancel={closeCrewMemberModaldialog}
        CrewMemberRowData={crewMemberRows}
        OnSearch={searchCrewMember}
        OnChange={handleCrewMemberOnChange}
        OnSave={saveCrewMember}
        SelectedCrewMembers={selectedCrewMember}
      />
      <AddTimeEntryActivityModalDialog
        Open={openActivityModaldialog}
        DialogTitle={"Select Activity"}
        OnCancel={closeActivityModaldialog}
        ActivityRowData={activityRows}
        OnSearch={searchActivity}
        OnChange={handleActivityOnChange}
        OnSave={saveActivity}
        ActivityGroups={groupList}
        ActivityScopes={groupScopeList}
        ActivityActivities={groupActivityList}
        ActivityAreas={groupAreaList}
        ActivityCostCodes={groupCostCodeList}
        SelectedActivities={selectedActivity}
      />
      <AddActivityNotesModalDialog
        DialogTitle={!isNullUndefined(activityNotes) ? "Update Notes" : "Add Notes"}
        DialogContentText={activityNotes}
        Open={openActivityNotesModal}
        OnSave={saveActivityNotes}
        OnCancel={closeActivityNotesModaldialog}
        IsEditMode={!isNullUndefined(activityNotes) ? true : false}
        ActivityName={activityName}
      />
      <AlertDialog
        Open={alertOpen}
        DialogTitle={"Submit Time Sheet"}
        DialogContentText={"You are about to submit timesheet for the week of " +
          ((timeEntryWeek.firstDay.getMonth() > 8) ? (timeEntryWeek.firstDay.getMonth() + 1) : ('0' + (timeEntryWeek.firstDay.getMonth() + 1))) + '/' + ((timeEntryWeek.firstDay.getDate() > 9) ? timeEntryWeek.firstDay.getDate() : ('0' + timeEntryWeek.firstDay.getDate())) + '/' + timeEntryWeek.firstDay.getFullYear()
          + " - " +
          ((timeEntryWeek.lastDay.getMonth() > 8) ? (timeEntryWeek.lastDay.getMonth() + 1) : ('0' + (timeEntryWeek.lastDay.getMonth() + 1))) + '/' + ((timeEntryWeek.lastDay.getDate() > 9) ? timeEntryWeek.lastDay.getDate() : ('0' + timeEntryWeek.lastDay.getDate())) + '/' + timeEntryWeek.lastDay.getFullYear()
          + ". Are you sure you want to proceed?"}
        Cancel={closeAlertDialogBox}
        onClose={closeAlertDialogBox}
        Confirm={submitTimeEntry}
        ButtonYes={"Submit"}
        ButtonNo={"Cancel"}
      />
      <div className="page-button-section">
      </div>
    </>
  );
}
