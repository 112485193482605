import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

export type IProps = {
    DialogTitle: string;
    DialogContentText: string;
    ButtonYes: string;
    ButtonNo: string;
    Confirm: any;
    Cancel?: any | null;
    Open: boolean;
    onClose?: any | null;
};

export const AlertDialog: React.FunctionComponent<IProps> = (props) => {
    //export default AlertDialog: React.FunctionComponent<any> = (props) => (
    //   const [open, setOpen] = React.useState(false);

    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };

    //   const handleClose = () => {
    //     setOpen(false);
    //   }; 

    return (
        <div style={{ zIndex: 10000 }}>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
            <Dialog
                open={props.Open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='confirm-dialog-delete'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
                    {props.DialogTitle}
                    <IconButton
                        aria-label="close"
                        onClick={props.Cancel}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 13,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ m: 0, p: 2, minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            {props.DialogContentText}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ m: 0, p: 2 }}>
                    <button type="button" className="btn btn-secondary btn-icon btn-sm" onClick={props.Cancel}>
                        {props.ButtonNo} <CloseIcon />
                    </button>
                    <button type="button" className="btn btn-primary btn-icon btn-sm" onClick={props.Confirm} autoFocus>
                        {props.ButtonYes} <CheckIcon />
                    </button>
                    {/* <Button onClick={props.Cancel}>{props.ButtonNo}</Button>
          <Button onClick={props.Confirm} autoFocus>
            {props.ButtonYes}
          </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
};
