import * as React from "react";
import { ITimeEntryActivityResponseModel } from "./ITimeEntry";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { DataGridPro, GridRowModel, GridRowOrderChangeParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type IProps = {
    DialogTitle: string;
    Open: boolean;
    ActivityRowData: ITimeEntryActivityResponseModel[];
    OnSave?: any | null;
    OnCancel?: any | null;
    OnChange: (event: any, id: number) => void;
    OnSearch: (value: string | null, controlName: string) => void;
    ActivityGroups: string[];
    ActivityScopes: string[];
    ActivityActivities: string[];
    ActivityAreas: string[];
    ActivityCostCodes: string[];
    SelectedActivities: number[];
};


function updateRowPosition(
    initialIndex: number,
    newIndex: number,
    rows: Array<GridRowModel>,
): Promise<any> {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                const rowsClone = [...rows];
                const row = rowsClone.splice(initialIndex, 1)[0];
                rowsClone.splice(newIndex, 0, row);
                resolve(rowsClone);
            },
            Math.random() * 500 + 100,
        ); // simulate network latency
    });
}

export const AddTimeEntryActivityModalDialog: React.FunctionComponent<IProps> = (props) => {
    const apiRef = useGridApiRef();
    const [rows, setRows] = React.useState(props.ActivityRowData);
    let activitySnapShotArray: any[] = [];
    const [loading, setLoading] = React.useState(false);
    

    React.useEffect(() => {
        loadData();
    }, [props]);

    React.useEffect(() => {
        if (apiRef.current !== null && Object.keys(apiRef.current).length > 0) {
            apiRef.current.subscribeEvent("rowDragStart", (params, event) => {
                if (!params.row?.isActive && !params.row?.isTracked) {
                    console.log('stop');
                    event.preventDefault();
                }
            });
        }
    }, [props, apiRef]);

    const loadData = () => {
        activitySnapShotArray = [...props.ActivityRowData];
        activitySnapShotArray.sort((a, b) => {
            return b.isSnapshot ? (props.SelectedActivities.indexOf(a.id) >= 0 && props.SelectedActivities.indexOf(b.id) >= 0) ? (props.SelectedActivities.indexOf(a.id) - props.SelectedActivities.indexOf(b.id)) : 1 : -1;
        });

        activitySnapShotArray.sort((a, b) => {
            return (!b.isSnapshot && !a.isSnapshot) ? a.activity < b.activity ? -1 : 1 : 0;
        });
        setRows(activitySnapShotArray);
    }

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(false);
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            rows, 
        );

        if (params.row.isSnapshot) {
            props.SelectedActivities.splice(params.oldIndex, 1);
            props.SelectedActivities.splice(params.targetIndex, 0, params.row.id);
        }
        setRows(newRows);
        setLoading(true);
    };

    const columns: GridColDef[] = [
        {
            field: "activity",
            headerName: "Activity",
            width: 500
        },
        {
            field: "costCode",
            headerName: "Cost Code",
            width: 300
        },
        {
            field: "isSnapshot",
            headerName: "",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row?.isSnapshot}
                    disabled={params.row?.hours > 0 || (!params.row?.isActive && !params.row?.isTracked)}
                    onChange={(e) => props.OnChange(e, params.row?.id)}
                />
            )
        }
    ];

    return (
        <div>
            <Dialog
                open={props.Open}
                onClose={props.OnCancel}
                fullWidth={true}
                maxWidth="md"
                className="confirm-dialog addForm custom-modal timeentry-activity-modal"
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {props.DialogTitle}
                    <IconButton
                        aria-label="close"
                        onClick={props.OnCancel}
                        sx={{
                            position: "absolute",
                            right: 10,
                            top: 13,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className="content-min-height">
                    <div className="row">
                        <div className="col-12">
                            <div className="card user-detail-card modal-detail-card activity-modal-detail-card">
                                <div className="card-body">
                                    <div className="dataTables_wrapper pt-2">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 d-flex justify-content-end mb-4">
                                                <Autocomplete
                                                    id="drpGroup"
                                                    autoHighlight
                                                    options={props.ActivityGroups}
                                                    sx={{ width: 200 }}
                                                    className="me-3"
                                                    onChange={(event: any, newValue: string | null) => {
                                                        props.OnSearch(newValue, "Group");
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Group"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="drpScope"
                                                    autoHighlight
                                                    options={props.ActivityScopes}
                                                    sx={{ width: 200 }}
                                                    className="me-3"
                                                    onChange={(event: any, newValue: string | null) => {
                                                        props.OnSearch(newValue, "Group Scope");
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Scope"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="drpActivity"
                                                    autoHighlight
                                                    options={props.ActivityActivities}
                                                    sx={{ width: 200 }}
                                                    className="me-3"
                                                    onChange={(event: any, newValue: string | null) => {
                                                        props.OnSearch(newValue, "Group Activity");
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Activity"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="drpArea"
                                                    autoHighlight
                                                    options={props.ActivityAreas}
                                                    sx={{ width: 200 }}
                                                    className="me-3"
                                                    onChange={(event: any, newValue: string | null) => {
                                                        props.OnSearch(newValue, "Group Area");
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Area"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="drpCostCode"
                                                    autoHighlight
                                                    options={props.ActivityCostCodes}
                                                    sx={{ width: 200 }}
                                                    className="me-3"
                                                    onChange={(event: any, newValue: string | null) => {
                                                        props.OnSearch(newValue, "Group Cost Code");
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Cost Code"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: "search",
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <TextField
                                                    id="standard-bare"
                                                    variant="outlined"
                                                    placeholder="Search..."
                                                    onChange={(e) => {
                                                        props.OnSearch(e.target.value, "Global Search");
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton>
                                                                <SearchOutlinedIcon />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid_wrapper autoheightcell">
                                        <DataGridPro
                                            apiRef={apiRef}
                                            rowSelection={false}
                                            rows={rows}
                                            isRowSelectable={(params: GridRowParams) => params.row.hours === 0}
                                            columns={columns}
                                            className="user-details-grid custom-details-grid modal-details-grid activity-details-grid"
                                            pageSizeOptions={[5, 10, 25]}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } }
                                            }}
                                            rowReordering={true}
                                            onRowOrderChange={handleRowOrderChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        type="button"
                        className="btn btn-secondary btn-icon btn-sm me-3"
                        onClick={props.OnSave}
                    >
                        Save Changes
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}