import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CreateIcon from '@mui/icons-material/Create';
import {  
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {  
  IManageUserModel,
  IRoleModel,
} from "../models/IUser";
import { Loading } from "../Shared/Loading";
import { Messages, NotifyType } from "../Shared/constant";
import { AlertDialogBox } from "../Shared/AlertDialogBox";
import Notify from "../Shared/notify";
import UserServices from "../services/UserServices";
import { GridRowsProp } from "@mui/x-data-grid";

//#region Model Declares
export type IProps = {
  DialogTitle: string;
  DialogContentText: string;
  ButtonYes: string;
  ButtonNo: string;
  onCancel?: any | null;
  Open: boolean;
  onClose?: any | null;
  IsEditMode: boolean;
  rowData: IManageUserModel;
  OpenUserModalEdit?: any | null;
  setReloadModalPopup: any;
  ReloadModalPopup: boolean;
};

const defaultValues: IManageUserModel = {
  firstName: "",
  lastName: "",
  id: 0,
  roleId: 0,
  email: "",
  isActive: true,
};
const initialRows: GridRowsProp = [];

//#endregion
export const UserModalDialog: React.FunctionComponent<IProps> = (props) => {
  //#region Variable Declaration

  //const declaration
  const { onClose } = props;
  const { onCancel } = props;
  const [manageUser, setManageUser] = React.useState(defaultValues);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoadSaveUser, setIsLoadSaveUser] = React.useState<boolean>(false);
  const [errFirstName, setErrFirstName] = React.useState<boolean>(false);
  const [errLastName, setErrLastName] = React.useState<boolean>(false);
  const [errRole, setErrRole] = React.useState<boolean>(false);
  const [errEmail, setErrEmail] = React.useState<boolean>(false);
  const [errMessageFirstName, setErrMessageFirstName] = React.useState("");
  const [errMessageLastName, setErrMessageLastName] = React.useState("");
  const [errMessageEmail, setErrMessageEmail] = React.useState("");

  const [roles, setRoles] = React.useState<IRoleModel[]>([]);
  const [rows, setRows] = React.useState(initialRows);
  //#endregion

  //#region UserEffect
  React.useEffect(() => {
    getRoleList();
    getUserEmails();
    setManageUser(defaultValues);
  }, []);

  React.useEffect(() => {
    setManageUser(defaultValues);

    if (props.rowData.id > 0) {
      setManageUser({
        ...manageUser,
        id: props.rowData.id,
        firstName: props.rowData.firstName,
        lastName: props.rowData.lastName,
        email: props.rowData.email,
        roleId: props.rowData.roleId,
        isActive: props.rowData.isActive,
      });
      props.setReloadModalPopup(false);

      props.OpenUserModalEdit();
      // getUserById();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData.id, props.ReloadModalPopup]);
  //#endregion

  //#region PageLoad Methods
  
  const getRoleList = async () => {
    UserServices.GetRoles().then((res) => {
      if (!res.hasErrors && res.value) {
        setRoles(res.value);
      }
    });
  };

  const getUserEmails = async () => {
    UserServices.GetUsers().then((res) => {
      if (!res.hasErrors && res.value) {
        setRows(res.value);
      }
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setManageUser({
      ...manageUser,
      [name]: value,
    });
  };

  const validateUser = async () => {
    resetErrorState();
    let isValid: boolean = true;

    if (
      manageUser.firstName === "" ||
      manageUser.firstName === undefined ||
      manageUser.firstName === null
    ) {
      setErrFirstName(true);
      setErrMessageFirstName(Messages.ManageUser.requiredFirstName);
      isValid = false;
    }
    if (
      manageUser.lastName === "" ||
      manageUser.lastName === undefined ||
      manageUser.lastName === null
    ) {
      setErrLastName(true);
      setErrMessageLastName(Messages.ManageUser.requiredLastName);
      isValid = false;
    }
    if (
      manageUser.email === "" ||
      manageUser.email === undefined ||
      manageUser.email === null
    ) {
      setErrEmail(true);
      setErrMessageEmail(Messages.ManageUser.requiredEmail);
      isValid = false;
    } else {
      const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      if (manageUser.email && manageUser.email.match(isValidEmail)) {
        setErrEmail(false);
        const filterRows = rows.filter((x) => x.email !== props.rowData.email);
        const isExist =
        filterRows.filter((x) => x.email === manageUser.email && x.id !== manageUser.id).length > 0;
        if (isExist) {
          setErrEmail(true);
          setErrMessageEmail(Messages.ManageUser.exists);
          isValid = false;
        }
      } else {
        setErrEmail(true);
        setErrMessageEmail(Messages.ManageUser.emailInvalid);
        isValid = false;
      }
    }

    if (
      manageUser.roleId === 0 ||
      manageUser.roleId === undefined ||
      manageUser.roleId === null
    ) {
      setErrRole(true);
     // setErrMessageRole(Messages.ManageUser.requiredRole);
      isValid = false;
    }

    if (isValid) {
      saveUser();
    }
  };

  const saveUser = async () => {
    let saveUser: IManageUserModel = {
      id: manageUser.id,
      firstName: manageUser.firstName,
      lastName: manageUser.lastName,
      email: manageUser.email,
      roleId: manageUser.roleId,
      isActive: manageUser.isActive,
    };
    setIsLoadSaveUser(true);
    var response = await UserServices.SaveUser(saveUser);
    if (response.success && !response.hasErrors && response.value) {
      setIsLoadSaveUser(false);
      Notify(
        NotifyType.success,
        props.IsEditMode
          ? Messages.ManageUser.updated
          : Messages.ManageUser.saved
      );
      setManageUser(defaultValues);      
      onClose();
    } else {
      setIsLoadSaveUser(false);
      Notify(NotifyType.error, Messages.ManageUser.error);
    }
  };

  //#endregion

  //#region internal methods
  const resetErrorState = async () => {
    setErrFirstName(false);
    setErrLastName(false);
    setErrRole(false);
    setErrEmail(false);
  };

  const resetUser = async () => {
    resetErrorState();
    setManageUser(defaultValues);
    onCancel();
  };

  const closeAlertDialogBox = () => {
    setAlertOpen(false);
    setAlertMessage("");
  };
  //#endregion

  //#region Validation Starts

  const handleErrorsFirstName = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleErrorsLastName = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleErrorsEmail = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleErrorsRole = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  //#endregion

  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.onCancel}
        className="confirm-dialog addForm custom-modal"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {props.DialogTitle}
          <IconButton
            aria-label="close"
            onClick={resetUser}
            sx={{
              position: "absolute",
              right: 10,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          {!isLoadSaveUser ? (
            <div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  name="firstName"
                  value={manageUser.firstName}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errFirstName}
                  helperText={errFirstName ? errMessageFirstName : ""}
                  onBlur={(e) => {
                    setErrFirstName(handleErrorsFirstName(e.target.value));
                  }}
                />
              </div>

              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  name="lastName"
                  value={manageUser.lastName}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errLastName}
                  helperText={errLastName ? errMessageLastName : ""}
                  onBlur={(e) => {
                    setErrLastName(handleErrorsLastName(e.target.value));
                  }}
                />
              </div>

              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  name="email"
                  value={manageUser.email}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  error={errEmail}
                  helperText={errEmail ? errMessageEmail : ""}
                  onBlur={(e) => {
                    setErrEmail(handleErrorsEmail(e.target.value));
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <FormControl fullWidth error={errRole && true}>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="roleId"
                    //  value={`${props.rowData.roleId}`}
                    value={manageUser.roleId}
                    onChange={(e) => {
                      setManageUser({
                        ...manageUser,
                        roleId: parseInt(e.target.value.toString()),
                      });
                    }}
                    label="Role"
                    onBlur={(e) => {
                      setErrRole(handleErrorsRole(e.target.value));
                    }}
                    //disabled={window.AdminRoleId === +window.RoleId ? false : true}
                  >
                    {roles.map((role: IRoleModel, index: number) => (
                      <MenuItem key={index} value={role.id}>{role.role}</MenuItem>
                    ))}
                  </Select>
                  {errRole && (
                    <FormHelperText>
                      {errRole ? Messages.ManageUser.requiredRole : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className="form-group mb-4">
                {
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={manageUser.isActive}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setManageUser({
                              ...manageUser,
                              isActive: e.target.checked,
                            });
                          } else {
                            setManageUser({
                              ...manageUser,
                              isActive: e.target.checked,
                            });
                          }
                        }}
                      />
                    }
                    label="Active"
                  />
                }
              </div>
            </div>
          ) : (
            <div className="loader custom-loader">
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary btn-icon btn-sm"
            onClick={resetUser}
          >
            Close <CloseIcon />
          </button>
          {!props.IsEditMode ? (
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={validateUser}
            >
              Add <PersonAddAlt1Icon />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={validateUser} 
            >
              Update <CreateIcon />
            </button>
          )}
        </DialogActions>
      </Dialog>
      <AlertDialogBox
        Open={alertOpen}
        DialogTitle={Messages.ManageUser.userAlert}
        DialogContentText={alertMessage}
        onClose={closeAlertDialogBox}
      />
    </div>
  );
};
