import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddUser from "@mui/icons-material/PersonAdd";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
  GridRowsProp,
  GridRowModesModel,  
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,  
} from "@mui/x-data-grid";
import { useEffect } from "react";
import UserServices from "../services/UserServices";
import { IManageUserModel } from "../models/IUser";
import { UserModalDialog } from "./AddUserModal";
import { Messages, UserRoleType } from "../Shared/constant";
import { Loading } from "../Shared/Loading";
import UnAuthorized from "../Shared/UnAuthorized";


const defaultValues: IManageUserModel = {
  firstName: "",
  lastName: "",
  id: 0,
  roleId: 0,
  email: "",
  isActive: true,
};

const initialRows: GridRowsProp = [];

export default function UserDetails() {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [manageUser, setManageUser] = React.useState(defaultValues);
  const [userID, setUserID] = React.useState<number>(0);
  //const [firstName, setFirstName] = React.useState<string>("");
  //const [lastName, setLastName] = React.useState<string>("");
  //const [email, setEmail] = React.useState<string>("");
  //const [IsActive, setIsActive] = React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [openUserModaldialog, setOpenUserModal] =
    React.useState<boolean>(false);
  const [isLoadUserDetailData, setIsLoadUserDetailData] =
    React.useState<boolean>(false);
  const [reloadModalPopup, setReloadModalPopup] =
    React.useState<boolean>(false);

  useEffect(() => {
    getUserDetails();
    setManageUser(defaultValues);
    setOpenUserModal(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = async () => {
    //setIsLoaded(false);
    UserServices.GetUsers().then((res) => {
      if (!res.hasErrors && res.value) {
        res.value.forEach((element) => {
          setManageUser({
            ...manageUser,
            id: element.id,
            firstName: element.firstName,
            lastName: element.lastName,
            email: element.email,
            roleId: element.roleId,
            isActive: element.isActive,
          });
        });
        setRows(res.value);
        //  setIsLoaded(true);
      }
    });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setUserID(+id);
    const rowData = rows.filter((x) => x.id === id)[0];
    setManageUser({
      ...manageUser,
      id: rowData.id,
      firstName: rowData.firstName,
      lastName: rowData.lastName,
      email: rowData.email,
      roleId: rowData.roleId,
      isActive: rowData.isActive,
    });
    setReloadModalPopup(true);
    setIsEditMode(true);
    setOpenUserModal(true);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const openUserModalEdit = () => {
    setIsLoadUserDetailData(true);
    //  setOpenUserModal(true);
  };

  const closeUserModal = () => {
    setOpenUserModal(false);
    setUserID(0);
  };
  const handlecloseUserModal = () => {
    setOpenUserModal(false);
    setUserID(0);
    getUserDetails();
  };

  const handleClickOpenUserModal = () => {
    setIsEditMode(false);
    setOpenUserModal(true);
    setManageUser(defaultValues);
    setUserID(0);
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 250,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      editable: false,
      type: "singleSelect",
      valueOptions: ["Admin", "Superintendent", "Project Manager"],
    },
    {
      field: "isActive",
      headerName: "Active",
      width: 200,
      editable: false,
      cellClassName: 'isactive-col',
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        //const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  return (
    <>
    {window.RoleId === UserRoleType.Admin.toString() || window.RoleId === UserRoleType.ProjectManager.toString() || window.RoleId === UserRoleType.Accountant.toString() ? (
      <><div className="page-title-box">
            <h1 className="page-title">User Management</h1>
          </div><div className="card user-detail-card pages-detail-card">
            <div className="card-body">
              <div className="dataTables_wrapper">
                <div className="row">
                  <div className="col-sm-12 col-md-8"></div>
                  <div className="col-sm-12 col-md-4">
                    <div className="dataTables_filter">
                      {isLoadUserDetailData ? (
                        <Button
                          color="primary"
                          startIcon={<AddUser />}
                          className="btn-icon-link btn mx-3 add-user-button"
                          onClick={handleClickOpenUserModal}
                        >
                          Add User
                        </Button>
                      ) : null}

                      {/* <label>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={autoCompleteData}
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        onChange={handleInputChange}
        className="autocomplete form-control"
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Search User"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </label> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid_wrapper autoheightcell">
                <Box
                  sx={{
                    height: 500,
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                  }}
                >
                  {isLoadUserDetailData ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      editMode="row"
                      className="user-details-grid custom-details-grid gridUserMgmtTbl"
                      rowModesModel={rowModesModel}
                      // apiRef={apiRef}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      // slots={{
                      //   toolbar: EditToolbar,
                      // }}
                      slotProps={{
                        toolbar: { setRows, setRowModesModel },
                      }} />
                  ) : (
                    <div className="loader custom-loader">
                      <Loading />
                    </div>
                  )}
                </Box>
              </div>
            </div>
          </div><UserModalDialog
            Open={openUserModaldialog}
            DialogTitle={isEditMode ? "Edit User" : "Add New User"}
            DialogContentText={Messages.ManageUser.confirmMessage}
            ButtonYes={"Yes"}
            ButtonNo={"No"}
            onCancel={closeUserModal}
            onClose={handlecloseUserModal}
            IsEditMode={isEditMode}
            rowData={manageUser}
            OpenUserModalEdit={openUserModalEdit}
            setReloadModalPopup={setReloadModalPopup}
            ReloadModalPopup={reloadModalPopup} /></>
    ) : (
      <div>
          <UnAuthorized />
        </div>
    )}
    </>
  );
}
