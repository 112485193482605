import React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridRowsProp,
    GridRowModesModel,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowModel,
    GridColumnVisibilityModel,
    GridToolbarContainer,
    GridToolbarExport,
    GridApi,
} from "@mui/x-data-grid";
import { Loading } from "../../Shared/Loading";
import ReportServices from "../ReportsServices";
import CrewServices from "../../CrewDetail/CrewServices";
import { IProjectsModel } from "../../models/IUser";
import { Autocomplete, TextField } from "@mui/material";
import { ITimeEntryDataInput } from "../IReports";
import { Messages, UserRoleType } from "../../Shared/constant";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import UnAuthorized from "../../Shared/UnAuthorized";

const initialRows: GridRowsProp = [];

//#region ToolBar

interface EditToolbarProps {
    apiRef: React.MutableRefObject<GridApi>;
    title: "";
    handleFilesChange: any;
    isReadOnly: boolean;
    isDisplayAutoButton: boolean;
}

let selectedProjectCode = "";
let selectedStartDate = "";
let selectedEndDate = "";

function EditToolbar(props: EditToolbarProps) {
    const { apiRef, handleFilesChange, isReadOnly, isDisplayAutoButton } = props;

    return (
        <>
            <div className="row mt-2 align-items-center">
                <div className="col">
                    <h5 className="py-3">{props.title}</h5>
                </div>
                <div className="col d-flex justify-content-end">
                    <GridToolbarContainer>
                        <GridToolbarExport
                            color="primary"
                            className="btn-icon-link btn"
                            csvOptions={{
                                fileName: `Timedata_${selectedStartDate}_${selectedEndDate}`,
                                utf8WithBom: true,
                            }}
                            printOptions={{ disableToolbarButton: true }}
                        />
                    </GridToolbarContainer>
                </div>
            </div>
        </>
    );
}
//#endregion

const defaultValues: ITimeEntryDataInput = {
    projectCode: "",
    startDate: "",
    endDate: "",
};

export default function TimeEntryExport() {
    const [rows, setRows] = React.useState(initialRows);
    const [isLoadTimeEntryData, setIsLoadTimeEntryData] =
        React.useState<boolean>(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
        {}
    );
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({ id: false });
    const [projects, setProjects] = React.useState<IProjectsModel[]>([]);
    const [inputPrams, setInputParams] = React.useState(defaultValues);
    const [errStartDate, setErrStartDate] = React.useState<boolean>(false);
    const [errMessageStartDate, setErrMessageStartDate] = React.useState("");
    const [errEndDate, setErrEndDate] = React.useState<boolean>(false);
    const [errMessageEndDate, setErrMessageEndDate] = React.useState("");
    const [errProjects, setErrProjects] = React.useState<boolean>(false);
    const [errMessageProject, setErrMessageProject] = React.useState("");
    const [valStartDate, setValStartDate] = React.useState<number>(0);
    const [valEndDate, setValEndDate] = React.useState<number>(0);
    const [errDateRange, setErrDateRange] = React.useState<boolean>(false);
    const [errMessageDateRange, setErrMessageDateRange] = React.useState("");

    useEffect(() => {
        getProjects();
    }, []);

    const getTimeEntrySummaryDetails = async (
        projectId: string,
        startDate: string,
        endDate: string
    ) => {
        ReportServices.GetTimeEntrySummary(projectId, startDate, endDate).then(
            (res) => {
                if (!res.hasErrors && res.value) {
                    const timeEntry = res.value;
                    setRows(timeEntry);
                    setIsLoadTimeEntryData(true);
                }
            }
        );
    };

    const getProjects = async () => {
        setIsLoadTimeEntryData(false);
        CrewServices.GetProjects(0).then((res) => {
            if (!res.hasErrors && res.value) {
                const localProjects = res.value.filter((x) => x.parent == null && x.isActive == true && x.isTracked == true);
                setProjects(localProjects);
                setIsLoadTimeEntryData(true);
            }
        });
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (
        params,
        event
    ) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleChange = (value: any, cntrlName: string) => {
        if (value === undefined || value === null || value === "" || value === 0 || value.length === 0) {
            switch (cntrlName) {
                case "StartDate":
                    setErrStartDate(true);
                    setErrMessageStartDate(Messages.Reports.requiredStartDate);
                    setInputParams({
                        ...inputPrams,
                        startDate: "",
                    });
                    break;
                case "EndDate":
                    setErrEndDate(true);
                    setErrMessageEndDate(Messages.Reports.requiredEndDate);
                    setInputParams({
                        ...inputPrams,
                        endDate: "",
                    });
                    break;
                case "ProjectCode":
                    setErrProjects(true);
                    setErrMessageProject(Messages.Reports.requiredProjectCode);
                    setInputParams({
                        ...inputPrams,
                        projectCode: "",
                    });
                    break;
            }
        } else {
            switch (cntrlName) {
                case "StartDate":
                    setErrStartDate(false);
                    setInputParams({
                        ...inputPrams,
                        startDate: dayjs(value).format("YYYY-MM-DDThh:mm:ss"),
                    });
                    setValStartDate(Date.parse(dayjs(value.$d).toString()));
                    selectedStartDate = dayjs(value).format("YYYYMMDD");
                    if (
                        valEndDate &&
                        valEndDate < Date.parse(dayjs(value.$d).toString())
                    ) {
                        setErrDateRange(true);
                        setErrMessageDateRange(Messages.Reports.dateRangeValidation);
                    } else {
                        setErrDateRange(false);
                    }
                    break;
                case "EndDate":
                    setErrEndDate(false);
                    setInputParams({
                        ...inputPrams,
                        endDate: dayjs(value).format("YYYY-MM-DDThh:mm:ss"),
                    });
                    setValEndDate(Date.parse(dayjs(value.$d).toString()));
                    selectedEndDate = dayjs(value).format("YYYYMMDD");
                    if (
                        valStartDate &&
                        valStartDate > Date.parse(dayjs(value.$d).toString())
                    ) {
                        setErrDateRange(true);
                        setErrMessageDateRange(Messages.Reports.dateRangeValidation);
                    } else {
                        setErrDateRange(false);
                    }
                    break;
                case "ProjectCode":
                    setInputParams({
                        ...inputPrams,
                        projectCode: value,
                    });
                    setErrProjects(false);
                    break;
            }
        }
    };

    const handleErrors = (value: any, cntrlName: string) => {
        resetErrorState();
        if (value === undefined || value === null || value === "" || value === 0) {
            switch (cntrlName) {
                case "ProjectCode":
                    setErrProjects(true);
                    setErrMessageProject(Messages.Reports.requiredProjectCode);
                    break;
                case "StartDate":
                    setErrStartDate(true);
                    setErrMessageStartDate(Messages.Reports.requiredStartDate);
                    break;
                case "EndDate":
                    setErrEndDate(true);
                    setErrMessageEndDate(Messages.Reports.requiredEndDate);
                    break;
                // case "EndDate":
                //     setErrDateRange(true);
                //     setErrMessageDateRange(Messages.Reports.dateRangeValidation);
                //     break;
            }
        }
    };

    const resetErrorState = async () => {
        setErrProjects(false);
        setErrStartDate(false);
        setErrEndDate(false);
        setErrDateRange(false);
    };

    const validateSearchForm = (inputs: ITimeEntryDataInput) => {
        resetErrorState();
        let isValid = true;

        if (
            inputs.projectCode === undefined ||
            inputs.projectCode === null ||
            inputs.projectCode === ""
        ) {
            setErrProjects(true);
            setErrMessageProject(Messages.Reports.requiredProjectCode);
            isValid = false;
        }

        if (
            inputs.startDate === undefined ||
            inputs.startDate === null ||
            inputs.startDate === ""
        ) {
            setErrStartDate(true);
            setErrMessageStartDate(Messages.Reports.requiredStartDate);
            isValid = false;
        }

        if (
            inputs.endDate === undefined ||
            inputs.endDate === null ||
            inputs.endDate === ""
        ) {
            setErrEndDate(true);
            setErrMessageEndDate(Messages.Reports.requiredEndDate);
            isValid = false;
        }

        if (!errStartDate && !errEndDate) {
            if (valStartDate > valEndDate) {
                setErrDateRange(true);
                setErrMessageDateRange(Messages.Reports.dateRangeValidation);
                isValid = false;
            }
        }

        if (isValid) {
            return true;
        } else {
            return false;
        }
    };
    const searchTimeEntry = async () => {
        if (validateSearchForm(inputPrams)) {
            setIsLoadTimeEntryData(false);
            selectedProjectCode = inputPrams.projectCode;

            getTimeEntrySummaryDetails(
                projects
                    .filter(({ projectCode }) =>
                        inputPrams.projectCode.includes(projectCode)
                    )
                    .map((x) => x.id)
                    .toLocaleString(),
                inputPrams.startDate,
                inputPrams.endDate
            );
        }
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "",
            width: 75,
        },
        {
            field: "employeeName",
            headerName: "Employee Name",
            width: 300,
            editable: false,
        },
        {
            field: "employeeNumber",
            headerName: "Employee ID",
            width: 175,
            editable: false,
        },
        {
            field: "payCode",
            headerName: "Pay Code",
            width: 100,
            editable: false,
        },
        {
            field: "hours",
            headerName: "Hours",
            width: 75,
            editable: false,
        },
        {
            field: "workDate",
            headerName: "Work Date",
            width: 150,
            editable: false,
        },
        {
            field: "jobNumber",
            headerName: "Job Number",
            width: 125,
            editable: false,
        },
        {
            field: "fullAccountNumber",
            headerName: "Full Account Number",
            width: 250,
            editable: false,
        },
        {
            field: "subType",
            headerName: "Sub Type",
            width: 100,
            editable: false,
        },
        {
            field: "subLedger",
            headerName: "Sub Ledger",
            width: 100,
            editable: false,
        },
        {
            field: "overridePayRate",
            headerName: "Override Pay Rate",
            width: 250,
            editable: false,
        },
        {
            field: "combinedName",
            headerName: "Combined Name",
            width: 350,
            editable: false,
        },
        {
            field: "group",
            headerName: "Group",
            width: 100,
            editable: false,
        },
        {
            field: "scope",
            headerName: "Scope",
            width: 175,
            editable: false,
        },
        {
            field: "activity",
            headerName: "Activity",
            width: 100,
            editable: false,
        },
        {
            field: "area",
            headerName: "Area",
            width: 100,
            editable: false,
        },
        {
            field: "description",
            headerName: "Description",
            width: 250,
            editable: false,
        },
        {
            field: "pay",
            headerName: "Pay",
            width: 100,
            editable: false,
        },
        {
            field: "crewTitle",
            headerName: "Crew Title",
            width: 150,
            editable: false,
        },
        {
            field: "supervisor",
            headerName: "Supervisor",
            width: 200,
            editable: false,
        },
        {
            field: "submittedBy",
            headerName: "Submitted By",
            width: 200,
            editable: false,
        },
        {
            field: "submittedDateTime",
            headerName: "Submitted DateTime",
            width: 250,
            editable: false,
            valueFormatter: (params) => params.value !== null ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : ""
        },
    ];

    return (
        <>
            {window.RoleId !== UserRoleType.FieldSupervisor.toString() ? (
                <>
                    <div className="page-title-box">
                        <h1 className="page-title">Timedata</h1>
                    </div>

                    <div className="card user-detail-card pages-detail-card">
                        <div className="card-body">
                            <div className="dataTables_wrapper">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 d-flex mb-2 gap-3">
                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            id="drpProjects"
                                            options={projects.map((option) => option.projectCode)}
                                            filterSelectedOptions
                                            sx={{ minWidth: 300 }}
                                            onChange={(event: any, newValue: string[] | null) => {
                                                handleChange(newValue, "ProjectCode");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search Project Number"
                                                    required
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: "search",
                                                        placeholder: "Project",
                                                    }}
                                                    error={errProjects}
                                                    helperText={errProjects ? errMessageProject : ""}
                                                />
                                            )} />

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label="Start Date"
                                                inputFormat="MM/DD/YYYY"
                                                value={inputPrams.startDate}
                                                onChange={(e: Dayjs | null) => {
                                                    handleChange(e, "StartDate");
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: "MM/DD/YYYY",
                                                        }}
                                                        helperText={errStartDate ? errMessageStartDate : ""}
                                                        //required
                                                        error={errStartDate}
                                                        onBlur={(e) => {
                                                            handleErrors(e.target.value, "StartDate");
                                                        }} />
                                                )} />

                                            <DesktopDatePicker
                                                label="End Date"
                                                inputFormat="MM/DD/YYYY"
                                                value={inputPrams.endDate}
                                                onChange={(e: Dayjs | null) => {
                                                    handleChange(e, "EndDate");
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: "MM/DD/YYYY",
                                                        }}
                                                        helperText={errEndDate
                                                            ? errMessageEndDate
                                                            : errDateRange
                                                                ? errMessageDateRange
                                                                : ""}
                                                        //required
                                                        error={errEndDate || errDateRange}
                                                        onBlur={(e) => {
                                                            handleErrors(e.target.value, "EndDate");
                                                        }} />
                                                )} />
                                        </LocalizationProvider>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-icon btn-sm"
                                                onClick={searchTimeEntry}
                                            >
                                                Search <SearchIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid_wrapper">
                                <Box
                                    sx={{
                                        height: 500,
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                    }}
                                >
                                    {isLoadTimeEntryData ? (
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            autoHeight={true}
                                            disableRowSelectionOnClick={true}
                                            editMode="row"
                                            className="user-details-grid custom-details-grid report-timedata"
                                            rowModesModel={rowModesModel}
                                            onRowModesModelChange={handleRowModesModelChange}
                                            onRowEditStop={handleRowEditStop}
                                            processRowUpdate={processRowUpdate}
                                            columnVisibilityModel={columnVisibilityModel}
                                            slotProps={{
                                                toolbar: { setRows, setRowModesModel },
                                            }}
                                            slots={{
                                                toolbar: EditToolbar,
                                            }} />
                                    ) : (
                                        <div className="loader">
                                            <Loading />
                                        </div>
                                    )}
                                </Box>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div>
                    <UnAuthorized />
                </div>
            )}
        </>
    );
}
