import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  IActionModel

} from "../../models/IUser";
import { Loading } from "../../Shared/Loading";
import { Messages, NotifyType } from "../../Shared/constant";
import Notify from "../../Shared/notify";
import { GridRowsProp } from "@mui/x-data-grid";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';
import { IManageSummaryOverride } from "../IReports";
import ReportServices from "../ReportsServices";

//#region Model Declares
export type IProps = {
  DialogTitle: string;
  DialogContentText: string;
  ButtonYes: string;
  ButtonNo: string;
  onCancel?: any | null;
  Open: boolean;
  onClose?: any | null;
  rowData: any;
  OpenSummaryModalEdit?: any | null;
  setReloadModalPopup: any;
  ReloadModalPopup: boolean;
};

const defaultValues: IManageSummaryOverride = {
  activityId: 0,
  action: "",
  quantity: "",
  notes: "",
  submissionDate: null,
  createdBy: 0
};
const initialRows: GridRowsProp = [];

//#endregion
export const SummaryOverrideModalDialog: React.FunctionComponent<IProps> = (props) => {
  //#region Variable Declaration

  //const declaration
  const { onClose } = props;
  const { onCancel } = props;
  const [manageSummaryOverrides, setManageSummaryOverrides] = React.useState(defaultValues);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoadSaveSummaryOverrides, setIsLoadSaveSummaryOverrides] = React.useState<boolean>(false);
  const [errActionText, setErractionText] = React.useState<boolean>(false);
  const [errNotes, setErrNotes] = React.useState<boolean>(false);
  const [errAction, setErrAction] = React.useState<boolean>(false);
  const [errMessageActionText, setErrMessageActionText] = React.useState("");
  const [errMessageNotes, setErrMessageNotes] = React.useState("");
  const [errSubmissionDate, setErrSubmissionDate] = React.useState<boolean>(false);
  const [errMessageSubmissionDate, setErrMessageSubmissionDate] = React.useState("");

  const [action, setAction] = React.useState<IActionModel[]>([]);
  //#endregion

  //#region UserEffect
  React.useEffect(() => {
    getActionList();
    resetSummaryOverrides();
  }, []);

  React.useEffect(() => {
    setManageSummaryOverrides(defaultValues);

    props.setReloadModalPopup(false);

    props.OpenSummaryModalEdit();
  }, [props.rowData.activityId, props.ReloadModalPopup]);

  //#endregion

  //#region PageLoad Methods

  const getActionList = async () => {
    const actionArray = [
      { actionId: 1, actionName: "Override Actual Qty" },
      { actionId: 2, actionName: "Actual Qty +/-" },
      { actionId: 3, actionName: "Override % Complete" },
      { actionId: 4, actionName: "% Complete +/-" },
    ];
    setAction(actionArray);
    //  }
  };


  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setManageSummaryOverrides({
      ...manageSummaryOverrides,
      [name]: value,
    });
  };

  const handleChange = (newValue: Dayjs | null, cntrlName: string) => {
    if (newValue === undefined || newValue === null) {
      switch (cntrlName) {
        case 'SubmissionDate':
          setErrSubmissionDate(true);
          setErrMessageSubmissionDate(Messages.SummaryOverride.requiredSubmissionDate);
          break;
      }
    } else {
      switch (cntrlName) {
        case 'SubmissionDate':
          setErrSubmissionDate(false);
          setManageSummaryOverrides({
            ...manageSummaryOverrides,
            submissionDate: dayjs(newValue).format("YYYY-MM-DDThh:mm:ss"),
          });
          break;
      }
    }
  };

  const validatereSummaryOverrides = async () => {
    resetErrorState();
    let isValid: boolean = true;

    if (
      manageSummaryOverrides.quantity === "" ||
      manageSummaryOverrides.quantity === undefined ||
      manageSummaryOverrides.quantity === null
    ) {
      setErractionText(true);
      setErrMessageActionText(Messages.SummaryOverride.quantityRequired);
      isValid = false;
    }
    if (
      manageSummaryOverrides.action === "" ||
      manageSummaryOverrides.action === undefined ||
      manageSummaryOverrides.action === null
    ) {
      setErrAction(true);
      isValid = false;
    }
    if (
      manageSummaryOverrides.submissionDate === "" ||
      manageSummaryOverrides.submissionDate === undefined ||
      manageSummaryOverrides.submissionDate === null ||
      manageSummaryOverrides.submissionDate === 0
    ) {
      setErrSubmissionDate(true);
      setErrMessageSubmissionDate(Messages.SummaryOverride.requiredSubmissionDate);
      isValid = false;
    }

    if (isValid) {
      saveSummaryOverrides();
    }
  };

  const saveSummaryOverrides = async () => {
    let saveSummaryOverride: IManageSummaryOverride = {
      activityId: props.rowData.activityId,
      action: manageSummaryOverrides.action,
      quantity: manageSummaryOverrides.quantity,
      notes: manageSummaryOverrides.notes,
      submissionDate: manageSummaryOverrides.submissionDate,
      createdBy: parseInt(window.UserId),

    };
    setIsLoadSaveSummaryOverrides(true);
    var response = await ReportServices.SaveSummaryOverrides(saveSummaryOverride);
    if (response.success && !response.hasErrors) {
      setIsLoadSaveSummaryOverrides(false);
      Notify(
        NotifyType.success, Messages.SummaryOverride.saved
      );
      setManageSummaryOverrides(defaultValues);
      onClose();
    } else {
      setIsLoadSaveSummaryOverrides(false);
      Notify(NotifyType.error, Messages.ManageUser.error);
    }
  };

  //#endregion

  //#region internal methods
  const resetErrorState = async () => {
    setErractionText(false);
    setErrAction(false);
    setErrSubmissionDate(false);
  };

  const resetSummaryOverrides = async () => {
    resetErrorState();
    setManageSummaryOverrides(defaultValues);
    onCancel();
  };

  const closeAlertDialogBox = () => {
    setAlertOpen(false);
    setAlertMessage("");
  };
  //#endregion

  //#region Validation Starts

  const handleErrorsActionText = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      setErrMessageActionText(Messages.SummaryOverride.quantityRequired);
      return true;
    } else {
      return false;
    }
  };
  const handleErrorsNotes = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleErrorsSubmissionDate = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleErrorsAction = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  //#endregion

  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.onCancel}
        className="confirm-dialog addForm custom-modal"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {props.DialogTitle}
          <IconButton
            aria-label="close"
            onClick={resetSummaryOverrides}
            sx={{
              position: "absolute",
              right: 10,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          {!isLoadSaveSummaryOverrides ? (
            <div>
               {/* <div className="form-group mb-4">
               <InputLabel htmlFor="login" sx={{display: 'inline'}}>Activity : {props.rowData.combinedName}</InputLabel>
              </div> */}

              <div className="form-group mb-4">
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Activity"
                  defaultValue={props.rowData.combinedName}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div className="form-group mb-4">
                <FormControl fullWidth error={errAction && true}>
                  <InputLabel id="demo-simple-select-label">Action</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="actionId"
                    onChange={(e) => {
                      setManageSummaryOverrides({
                        ...manageSummaryOverrides,
                        action: action.filter(x => x.actionId == e.target.value)[0].actionName,
                      });
                    }}
                    label="Action"
                    onBlur={(e) => {
                      setErrAction(handleErrorsAction(e.target.value));
                    }}
                  >
                    {action.map((action: IActionModel, index: number) => (
                      <MenuItem key={index} value={action.actionId}>{action.actionName}</MenuItem>
                    ))}
                  </Select>
                  {errAction && (
                    <FormHelperText>
                      {errAction ? Messages.SummaryOverride.requiredAction : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Quantity"
                  name="quantity"
                  type="number"
                  value={manageSummaryOverrides.quantity}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: manageSummaryOverrides.action === "Override % Complete" || manageSummaryOverrides.action === "% Complete +/-" ? <InputAdornment position="end">%</InputAdornment> : <InputAdornment position="end"></InputAdornment>
                  }}
                  error={errActionText}
                  helperText={errActionText ? errMessageActionText : ""}
                  onBlur={(e) => {
                    setErractionText(handleErrorsActionText(e.target.value));
                  }}
                />
              </div>

              <div className="form-group mb-4">
                <TextField
                  id="outlined-textarea"
                  aria-label="maximum height"
                  placeholder="Notes...."
                  name="notes"
                  value={manageSummaryOverrides.notes}
                  onChange={handleInputChange}
                  multiline={true}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: { resize: "both" } }}
                />

              </div>

              <div className="form-group mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Submission Date"
                    inputFormat="MM/DD/YYYY"
                    value={manageSummaryOverrides.submissionDate}
                    onChange={(e) => { handleChange(e, 'SubmissionDate') }}
                    renderInput={(params) => <TextField {...params}
                      inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                      helperText={errSubmissionDate ? errMessageSubmissionDate : ""}
                      required
                      error={errSubmissionDate}
                      onBlur={(e) => {
                        setErrSubmissionDate(handleErrorsSubmissionDate(e.target.value));
                      }}
                    />
                    }

                  />
                </LocalizationProvider>
              </div>

            </div>
          ) : (
            <div className="loader custom-loader">
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary btn-icon btn-sm"
            onClick={resetSummaryOverrides}
          >
            Close <CloseIcon />
          </button>
          {
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={validatereSummaryOverrides} 
            >
              Add <PersonAddAlt1Icon />
            </button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};
