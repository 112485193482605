import * as React from "react";
import { ITimeEntryCrewMemberResponseModel } from "./ITimeEntry";
import { GridColDef, GridRowParams, GridRowsProp } from "@mui/x-data-grid";
import { DataGridPro, GridRowModel, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

export type IProps = {
    DialogTitle: string;
    Open: boolean;
    CrewMemberRowData: ITimeEntryCrewMemberResponseModel[];
    OnSave?: any | null;
    OnCancel?: any | null;
    OnChange: (event: any, id: number) => void;
    OnSearch: (value: string) => void;
    SelectedCrewMembers: number[];
}

function updateRowPosition(
    initialIndex: number,
    newIndex: number,
    rows: Array<GridRowModel>,
): Promise<any> {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                const rowsClone = [...rows];
                const row = rowsClone.splice(initialIndex, 1)[0];
                rowsClone.splice(newIndex, 0, row);
                resolve(rowsClone);
            },
            Math.random() * 500 + 100,
        ); // simulate network latency
    });
}

export const AddTimeEntryCrewMemberModalDialog: React.FunctionComponent<IProps> = (props) => {

    const [rows, setRows] = React.useState(props.CrewMemberRowData);
    let crewSnapShotArray: any[] = [];

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        loadData();
    }, [props]);

    const loadData = () => {
        crewSnapShotArray = [...props.CrewMemberRowData];
        crewSnapShotArray.sort((a, b) => {
            return b.isSnapshot ? (props.SelectedCrewMembers.indexOf(a.id) >= 0 && props.SelectedCrewMembers.indexOf(b.id) >= 0) ? (props.SelectedCrewMembers.indexOf(a.id) - props.SelectedCrewMembers.indexOf(b.id)) : 1 : -1;
        });
        crewSnapShotArray.sort((a, b) => {
            return (!b.isSnapshot && !a.isSnapshot) ? a.employeeName < b.employeeName ? -1 : 1 : 0;
        });
        setRows(crewSnapShotArray);
    }

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(false);
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            rows,
        );

        if (params.row.isSnapshot) {
            props.SelectedCrewMembers.splice(params.oldIndex, 1);
            props.SelectedCrewMembers.splice(params.targetIndex, 0, params.row.id);
        }
        setRows(newRows);
        setLoading(true);
    };

    React.useEffect(() => {
        setLoading(false);
    }, [true]);

    const searchCrewMember = (value: string) => {
        if (value.length > 2) {
            const filterData = props.CrewMemberRowData.filter(c => c.employeeName.toLowerCase().includes(value.toLocaleLowerCase()) ||
                c.employeeNumber.includes(value.toLocaleLowerCase()));
            setRows(filterData);
        } else {
            loadData();
        }
    }

    const columns: GridColDef[] = [
        {
            field: "employeeName",
            headerName: "Employee Name",
            width: 400
        },
        {
            field: "employeeNumber",
            headerName: "Employee ID",
            width: 185
        },
        {
            field: "isSnapshot",
            headerName: "",
            renderCell: (params) => (
                <Checkbox
                    checked={params.row?.isSnapshot}
                    disabled={params.row?.hours > 0}
                    onChange={(e) => props.OnChange(e, params.row?.id)}
                />
            )
        }
    ]

    return (
        <div>
            <Dialog
                open={props.Open}
                onClose={props.OnCancel}
                className="confirm-dialog addForm custom-modal timeentry-crewmember-modal"
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {props.DialogTitle}
                    <IconButton
                        aria-label="close"
                        onClick={props.OnCancel}
                        sx={{
                            position: "absolute",
                            right: 10,
                            top: 13,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className="content-min-height">
                    <div className="row">
                        <div className="col-12">
                            <div className="card user-detail-card modal-detail-card">
                                <div className="card-body">
                                    <div className="dataTables_wrapper">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 d-flex justify-content-end mb-2">
                                                <TextField
                                                    id="standard-bare"
                                                    variant="outlined"
                                                    placeholder="Search..."
                                                    onChange={(e) => {
                                                        searchCrewMember(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton>
                                                                <SearchOutlinedIcon />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid_wrapper autoheightcell">
                                        <DataGridPro
                                            rowSelection={false}
                                            rows={rows}
                                            isRowSelectable={(params: GridRowParams) => params.row.hours === 0}
                                            columns={columns}
                                            className="user-details-grid custom-details-grid modal-details-grid"
                                            pageSizeOptions={[5, 10, 25]}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } }
                                            }}
                                            rowReordering={true}
                                            onRowOrderChange={handleRowOrderChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        type="button"
                        className="btn btn-secondary btn-icon btn-sm me-3"
                        onClick={props.OnSave}
                    >
                        Save Changes
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}