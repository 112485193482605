import React from "react";
import { useEffect } from "react";
import { IActivityComplete, ISummaryReportModel, ISummaryReportRequest } from "../IReports";
import ReportServices from "../ReportsServices";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Loading } from "../../Shared/Loading";
import { DataGrid, LoadPanel } from "devextreme-react";
import ProjectServices from "../../ProjectDetail/ProjectServices";
import { IManageProjectModel } from "../../ProjectDetail/IProject";
import { Messages } from "../../Shared/constant";
import dayjs, { Dayjs } from 'dayjs';
import { Column, ColumnChooser, FilterRow, Format, Position, StateStoring, Summary, TotalItem, Scrolling, Paging, Pager } from "devextreme-react/data-grid";
import TaskIcon from '@mui/icons-material/Task';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import IsoIcon from '@mui/icons-material/Iso';

import { SummaryOverrideModalDialog } from "./SummaryOverridesModal";
import { ForecastOverrideModalDialog } from "./ForecastOverridesModal";
import { formatNumber } from "devextreme/localization";

const defaultValues: any = {
    activityId: 0, combinedName: "", actualProduction: 0, toDateProduction: 0, projectedProduction: 0, budgetProduction: 0, requiredProduction: 0, actualCrewRate: 0,
    toDateCrewRate: 0, projectedCrewRate: 0, budgetCrewRate: 0, dateValues: ""
};

export default function SummaryReport() {
    const [isLoadReportData, setIsLoadReportData] = React.useState<boolean>(false);
    const [projects, setProjects] = React.useState<IManageProjectModel[]>([]);
    const [manageProjectId, setManageProjectId] = React.useState<number>(0);
    const [manageStartDate, setManageStartDate] = React.useState<any>(null);
    const [manageEndDate, setManageEndDate] = React.useState<any>(null);
    const [errProjectCode, setErrProjectCode] = React.useState<boolean>(false);
    const [errMessageProjectCode, setErrMessageProjectCode] = React.useState("");
    const [errStartDate, setErrStartDate] = React.useState<boolean>(false);
    const [errEndDate, setErrEndDate] = React.useState<boolean>(false);
    const [errMessageStartDate, setErrMessageStartDate] = React.useState("");
    const [errMessageEndDate, setErrMessageEndDate] = React.useState("");
    const [manageReportData, setManageReportData] = React.useState<ISummaryReportModel[]>([]);
    const [openSummaryModaldialog, setOpenSummaryModal] = React.useState<boolean>(false);
    const [reloadModalPopup, setReloadModalPopup] = React.useState<boolean>(false);
    const [manageSummary, setManageSummary] = React.useState(defaultValues);
    const [manageForecast, setManageForecast] = React.useState(defaultValues);
    const [openForecastModaldialog, setOpenForecastModal] = React.useState<boolean>(false);
    let searchCombinedName = "";

    useEffect(() => {
        const prevMonday = getPreviousWeekDays(true);
        const prevSunday = getPreviousWeekDays(false);
        setManageStartDate(dayjs(prevMonday).format("YYYY-MM-DDThh:mm:ss"));
        setManageEndDate(dayjs(prevSunday).format("YYYY-MM-DDThh:mm:ss"));
        getProjects();
        setOpenSummaryModal(false);
    }, []);

    const getProjects = async () => {
        ProjectServices.GetProjectDetails(0).then((res) => {
            if (!res.hasErrors && res.value) {
                const localProjects = res.value.filter((x) => x.parent === null && x.isActive === true && x.isTracked === true);
                setProjects(localProjects);
            }
        });
    };

    const getSearchRecord = (value: string, words: string[]) => {
        var res = words.map((item) => value.indexOf(item) > -1);
        return res.indexOf(true) > -1;
    }

    const getReportData = async () => {
        setIsLoadReportData(true);
        const summaryReportRequest: ISummaryReportRequest = {
            projectId: manageProjectId,
            startDate: manageStartDate,
            endDate: manageEndDate
        };

        await ReportServices.GetSummaryReport(summaryReportRequest).then((res) => {
            if (!res.hasErrors && res.value) {
                const data = res.value;

                if (searchCombinedName === '') {
                    setManageReportData(data);
                } else {
                    const searchPlainText = searchCombinedName.replace(/\|/g,' ');
                    const searchWords = searchPlainText.split(' ');
                    const searchUniqueWords = searchWords.map(s => s.toLowerCase()).filter((value, index, current_value) => current_value.indexOf(value) === index && value !== '');
                    const searchData = data.filter(item => getSearchRecord(item.combinedName.toLowerCase(), searchUniqueWords) === true);
                    setManageReportData(searchData);
                }
                setIsLoadReportData(false);
            }
        });
    }

    const handleChange = (value: Dayjs | any, cntrlName: string) => {
        if (value === undefined || value === null || value === "" || value === 0) {
            switch (cntrlName) {
                case 'ProjectCode':
                    setErrProjectCode(true);
                    setErrMessageProjectCode(Messages.Reports.requiredProjectCode);
                    setManageProjectId(0);
                    break;
                case 'StartDate':
                    setErrStartDate(true);
                    setErrMessageStartDate(Messages.Reports.requiredStartDate);
                    setManageStartDate(null);
                    break;
                case 'EndDate':
                    setErrEndDate(true);
                    setErrMessageEndDate(Messages.Reports.requiredEndDate);
                    setManageEndDate(null);
                    break;
                case 'SearchCombinedName':
                    searchCombinedName = '';
                    break;
            }
        } else {
            switch (cntrlName) {
                case 'ProjectCode':
                    const pId = projects.filter(p => p.projectCode === value).map(p => p.id);
                    setManageProjectId(pId[0]);
                    break;
                case 'StartDate':
                    setManageStartDate(dayjs(value).format("YYYY-MM-DDThh:mm:ss"));
                    break;
                case 'EndDate':
                    setManageEndDate(dayjs(value).format("YYYY-MM-DDThh:mm:ss"));
                    break;
                case 'SearchCombinedName':
                    searchCombinedName = value;
            }
        }
    };

    const resetErrorState = async () => {
        setErrProjectCode(false);
        setErrStartDate(false);
        setErrEndDate(false);
    };

    const loadSummaryReport = async () => {
        resetErrorState();
        let isValid = true;
        if (manageProjectId === 0) {
            setErrProjectCode(true);
            setErrMessageProjectCode(Messages.Reports.requiredProjectCode);
            isValid = false;
        }

        if (manageStartDate !== null && manageStartDate.toLowerCase() === 'invalid date') {
            setErrStartDate(true);
            setErrMessageStartDate(Messages.Reports.invalidStartDate);
            isValid = false;
        }

        if (manageStartDate !== null && manageEndDate === null) {
            setErrEndDate(true);
            setErrMessageEndDate(Messages.Reports.requiredEndDate);
            isValid = false;
        }

        if (manageEndDate !== null && manageEndDate.toLowerCase() === 'invalid date') {
            setErrEndDate(true);
            setErrMessageEndDate(Messages.Reports.invalidEndDate);
            isValid = false;
        }

        if (manageEndDate !== null && manageStartDate === null) {
            setErrStartDate(true);
            setErrMessageStartDate(Messages.Reports.requiredStartDate);
            isValid = false;
        }

        if (manageStartDate !== null && manageEndDate !== null) {
            if (manageEndDate < manageStartDate) {
                setErrEndDate(true);
                setErrMessageEndDate(Messages.Reports.dateRangeValidation);
                isValid = false;
            }
        }

        if (isValid) {
            getReportData();
        }
    }

    const customizeText = (cellinfo) => {
        if (cellinfo.value === null || cellinfo.value === undefined) {
            return "-";
        } else {
            return cellinfo.value;
        }
    }

    const renderTitleHeader = (data) => {
        return (<span className="word-pre-wrap">{data.column.caption.replace(/ /g, "\r\n")}</span>);
    }

    const onCellPrepared = (e) => {
        // disabled tr/td when activity is completed..
        if (e.rowType === "data") {
            if (e.data.isComplete) {
                const classes = ['budget-section', 'todate-section', 'datafilter-section', 'remaining-section', 'projected-section',
                    'forecasted-section', 'required-section', 'production-section']

                classes.forEach(cls => {
                    if (e.cellElement.classList.contains(cls)) {
                        e.cellElement.classList.remove(cls);
                    }
                });
                e.cellElement.classList.add('disabled-cell');
            }
        }

        // set color for positive & negative numbers in data row
        if (e.rowType === "data" && e.column.dataType === 'number') {
            if (e.value === 0) {
                e.cellElement.textContent = e.cellElement.textContent.includes('$') ? "$ -" : "-";
            }

            if (e.column.dataField === "toDateOverUnder" || e.column.dataField === "actualOverUnder" || e.column.dataField === "projectedOverUnderDollar" ||
                e.column.dataField === "projectedOverUnderMhrs" || e.column.dataField === "forecastedOverUnderDollar" || e.column.dataField === "forecastedOverUnderMhrs" ||
                e.column.dataField === "requiredOverUnderDollar" || e.column.dataField === "requiredOverUnderMhrs" || e.column.dataField === "productionDelta" ||
                e.column.dataField === "remainingMhrs") {
                const existingClass = e.cellElement.className;
                if (e.value < 0) {
                    e.cellElement.className = existingClass + " negative-number";
                } else if (e.value > 0) {
                    if (e.column.dataField !== "remainingMhrs") {
                        e.cellElement.className = existingClass + " positive-number";
                    }
                } else {
                    e.cellElement.className = existingClass;
                }
            }
        }

        // set color for positive & negative numbers in total row
        if (e.rowType === "totalFooter" && e.column.dataType === 'number') {
            const eValue = e.summaryItems.length > 0 ? e.summaryItems[0].value : 0;
            if (eValue === 0) {
                e.cellElement.textContent = e.cellElement.textContent.includes('$') ? "$ -" : "-";
            }

            if (e.column.dataField === "toDateOverUnder" || e.column.dataField === "actualOverUnder" || e.column.dataField === "projectedOverUnderDollar" ||
                e.column.dataField === "projectedOverUnderMhrs" || e.column.dataField === "forecastedOverUnderDollar" || e.column.dataField === "forecastedOverUnderMhrs" ||
                e.column.dataField === "requiredOverUnderDollar" || e.column.dataField === "requiredOverUnderMhrs" || e.column.dataField === "productionDelta" ||
                e.column.dataField === "remainingMhrs") {

                if (e.cellElement.childNodes.length > 0) {
                    const existingClass = e.cellElement.childNodes[0].className;
                    if (eValue < 0) {
                        e.cellElement.childNodes[0].className = existingClass + " negative-number";
                    } else if (eValue > 0) {
                        if (e.column.dataField !== "remainingMhrs") {
                            e.cellElement.childNodes[0].className = existingClass + " positive-number";
                        }
                    } else {
                        e.cellElement.childNodes[0].className = existingClass;
                    }
                }

            }
        }

        // if (e.rowType === "data" && e.column.dataField === "activityId") {
        //     if (e.column.name === "Complete Activity") {
        //         console.log(e.column.name);
        //     } else if (e.column.name === "Update Quantity") {
        //         console.log(e.column.name);
        //     } else {
        //         console.log(e.column.name);
        //     }
        // }
    }

    const completeActivity = async (e, id) => {
        e.preventDefault();
        const activityComplete: IActivityComplete = {
            id: +id,
            createdBy: +window.UserId
        };
        await ReportServices.UpdateActiivtyComplete(activityComplete).then((res) => {
            if (!res.hasErrors && res.value) {
                getReportData();
            }
        });
    }

    //#region Summary modal 
    const openSummaryOverride = async (e, data) => {
        e.preventDefault();
        setManageSummary({
            ...manageSummary,
            activityId: data.activityId,
            combinedName: data.combinedName
        });
        setOpenSummaryModal(true);
        setReloadModalPopup(true);
    }

    const openSummaryModalEdit = () => {
        setIsLoadReportData(false);
    };

    const closeSummaryModal = () => {
        setOpenSummaryModal(false);
    };

    const handlecloseSummaryModal = () => {
        setOpenSummaryModal(false);
        getReportData();
    };
    //#endregion

    //#region Forecast modal 
    const openForecastOverride = async (e, data) => {
        e.preventDefault();
        const tempStartDate = new Date(manageStartDate);
        const tempEndDate = new Date(manageEndDate);
        setManageForecast({
            ...manageForecast,
            activityId: data.activityId,
            combinedName: data.combinedName,
            actualProduction: data.actualProduction,
            toDateProduction: data.toDateProduction,
            projectedProduction: data.projectedProduction,
            budgetProduction: data.budgetProduction,
            requiredProduction: data.requiredProduction,
            actualCrewRate: data.actualCrewRate,
            toDateCrewRate: data.toDateCrewRate,
            projectedCrewRate: data.projectedCrewRate,
            budgetCrewRate: data.budgetCrewRate,
            dateValues: "between (" + `${('0' + (tempStartDate.getMonth() + 1)).slice(-2)}/${('0' + tempStartDate.getDate()).slice(-2)}/${tempStartDate.getFullYear()})` + " and "
                + `(${('0' + (tempEndDate.getMonth() + 1)).slice(-2)}/${('0' + tempEndDate.getDate()).slice(-2)}/${tempEndDate.getFullYear()})`
        });
        setOpenForecastModal(true);
        setReloadModalPopup(true);
    }

    const openForecastModalEdit = () => {
        setIsLoadReportData(false);
    };

    const closeForecastModal = () => {
        setOpenForecastModal(false);
    };

    const handlecloseForecastModal = () => {
        setOpenForecastModal(false);
        getReportData();
    };
    //#endregion 

    const cellActivityComplete = (data: any) => {
        if (!data.data.isComplete) {
            return (<Button className="activity-notcomplete summary-icon" onClick={(e) => completeActivity(e, data.value)}><TaskIcon /></Button>);
        } else {
            return (<Button className="activity-notcomplete summary-icon" title="Activity Complete" onClick={(e) => completeActivity(e, data.value)}><TaskIcon /></Button>);
        }
    }

    const cellUpdateQuantity = (data: any) => {
        return (<Button className="activity-notcomplete summary-icon" title="Update Qty" onClick={(e) => openSummaryOverride(e, data.data)}><IsoIcon /></Button>);
    }

    const cellUpdateForecast = (data: any) => {
        return (<Button className="activity-notcomplete summary-icon" title="Update Forecast" onClick={(e) => openForecastOverride(e, data.data)}><LineAxisIcon /></Button>);
    }

    const getPreviousWeekDays = (isMonday: boolean) => {
        const currentDate = new Date();
        const date = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        const day = date.getDay();
        const prevMonday = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        if (day === 0) {
            prevMonday.setDate(date.getDate() - 6);
        }
        else {
            prevMonday.setDate(date.getDate() - (day - 1));
        }

        return isMonday ? prevMonday : prevMonday.setDate(prevMonday.getDate() + 6);
    }

    const calculateCustomSummary = (options: any) => {
        if (options.name === "unit") {
            if (options.summaryProcess === "start") {
                options.totalValue = { unit: "", optionDefault: 0, optionLength: 1 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.optionDefault += options.totalValue.optionLength;
                options.totalValue.unit = options.value.unit;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = options.totalValue.optionDefault === 1 ? options.totalValue.unit : "Units";
            }
        }

        if (options.name === "units") {
            if (options.summaryProcess === "start") {
                options.totalValue = { units: "", optionDefault: 0, optionLength: 1 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.optionDefault += options.totalValue.optionLength;
                options.totalValue.units = options.value.units;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = options.totalValue.optionDefault === 1 ? options.totalValue.units : "Units";
            }
        }

        if (options.name === "budgetQty") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetQty: 0 };
            }

            if (options.summaryProcess === "calculate") {
                let qty = 0;
                qty = options.value.unit === 'wks' ? +options.value.budgetQty.toFixed(1) : +options.value.budgetQty.toFixed();
                options.totalValue.budgetQty += qty;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = options.totalValue.budgetQty;
            }
        }

        if (options.name === "budgetDollarPerUnits") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetDollar: 0, budgetQty: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.budgetDollar += options.value.budgetDollar;
                options.totalValue.budgetQty += options.value.unit === 'wks' ? +options.value.budgetQty.toFixed(1) : +options.value.budgetQty.toFixed();
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = isFinite(options.totalValue.budgetDollar / options.totalValue.budgetQty) ? (options.totalValue.budgetDollar / options.totalValue.budgetQty) : 0;
            }
        }

        if (options.name === "budgetProduction") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetHours: 0, budgetQty: 0, budgetUnit: "", optionDefault: 0, optionLength: 1 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.budgetHours += options.value.budgetHours;
                options.totalValue.budgetQty += options.value.unit === 'wks' ? +options.value.budgetQty.toFixed(1) : +options.value.budgetQty.toFixed();
                options.totalValue.optionDefault += options.totalValue.optionLength;
                options.totalValue.budgetUnit = options.value.unit;
            }

            if (options.summaryProcess === "finalize") {
                if (options.totalValue.unit === 'wks' || options.totalValue.unit === 'ea' || options.totalValue.unit === '%') {
                    const budProd = isFinite(options.totalValue.budgetQty / options.totalValue.budgetHours) ? (options.totalValue.budgetQty / options.totalValue.budgetHours) : 0;
                    options.totalValue = isFinite(1 / budProd) ? (1 / budProd) : 0;
                } else {
                    options.totalValue = isFinite(options.totalValue.budgetQty / options.totalValue.budgetHours) ? (options.totalValue.budgetQty / options.totalValue.budgetHours) : 0;
                }
            }
        }

        if (options.name === "budgetCrewRate") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetDollar: 0, budgetHours: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.budgetDollar += options.value.budgetDollar;
                options.totalValue.budgetHours += options.value.budgetHours;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = isFinite(options.totalValue.budgetDollar / options.totalValue.budgetHours) ? (options.totalValue.budgetDollar / options.totalValue.budgetHours) : 0;
            }
        }

        if (options.name === "toDateQty") {
            if (options.summaryProcess === "start") {
                options.totalValue = { toDateQty: 0 };
            }

            if (options.summaryProcess === "calculate") {
                let qty = 0;
                qty = options.value.unit === 'wks' ? +options.value.toDateQty.toFixed(1) : +options.value.toDateQty.toFixed();
                options.totalValue.toDateQty += qty;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = options.totalValue.toDateQty;
            }
        }

        if (options.name === "toDatePercentageCompleteDollar") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetDollar: 0, toDateDollar: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.budgetDollar += options.value.budgetDollar;
                options.totalValue.toDateDollar += options.value.toDateDollar;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = (isFinite(options.totalValue.toDateDollar / options.totalValue.budgetDollar) ? (options.totalValue.toDateDollar / options.totalValue.budgetDollar) : 0) * 100;
            }
        }

        if (options.name === "toDatePercentageCompleteMhrs") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetHours: 0, toDateHours: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.budgetHours += options.value.budgetHours;
                options.totalValue.toDateHours += options.value.toDateHours;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = (isFinite(options.totalValue.toDateHours / options.totalValue.budgetHours) ? (options.totalValue.toDateHours / options.totalValue.budgetHours) : 0) * 100;
            }
        }

        if (options.name === "toDateDollarPerUnit") {
            if (options.summaryProcess === "start") {
                options.totalValue = { toDateDollar: 0, toDateQty: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.toDateDollar += options.value.toDateDollar;
                options.totalValue.toDateQty += options.value.unit === 'wks' ? +options.value.toDateQty.toFixed(1) : +options.value.toDateQty.toFixed();
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = isFinite(options.totalValue.toDateDollar / options.totalValue.toDateQty) ? (options.totalValue.toDateDollar / options.totalValue.toDateQty) : 0;
            }
        }

        if (options.name === "toDateProduction") {
            if (options.summaryProcess === "start") {
                options.totalValue = { toDateHours: 0, toDateQty: 0, toDateUnit: "", optionDefault: 0, optionLength: 1 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.toDateHours += options.value.toDateHours;
                options.totalValue.toDateQty += options.value.unit === 'wks' ? +options.value.toDateQty.toFixed(1) : +options.value.toDateQty.toFixed();
                options.totalValue.optionDefault += options.totalValue.optionLength;
                options.totalValue.toDateUnit = options.value.unit;
            }

            if (options.summaryProcess === "finalize") {
                if (options.totalValue.unit === 'wks' || options.totalValue.unit === 'ea' || options.totalValue.unit === '%') {
                    const toDateProd = isFinite(options.totalValue.toDateQty / options.totalValue.toDateHours) ? (options.totalValue.toDateQty / options.totalValue.toDateHours) : 0;
                    options.totalValue = isFinite(1 / toDateProd) ? (1 / toDateProd) : 0;
                } else {
                    options.totalValue = isFinite(options.totalValue.toDateQty / options.totalValue.toDateHours) ? (options.totalValue.toDateQty / options.totalValue.toDateHours) : 0;
                }
            }
        }

        if (options.name === "toDateCrewRate") {
            if (options.summaryProcess === "start") {
                options.totalValue = { toDateDollar: 0, toDateHours: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.toDateDollar += options.value.toDateDollar;
                options.totalValue.toDateHours += options.value.toDateHours;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = isFinite(options.totalValue.toDateDollar / options.totalValue.toDateHours) ? (options.totalValue.toDateDollar / options.totalValue.toDateHours) : 0;
            }
        }

        if (options.name === "actualQty") {
            if (options.summaryProcess === "start") {
                options.totalValue = { actualQty: 0 };
            }

            if (options.summaryProcess === "calculate") {
                let qty = 0;
                qty = options.value.unit === 'wks' ? +options.value.actualQty.toFixed(1) : +options.value.actualQty.toFixed();
                options.totalValue.actualQty += qty;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = options.totalValue.actualQty;
            }
        }

        if (options.name === "actualPercentageCompleteDollar") {
            if (options.summaryProcess === "start") {
                options.totalValue = { budgetDollar: 0, actualDollar: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.budgetDollar += options.value.budgetDollar;
                options.totalValue.actualDollar += options.value.actualDollar;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = (isFinite(options.totalValue.actualDollar / options.totalValue.budgetDollar) ? (options.totalValue.actualDollar / options.totalValue.budgetDollar) : 0) * 100;
            }
        }

        if (options.name === "actualDollarPerUnit") {
            if (options.summaryProcess === "start") {
                options.totalValue = { actualDollar: 0, actualQty: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.actualDollar += options.value.actualDollar;
                options.totalValue.actualQty += options.value.unit === 'wks' ? +options.value.actualQty.toFixed(1) : +options.value.actualQty.toFixed();
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = isFinite(options.totalValue.actualDollar / options.totalValue.actualQty) ? (options.totalValue.actualDollar / options.totalValue.actualQty) : 0;
            }
        }

        if (options.name === "actualProduction") {
            if (options.summaryProcess === "start") {
                options.totalValue = { actualHours: 0, actualQty: 0, actualUnit: "", optionDefault: 0, optionLength: 1 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.actualHours += options.value.actualHours;
                options.totalValue.actualQty += options.value.unit === 'wks' ? +options.value.actualQty.toFixed(1) : +options.value.actualQty.toFixed();
                options.totalValue.optionDefault += options.totalValue.optionLength;
                options.totalValue.actualUnit = options.value.unit;
            }

            if (options.summaryProcess === "finalize") {
                if (options.totalValue.unit === 'wks' || options.totalValue.unit === 'ea' || options.totalValue.unit === '%') {
                    const actualProd = isFinite(options.totalValue.actualQty / options.totalValue.actualHours) ? (options.totalValue.actualQty / options.totalValue.actualHours) : 0;
                    options.totalValue = isFinite(1 / actualProd) ? (1 / actualProd) : 0;
                } else {
                    options.totalValue = isFinite(options.totalValue.actualQty / options.totalValue.actualHours) ? (options.totalValue.actualQty / options.totalValue.actualHours) : 0;
                }
            }
        }

        if (options.name === "actualCrewRate") {
            if (options.summaryProcess === "start") {
                options.totalValue = { actualDollar: 0, actualHours: 0 };
            }

            if (options.summaryProcess === "calculate") {
                options.totalValue.actualDollar += options.value.actualDollar;
                options.totalValue.actualHours += options.value.actualHours;
            }

            if (options.summaryProcess === "finalize") {
                options.totalValue = isFinite(options.totalValue.actualDollar / options.totalValue.actualHours) ? (options.totalValue.actualDollar / options.totalValue.actualHours) : 0;
            }
        }
    }

    const renderQtyCell = (cellinfo) => {
        if (cellinfo.value !== 0) {
            if (cellinfo.row.data.unit === 'wks') {
                return formatNumber(cellinfo.value, "###,##0.0");
            } else {
                return formatNumber(cellinfo.value, "###,##0");
            }
        } else {
            return "";
        }
    }

    const renderDollarPerUnitCell = (cellinfo) => {
        if (cellinfo.value !== 0) {
            if (cellinfo.row.data.unit === 'wks') {
                return formatNumber(cellinfo.value, "$###,##0");
            } else {
                return formatNumber(cellinfo.value, "$###,##0.00")
            }
        } else {
            return "";
        }
    }

    const cellRowNumber = (cellinfo) => {
        return cellinfo.rowIndex + 1;
    }

    return (
        <>
            <div className="page-title-box">
                <h1 className="page-title">Work Package Reporting</h1>
            </div>

            <div className="card user-detail-card pages-detail-card workpackage-reporting-card">
                <div className="card-body">
                    <div className="dataTables_wrapper">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 d-flex mb-2 gap-3">
                                <Autocomplete
                                    limitTags={2}
                                    id="drpProjects"
                                    options={projects.map((option) => option.projectCode)}
                                    filterSelectedOptions

                                    sx={{ minWidth: 300 }}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleChange(newValue, "ProjectCode");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Project Number"
                                            required
                                            helperText={errProjectCode ? errMessageProjectCode : ""}
                                            error={errProjectCode}
                                            InputProps={{
                                                ...params.InputProps,
                                                type: "search",
                                                placeholder: "Project",
                                            }} />
                                    )} />
                                
                                <TextField
                                    id="standard-bare"
                                    variant="outlined"
                                    placeholder="Search Combined Name"
                                    className="me-5"
                                    onChange={(e) => {
                                        handleChange(e.target.value, 'SearchCombinedName');
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="MM/DD/YYYY"
                                        className="datafilter-section-date"
                                        disableFuture={false}
                                        showDaysOutsideCurrentMonth={true}
                                        value={manageStartDate}
                                        onChange={(e) => { handleChange(e, 'StartDate') }}
                                        renderInput={(params) => <TextField {...params}
                                            inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                                            helperText={errStartDate ? errMessageStartDate : ""}
                                            //required
                                            error={errStartDate}
                                            onBlur={(e) => {
                                                //handleErrors(e.target.value, 'StartDate');
                                            }} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="MM/DD/YYYY"
                                        className="datafilter-section-date"
                                        disableFuture={false}
                                        showDaysOutsideCurrentMonth={true}
                                        value={manageEndDate}
                                        onChange={(e) => { handleChange(e, 'EndDate') }}
                                        renderInput={(params) => <TextField {...params}
                                            inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }}
                                            //required
                                            helperText={errEndDate ? errMessageEndDate : ""}
                                            error={errEndDate}
                                            onBlur={(e) => {
                                                //handleErrors(e.target.value, 'EndDate');
                                            }} />}
                                    />
                                </LocalizationProvider>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-icon btn-sm"
                                        onClick={loadSummaryReport}
                                    >
                                        View Report
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid_wrapper autoheightcell">
                        <Box
                            sx={{
                                height: 500,
                                minHeight: 200,
                                width: "100%",
                                "& .actions": {
                                    color: "text.secondary",
                                },
                                "& .textPrimary": {
                                    color: "text.primary",
                                },
                            }}
                        >
                            {!isLoadReportData ? (
                                <DataGrid
                                    className="summary-report"
                                    dataSource={manageReportData}
                                    showBorders={true}
                                    onCellPrepared={onCellPrepared}
                                    wordWrapEnabled={true}
                                    height={635}
                                >
                                    <Column 
                                        width={38}
                                        cellRender={cellRowNumber}
                                        caption=""
                                        fixed={true}
                                        fixedPosition="left"
                                        alignment="center"
                                        allowFiltering={false}
                                        allowHiding={false}
                                        showInColumnChooser={false}
                                    />
                                    <Column
                                        dataField="activityId"
                                        name="Complete Activity"
                                        caption=""
                                        dataType="object"
                                        width={35}
                                        fixed={true}
                                        fixedPosition="left"
                                        cellRender={cellActivityComplete}
                                        alignment="center"
                                        allowFiltering={false}
                                        allowHiding={false}
                                        showInColumnChooser={false}
                                    />
                                    <Column
                                        dataField="activityId"
                                        name="Update Quantity"
                                        caption=""
                                        dataType="object"
                                        width={35}
                                        fixed={true}
                                        fixedPosition="left"
                                        cellRender={cellUpdateQuantity}
                                        alignment="center"
                                        allowFiltering={false}
                                        allowHiding={false}
                                        showInColumnChooser={false}
                                    />
                                    <Column
                                        dataField="activityId"
                                        name="Update forecast"
                                        caption=""
                                        dataType="object"
                                        width={35}
                                        fixed={true}
                                        fixedPosition="left"
                                        cellRender={cellUpdateForecast}
                                        alignment="center"
                                        allowFiltering={false}
                                        allowHiding={false}
                                        showInColumnChooser={false}
                                    />
                                    <Column
                                        dataField="costCode"
                                        caption="Cost Code"
                                        dataType="string"
                                        width={90}
                                        fixed={true}
                                        fixedPosition="left"
                                        allowFiltering={true}
                                    />
                                    <Column
                                        dataField="costCodeTitle"
                                        caption="Cost Code Title"
                                        dataType="string"
                                        width={120}
                                        fixed={true}
                                        fixedPosition="left"
                                        allowFiltering={true}
                                    />
                                    <Column
                                        dataField="combinedName"
                                        caption="Combined Name"
                                        dataType="string"
                                        width={200}
                                        fixed={true}
                                        fixedPosition="left"
                                        allowFiltering={true}
                                    />
                                    <Column
                                        dataField="group"
                                        caption="Group"
                                        dataType="string"
                                        width={90}
                                        fixed={true}
                                        fixedPosition="left"
                                        visible={false}
                                        allowFiltering={true}
                                        allowHiding={true}
                                        showInColumnChooser={true}
                                    />
                                    <Column
                                        dataField="scope"
                                        caption="Scope"
                                        dataType="string"
                                        width={100}
                                        fixed={true}
                                        fixedPosition="left"
                                        visible={false}
                                        allowFiltering={true}
                                        allowHiding={true}
                                        showInColumnChooser={true}
                                    />
                                    <Column
                                        dataField="activity"
                                        caption="Activity"
                                        dataType="string"
                                        width={90}
                                        fixed={true}
                                        fixedPosition="left"
                                        visible={false}
                                        allowFiltering={true}
                                        allowHiding={true}
                                        showInColumnChooser={true}
                                    />
                                    <Column
                                        dataField="area"
                                        caption="Area/ Lvl"
                                        dataType="string"
                                        width={90}
                                        fixed={true}
                                        fixedPosition="left"
                                        visible={false}
                                        allowFiltering={true}
                                        allowHiding={true}
                                        showInColumnChooser={true}
                                    />
                                    <Column
                                        dataField="budgetDollar"
                                        caption="Budget $"
                                        dataType="number"
                                        cssClass="budget-section"
                                        format="currency"
                                        width={130}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="budgetHours"
                                        caption="Budget Hours"
                                        dataType="number"
                                        cssClass="budget-section"
                                        format="###,##0"
                                        width={80}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="budgetQty"
                                        caption="Budget Qty"
                                        dataType="number"
                                        cssClass="budget-section"
                                        //format="###,##0" //Unit=wks then "###,##0.0" else "###,##0"
                                        width={70}
                                        headerCellRender={renderTitleHeader}
                                        cellRender={(e) => renderQtyCell(e)}
                                    />
                                    <Column
                                        dataField="unit"
                                        name="Unit"
                                        caption="Budget Unit"
                                        dataType="string"
                                        cssClass="budget-section"
                                        width={65}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="budgetDollarPerUnits"
                                        caption="Budget $/Units"
                                        dataType="number"
                                        cssClass="budget-section"
                                        width={130}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="budgetProduction"
                                        caption="Budget Production"
                                        dataType="number"
                                        cssClass="budget-section"
                                        format="###,##0.00"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="Units"
                                        caption="Budget Units"
                                        dataType="string"
                                        cssClass="budget-section"
                                        width={65}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="budgetCrewRate"
                                        caption="Budget Crew&nbsp;Rate"
                                        dataType="number"
                                        cssClass="budget-section"
                                        width={110}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="toDateDollar"
                                        caption="To-Date $"
                                        dataType="number"
                                        cssClass="todate-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDatePercentageCompleteDollar"
                                        caption="To-Date %&nbsp;Complete&nbsp;$"
                                        dataType="number" //needs to be a number for filter purposes
                                        cssClass="todate-section"
                                        format="###,##0'%'"
                                        width={105}
                                        //customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDateHours"
                                        caption="To-Date Hours"
                                        dataType="number"
                                        cssClass="todate-section"
                                        format="###,##0"
                                        width={80}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDatePercentageCompleteMhrs"
                                        caption="To-Date %&nbsp;Complete&nbsp;Mhrs"
                                        dataType="number" //needs to be a number for filter purposes
                                        cssClass="todate-section"
                                        format="###,##0'%'"
                                        width={130}
                                        //customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDateQty"
                                        caption="To-Date Qty"
                                        dataType="number"
                                        cssClass="todate-section"
                                        //format="###,##0" //Unit=wks then "###,##0.0" else "###,##0"
                                        width={70}
                                        headerCellRender={renderTitleHeader}
                                        cellRender={(e) => renderQtyCell(e)}
                                    />
                                    <Column
                                        dataField="unit"
                                        name="To-Date Unit"
                                        caption="To-Date Unit"
                                        dataType="string"
                                        cssClass="todate-section"
                                        width={70}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDatePercentageCompleteQty"
                                        caption="To-Date %&nbsp;Complete&nbsp;Qty"
                                        dataType="number" //needs to be a number for filter purposes
                                        cssClass="todate-section"
                                        format="###,##0'%'"
                                        width={120}
                                        //customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDateDollarPerUnit"
                                        caption="To-Date $/Unit"
                                        dataType="number" //Unit=wks then "###,##0" else "###,##0.00"
                                        cssClass="todate-section"
                                        width={100}
                                        headerCellRender={renderTitleHeader}
                                        cellRender={(e) => renderDollarPerUnitCell(e)}
                                    />
                                    <Column
                                        dataField="toDateProduction"
                                        caption="To-Date Production"
                                        dataType="number"
                                        cssClass="todate-section"
                                        format="###,##0.00"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="To-Date Units"
                                        caption="To-Date Units"
                                        dataType="string"
                                        cssClass="todate-section"
                                        width={70}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDateOverUnder"
                                        caption="To-Date (Over)/Under"
                                        dataType="number"
                                        cssClass="todate-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="toDateCrewRate"
                                        caption="To-Date Crew&nbsp;Rate"
                                        dataType="number"
                                        cssClass="todate-section"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="actualDollar"
                                        caption="Actual $"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualPercentageCompleteDollar"
                                        caption="Actual %&nbsp;Complete&nbsp;$"
                                        dataType="number" //needs to be a number for filter purposes
                                        cssClass="datafilter-section"
                                        format="###,##0'%'"
                                        width={105}
                                        //customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualHours"
                                        caption="Actual Hours"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        width={80}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualPercentageCompleteMhrs"
                                        caption="Actual %&nbsp;Complete&nbsp;Mhrs"
                                        dataType="number" //needs to be a number for filter purposes
                                        cssClass="datafilter-section"
                                        format="###,##0'%'"
                                        width={130}
                                        //customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualQty"
                                        caption="Actual Qty"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        //format="###,##0" //Unit=wks then "###,##0.0" else "###,##0"
                                        width={70}
                                        headerCellRender={renderTitleHeader}
                                        cellRender={(e) => renderQtyCell(e)}
                                    />
                                    <Column
                                        dataField="unit"
                                        name="Data Filter Unit"
                                        caption="Actual Unit"
                                        dataType="string"
                                        cssClass="datafilter-section"
                                        width={65}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualPercentageCompleteQty"
                                        caption="Actual %&nbsp;Complete&nbsp;Qty"
                                        dataType="number" //needs to be a number for filter purposes
                                        cssClass="datafilter-section"
                                        format="###,##0'%'"
                                        width={120}
                                        //customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualDollarPerUnit"
                                        caption="Actual $/Unit"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        width={100}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="actualProduction"
                                        caption="Actual Production"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        format="###,##0.00"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="Actual Units"
                                        caption="Actual Units"
                                        dataType="string"
                                        cssClass="datafilter-section"
                                        width={60}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualOverUnder"
                                        caption="Actual (Over)/Under"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="actualCrewRate"
                                        caption="Actuals Crew&nbsp;Rate"
                                        dataType="number"
                                        cssClass="datafilter-section"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="remainingDollar"
                                        caption="Remaining $"
                                        dataType="number"
                                        cssClass="remaining-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="remainingMhrs"
                                        caption="Remaining Mhrs"
                                        dataType="number"
                                        cssClass="remaining-section"
                                        format="###,##0;(###,##0)"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="remainingQty"
                                        caption="Remaining Qty"
                                        dataType="number"
                                        cssClass="remaining-section"
                                        format="###,##0"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="unit"
                                        name="Remaining Unit"
                                        caption="Remaining Unit"
                                        dataType="string"
                                        cssClass="remaining-section"
                                        width={85}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="projectedProduction"
                                        caption="Projected Production"
                                        dataType="number"
                                        cssClass="projected-section"
                                        format="###,##0.00"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="Projected Units"
                                        caption="Projected Units"
                                        dataType="string"
                                        cssClass="projected-section"
                                        width={80}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="projectedFinalDollar"
                                        caption="Projected Final&nbsp;$"
                                        dataType="number"
                                        cssClass="projected-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="projectedOverUnderDollar"
                                        caption="Projected (Over)/Under&nbsp;$"
                                        dataType="number"
                                        cssClass="projected-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="projectedFinalMhrs"
                                        caption="Projected Final&nbsp;Mhrs"
                                        dataType="number"
                                        cssClass="projected-section"
                                        format="###,##0"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="projectedOverUnderMhrs"
                                        caption="Projected (Over)/Under&nbsp;Mhrs"
                                        dataType="number"
                                        cssClass="projected-section"
                                        format="###,##0;(###,##0)"
                                        width={140}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="projectedCrewRate"
                                        caption="Projected Crew&nbsp;Rate"
                                        dataType="number"
                                        cssClass="projected-section"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="forecastedProduction"
                                        caption="Forecasted Production"
                                        dataType="number"
                                        cssClass="forecasted-section"
                                        format="###,##0.00"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="Forecated Units"
                                        caption="Forecasted Units"
                                        dataType="string"
                                        cssClass="forecasted-section"
                                        width={85}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="forecastedFinalDollar"
                                        caption="Forecasted Final&nbsp;$"
                                        dataType="number"
                                        cssClass="forecasted-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="forecastedOverUnderDollar"
                                        caption="Forecasted (Over)/Under&nbsp;$"
                                        dataType="number"
                                        cssClass="forecasted-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="forecastedFinalHrs"
                                        caption="Forecasted Final&nbsp;Hrs"
                                        dataType="number"
                                        cssClass="forecasted-section"
                                        format="###,##0"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="forecastedOverUnderMhrs"
                                        caption="Forecasted (Over)/Under&nbsp;Mhrs"
                                        dataType="number"
                                        cssClass="forecasted-section"
                                        format="###,##0;(###,##0)"
                                        width={140}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="forecastedCrewRate"
                                        caption="Forecasted Crew&nbsp;Rate"
                                        dataType="number"
                                        cssClass="forecasted-section"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    >
                                        <Format type="currency" precision={2}></Format>
                                    </Column>
                                    <Column
                                        dataField="requiredProduction"
                                        caption="Required Production"
                                        dataType="number"
                                        cssClass="required-section"
                                        format="###,##0.00"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="Required Units"
                                        caption="Required Units"
                                        dataType="string"
                                        cssClass="required-section"
                                        width={75}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="finalDollar"
                                        caption="Required Final&nbsp;$"
                                        dataType="number"
                                        cssClass="required-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="requiredOverUnderDollar"
                                        caption="Required (Over)/Under&nbsp;$"
                                        dataType="number"
                                        cssClass="required-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="finalHrs"
                                        caption="Required Final&nbsp;Hrs"
                                        cssClass="required-section"
                                        dataType="number"
                                        width={80}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="requiredOverUnderMhrs"
                                        caption="Required (Over)/Under&nbsp;Mhrs"
                                        dataType="number"
                                        cssClass="required-section"
                                        format="###,##0;(###,##0)"
                                        width={140}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="productionDelta"
                                        caption="Production Delta"
                                        dataType="number"
                                        cssClass="production-section"
                                        format="###,##0.00;(###,##0.00)"
                                        width={90}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="units"
                                        name="Production Units"
                                        caption="Production Units"
                                        dataType="string"
                                        cssClass="production-section"
                                        width={90}
                                        customizeText={customizeText}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="finalDollarDelta"
                                        caption="Production Final&nbsp;$&nbsp;Delta"
                                        dataType="number"
                                        cssClass="production-section"
                                        format="currency"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <Column
                                        dataField="finalMhrDelta"
                                        caption="Production Final&nbsp;Mhr&nbsp;Delta"
                                        dataType="number"
                                        cssClass="production-section"
                                        width={120}
                                        headerCellRender={renderTitleHeader}
                                    />
                                    <FilterRow visible={true} />
                                    <LoadPanel visible={false} />
                                    <StateStoring enabled={true} type="localStorage" storageKey="reportStorage" />
                                    <Scrolling />
                                    <Paging defaultPageSize={50} />
                                    <Pager
                                        visible={true}
                                        displayMode="full"
                                        showPageSizeSelector={true}
                                        showInfo={false}
                                        showNavigationButtons={false} />
                                    <ColumnChooser enabled={true} mode="select" height={450}>
                                        <Position
                                            my="right top"
                                            at="right bottom"
                                            of=".dx-datagrid-column-chooser-button"
                                        />

                                        {/* <ColumnChooserSearch
                                            enabled={true}
                                            editorOptions={{ placeholder: 'Search column' }} /> */}

                                    </ColumnChooser>
                                    <Summary calculateCustomSummary={calculateCustomSummary}>
                                        <TotalItem
                                            column="budgetDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="budgetHours"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="budgetQty"
                                            summaryType="custom"
                                            valueFormat="###,##0.0"
                                            displayFormat="{0}"
                                            showInColumn="budgetQty" />
                                        <TotalItem
                                            name="unit"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="unit" />
                                        <TotalItem
                                            name="budgetDollarPerUnits"
                                            summaryType="custom"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="budgetDollarPerUnits" />
                                        <TotalItem
                                            name="budgetProduction"
                                            summaryType="custom"
                                            valueFormat="###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="budgetProduction" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="units" />
                                        <TotalItem
                                            name="budgetCrewRate"
                                            summaryType="custom"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="budgetCrewRate" />
                                        <TotalItem
                                            column="toDateDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="toDatePercentageCompleteDollar"
                                            summaryType="custom"
                                            valueFormat="###,##0"
                                            displayFormat="{0}%"
                                            showInColumn="toDatePercentageCompleteDollar" />
                                        <TotalItem
                                            column="toDateHours"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="toDatePercentageCompleteMhrs"
                                            summaryType="custom"
                                            valueFormat="###,##0"
                                            displayFormat="{0}%"
                                            showInColumn="toDatePercentageCompleteMhrs" />
                                        <TotalItem
                                            name="toDateQty"
                                            summaryType="custom"
                                            valueFormat="###,##0.0"
                                            displayFormat="{0}"
                                            showInColumn="toDateQty" />
                                        <TotalItem
                                            name="unit"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="To-Date Unit" />
                                        <TotalItem
                                            name="toDateDollarPerUnit"
                                            summaryType="custom"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="toDateDollarPerUnit" />
                                        <TotalItem
                                            name="toDateProduction"
                                            summaryType="custom"
                                            valueFormat="###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="toDateProduction" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="To-Date Units" />
                                        <TotalItem
                                            column="toDateOverUnder"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="toDateCrewRate"
                                            summaryType="custom"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="toDateCrewRate" />
                                        <TotalItem
                                            column="actualDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="actualPercentageCompleteDollar"
                                            summaryType="custom"
                                            valueFormat="###,##0"
                                            displayFormat="{0}%"
                                            showInColumn="actualPercentageCompleteDollar" />
                                        <TotalItem
                                            column="actualHours"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="actualQty"
                                            summaryType="custom"
                                            valueFormat="###,##0.0"
                                            displayFormat="{0}"
                                            showInColumn="actualQty" />
                                        <TotalItem
                                            name="unit"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Actual Unit" />
                                        <TotalItem
                                            name="actualDollarPerUnit"
                                            summaryType="custom"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="actualDollarPerUnit" />
                                        <TotalItem
                                            name="actualProduction"
                                            summaryType="custom"
                                            valueFormat="###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="actualProduction" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Actual Units" />
                                        <TotalItem
                                            column="actualOverUnder"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="actualCrewRate"
                                            summaryType="custom"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}"
                                            showInColumn="actualCrewRate" />
                                        <TotalItem
                                            column="remainingDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="remainingMhrs"
                                            summaryType="sum"
                                            valueFormat="###,##0;(###,##0)"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="remainingQty"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="unit"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Remaining Unit" />
                                        <TotalItem
                                            column="projectedProduction"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Projected Units" />
                                        <TotalItem
                                            column="projectedFinalDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="projectedOverUnderDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="projectedFinalMhrs"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="projectedOverUnderMhrs"
                                            summaryType="sum"
                                            valueFormat="###,##0;(###,##0)"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="projectedCrewRate"
                                            summaryType="sum"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="forecastedProduction"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Forecated Units" />
                                        <TotalItem
                                            column="forecastedFinalDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="forecastedOverUnderDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="forecastedFinalHrs"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="forecastedOverUnderMhrs"
                                            summaryType="sum"
                                            valueFormat="###,##0;(###,##0)"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="forecastedCrewRate"
                                            summaryType="sum"
                                            valueFormat="$###,##0.00"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="requiredProduction"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Required Units" />
                                        <TotalItem
                                            column="finalDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="requiredOverUnderDollar"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="finalHrs"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="requiredOverUnderMhrs"
                                            summaryType="sum"
                                            valueFormat="###,##0;(###,##0)"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="productionDelta"
                                            summaryType="sum"
                                            valueFormat="###,##0;(###,##0)"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            name="units"
                                            summaryType="custom"
                                            displayFormat="{0}"
                                            showInColumn="Production Units" />
                                        <TotalItem
                                            column="finalDollarDelta"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            displayFormat="{0}" />
                                        <TotalItem
                                            column="finalMhrDelta"
                                            summaryType="sum"
                                            valueFormat="###,##0"
                                            displayFormat="{0}" />
                                    </Summary>
                                    {/* <Scrolling columnRenderingMode="virtual" /> */}
                                </DataGrid>
                            ) : (
                                <div className="loader custom-loader">
                                    <Loading />
                                </div>
                            )}
                        </Box>
                    </div>
                </div >
            </div >
            <SummaryOverrideModalDialog
                Open={openSummaryModaldialog}
                DialogTitle={"Summary Overrides"}
                DialogContentText={Messages.ManageUser.confirmMessage}
                ButtonYes={"Save"}
                ButtonNo={"Cancel"}
                onCancel={closeSummaryModal}
                onClose={handlecloseSummaryModal}
                rowData={manageSummary}
                OpenSummaryModalEdit={openSummaryModalEdit}
                setReloadModalPopup={setReloadModalPopup}
                ReloadModalPopup={reloadModalPopup}
            />
            <ForecastOverrideModalDialog
                Open={openForecastModaldialog}
                DialogTitle={"Forecast Overrides"}
                DialogContentText={Messages.ManageUser.confirmMessage}
                ButtonYes={"Save"}
                ButtonNo={"Cancel"}
                onCancel={closeForecastModal}
                onClose={handlecloseForecastModal}
                rowData={manageForecast}
                OpenForecastModalEdit={openForecastModalEdit}
                setReloadModalPopup={setReloadModalPopup}
                ReloadModalPopup={reloadModalPopup}
            />
        </>
    )
}