import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CreateIcon from '@mui/icons-material/Create';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, FormControlLabel, Checkbox, FormControl, InputLabel, MenuItem, Select, Autocomplete } from "@mui/material";
import { Loading } from "../Shared/Loading";
import { Messages, NotifyType } from "../Shared/constant";
import { AlertDialogBox } from "../Shared/AlertDialogBox";
import Notify from "../Shared/notify";
import { IManageCrewModel } from "./ICrew";
import CrewServices from "./CrewServices";
import { IManageUserModel, IProjectsModel } from "../models/IUser";
import UserServices from "../services/UserServices";

//#region Model Declares
export type IProps = {
  DialogTitle: string;
  DialogContentText: string;
  ButtonYes: string;
  ButtonNo: string;
  onCancel?: any | null;
  Open: boolean;
  onClose?: any | null;
  IsEditMode: boolean;
  rowData: IManageCrewModel;
  OpenCrewModalEdit?: any | null;
  setReloadModalPopup: any;
  ReloadModalPopup: boolean;
  gridRowData: IManageCrewModel[];
};

const defaultValues: IManageCrewModel = { id: 0, name: "", projectId: 0, projectName: "", projectTitle: "", supervisorName: "", supervisor: 0, isActive: true };

//#endregion
export const CrewModalDialog: React.FunctionComponent<IProps> = (props) => {
  //#region Variable Declaration

  //const declaration
  const { onClose } = props;
  const { onCancel } = props;
  const [manageCrew, setManageCrew] = React.useState(defaultValues);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoadSaveCrew, setIsLoadSaveCrew] = React.useState<boolean>(false);
  const [errProjects, setErrProjects] = React.useState<boolean>(false);
  const [, setDisabledControls] = React.useState<boolean>(true);
  const [isFormInvalid, setIsFormInvalid] = React.useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [project, setProjects] = React.useState<IProjectsModel[]>([]);
  const [supervisor, setSupervisor] = React.useState<IManageUserModel[]>([]);
  const [, setErrName] = React.useState<boolean>(false);
  const [errProjectCode, setErrProjectCode] = React.useState<boolean>(false);
  const [errMessageProjectCode, setErrMessageProjectCode] = React.useState("");
  //#endregion

  //#region UserEffect
  React.useEffect(() => {
    setManageCrew(defaultValues);
    getProjects();
    getSupervisor();
  }, []);

  React.useEffect(() => {
    setManageCrew(defaultValues);

    if (props.rowData.id > 0) {
      setManageCrew({
        ...manageCrew,
        id: props.rowData.id,
        name: props.rowData.name,
        projectId: props.rowData.projectId,
        projectName: props.rowData.projectName,
        supervisorName: props.rowData.supervisorName,
        supervisor: props.rowData.supervisor,
        isActive: props.rowData.isActive,
      });
      props.setReloadModalPopup(false);

      props.OpenCrewModalEdit();
      // getUserById();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData.id, props.ReloadModalPopup]);
  //#endregion

  //#region PageLoad Methods
  const getProjects = async () => {
    CrewServices.GetProjects(0).then((res) => {
      if (!res.hasErrors && res.value) {
        setProjects(res.value.filter(x => x.parent === null && x.isActive === true && x.isTracked === true));
      }
    });
  };

  const getSupervisor = async () => {
    //setIsLoaded(false);
    UserServices.GetUsers().then((res) => {
      if (!res.hasErrors && res.value) {
        setSupervisor(res.value.filter(x => x.roleId === 2 || x.roleId === 7));
        //  setIsLoaded(true);
      }
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setManageCrew({
      ...manageCrew,
      [name]: value,
    });
  };

  const validateCrewName = (name: string, projectName: string, id: number) => {
    resetErrorState();
    let isValid: boolean = true;

    if (name !== "") {
      setIsFormInvalid(false);
      const obj = props.gridRowData.filter((x) => x.name.toLocaleLowerCase() === name.toLocaleLowerCase().trim() && x.id !== id);
      if (obj.length > 0) {
        setIsFormInvalid(true);
        setErrorMsg(name + " already exist!");
        // return false;
        isValid = false;
      }
    }

    if (projectName === "" || projectName === undefined || projectName === null) {
      setErrProjectCode(true);
      setErrMessageProjectCode(Messages.ManageUser.requiredProjects);
      setManageCrew({
        ...manageCrew,
        projectName: "",
      });
      isValid = false;
    }

    if (name === "" || name === undefined || name === null) {
      setErrorMsg("Name required");
      setIsFormInvalid(true);
      isValid = false;
    }

    if (isValid) {
      return true;
    }
    return false;

  };

  const saveCrew = async () => {
    if (validateCrewName(manageCrew.name, manageCrew.projectName, manageCrew.id)) {
      let projectId = project.filter(p => p.projectCode === manageCrew.projectName).map(p => p.id)[0];

      let saveCrew: IManageCrewModel = {
        id: manageCrew.id,
        name: manageCrew.name,
        projectId: projectId,
        projectName: manageCrew.projectName,
        projectTitle: manageCrew.projectTitle,
        supervisorName: manageCrew.supervisorName === "" ? null : manageCrew.supervisorName,
        supervisor: manageCrew.supervisor === 0 ? null : manageCrew.supervisor,
        isActive: manageCrew.isActive,
        createdBy: window.UserId,
      };
      setIsLoadSaveCrew(true);
      var response = await CrewServices.SaveCrew(saveCrew);
      if (response.success && !response.hasErrors && response.value) {
        setIsLoadSaveCrew(false);
        Notify(
          NotifyType.success,
          props.IsEditMode ? Messages.Crew.updated : Messages.Crew.saved
        );
        setManageCrew(defaultValues);
        setDisabledControls(false);
        onClose();
      } else {
        setIsLoadSaveCrew(false);
        Notify(NotifyType.error, Messages.Crew.error);
      }
    }
  };

  //#endregion

  //#region internal methods

  const resetErrorState = async () => {
    setErrName(false);
    setErrProjects(false);
    setErrorMsg("");
    setErrProjectCode(false);
    setErrMessageProjectCode("");
  };

  const resetUser = async () => {
    resetErrorState();
    setDisabledControls(true);
    setManageCrew(defaultValues);
    onCancel();
  };

  const closeAlertDialogBox = () => {
    setAlertOpen(false);
    setAlertMessage("");
    // inputRef.focus();
  };

  const handleErrorsName = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (value: any, cntrlName: string) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      switch (cntrlName) {
        case 'ProjectCode':
          setErrProjectCode(true);
          setErrMessageProjectCode(Messages.ManageUser.requiredProjects);
          setManageCrew({
            ...manageCrew,
            projectName: "",
          });
          break;
      }
    } else {
      switch (cntrlName) {
        case 'ProjectCode':
          setErrProjectCode(false);
          setErrMessageProjectCode("");
          setManageCrew({
            ...manageCrew,
            projectName: value,
          });
          break;
      }
    }
  }
  //#endregion

  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.onCancel}
        className="confirm-dialog addForm custom-modal"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {props.DialogTitle}
          <IconButton
            aria-label="close"
            onClick={resetUser}
            sx={{
              position: "absolute",
              right: 10,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {!isLoadSaveCrew ? (
            <div>
              <div className="form-group">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  name="name"
                  value={manageCrew.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  required
                  error={isFormInvalid}
                  helperText={isFormInvalid ? errorMsg : ""}
                  onBlur={(e) => {
                    setIsFormInvalid(handleErrorsName(e.target.value));
                  }}
                />
              </div>
              <div className="form-group">
                <Autocomplete
                  limitTags={2}
                  id="drpProjects"
                  value={manageCrew.projectName}
                  options={project.map((option) => option.projectCode)}
                  filterSelectedOptions

                  sx={{ minWidth: 300 }}
                  onChange={(event: any, newValue: string | null) => {
                    handleChange(newValue, "ProjectCode");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Number"
                      required
                      value={manageCrew.projectName}
                      helperText={errProjectCode ? errMessageProjectCode : ""}
                      error={errProjectCode}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        placeholder: "Project",
                      }} />
                  )} />
              </div>
              <div className="form-group">
                <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Supervisor</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="supervisor"
                    value={manageCrew.supervisor}
                    onChange={(e) => {
                      setManageCrew({
                        ...manageCrew,
                        supervisor: parseInt(e.target.value.toString()),
                      });
                    }}
                    label="Supervisor"
                  // onBlur={(e) => {
                  //   setErrProjects(handleErrorsRole(e.target.value));
                  // }}
                  //disabled={window.AdminRoleId === +window.RoleId ? false : true}
                  >
                    {supervisor.map((supervisor: IManageUserModel) => (
                      <MenuItem value={supervisor.id}>{supervisor.firstName} {supervisor.lastName}</MenuItem>
                    ))}
                  </Select>
                  {/* {errProjects && (
                    <FormHelperText>
                      {errProjects ? Messages.ManageUser.requiredProjects : ""}
                    </FormHelperText>
                  )} */}
                </FormControl>
              </div>
              <div className="form-group">
                {
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={manageCrew.isActive}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setManageCrew({
                              ...manageCrew,
                              isActive: e.target.checked,
                            });
                          } else {
                            setManageCrew({
                              ...manageCrew,
                              isActive: e.target.checked,
                            });
                          }
                        }}
                      />
                    }
                    label="Active"
                  />
                }
              </div>
            </div>
          ) : (
            <div className="loader">
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary btn-icon btn-sm"
            onClick={resetUser}
          >
            Close <CloseIcon />
          </button>
          {!props.IsEditMode ? (
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={saveCrew}
            >
              Add <PersonAddAlt1Icon />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={saveCrew}
            >
              Update <CreateIcon />
            </button>
          )}
        </DialogActions>
      </Dialog>
      <AlertDialogBox
        Open={alertOpen}
        DialogTitle={Messages.ManageUser.userAlert}
        DialogContentText={alertMessage}
        onClose={closeAlertDialogBox}
      />
    </div>
  );
};
