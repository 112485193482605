import { NotifyType } from "../Shared/constant";
import { toast } from 'react-toastify';

export default function notify(type: string, msg: string, customId?: string) {
    if (NotifyType.error === type) {
        toast.error(msg, { toastId: customId });
    }
    else if (NotifyType.info === type) {
        toast.info(msg, { toastId: customId });
    }
    else if (NotifyType.success === type) {
        toast.success(msg, { toastId: customId });
    }
    else if (NotifyType.warn === type) {
        toast.warn(msg, { toastId: customId });
    }
}