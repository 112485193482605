import { Result } from "../models/Result";
import { ServiceBase } from "./ServiceBase";

export interface ISnapshotSaveRequestModel {
    activityIds: number[],
    crewMemberIds: number[],
    crewId: number,
    date: Date,
    userId: number
    crewMemberSequences?: number[],
    activitySequences?: number[],
}

export interface ISnapshotSaveResponseModel {
    weeklySnapshotId: number,
    activityId: number,
    crewMemberId: number,
    crewId: number,
    weekNo: number,
    year: number,
    crewMemberHours: number,
    activityHours: number
}

export default class snapshotServices extends ServiceBase {
    public static async SaveSnapshot(saveRequestModel: ISnapshotSaveRequestModel): Promise<Result<ISnapshotSaveResponseModel[]>> {
        var result = await this.requestJson<ISnapshotSaveResponseModel[]>({
            url: `api/snapshot`,
            method: "POST",
            data: saveRequestModel
        });
        return result;
    }
}