import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UploadFile from "@mui/icons-material/UploadFile";
import UploadIcon from '@mui/icons-material/Upload';
import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridApi,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { Messages, UserRoleType } from "../Shared/constant";
import notify from "../Shared/notify";
import { Loading } from "../Shared/Loading";
import ActivityBudgetListServices from "./ActivityBudgetListServices";
import { IActivityBudgetModel } from "./IActivityBudget";
import UnAuthorized from "../Shared/UnAuthorized";
import { IProjectsModel } from "../models/IUser";
import CrewServices from "../CrewDetail/CrewServices";
import { Autocomplete, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const defaultValues: IActivityBudgetModel = {
  id: 0,
  projectCode: "",
  startDate: "",
  endDate: "",
  costCode: "",
  costCodeTitle: "",
  projectTitle: "",
  group: "",
  scope: "",
  activity: "",
  area: "",
  activityIsTracked: false,
  activityIsActive: false,
  activityStartDate: "",
  activityEndDate: "",
  cost: 0,
  manHours: 0,
  quantity: 0,
  unit: "",
  costOverride: 0,
  manHoursOverride: 0,
  qauntityOverride: 0,
  unitOverride: "",
  overrideNotes: ""
};

const initialRows: GridRowsProp = [];

//#region ToolBar

interface EditToolbarProps {
  apiRef: React.MutableRefObject<GridApi>;
  title: "";
  handleFilesChange: any;
  isReadOnly: boolean;
  isDisplayAutoButton: boolean;
}

function EditToolbar(props: EditToolbarProps) {
  // const {
  //   //apiRef
  // } = props;

  return (
    <>
      <div className="row align-items-center mb-3">
        <div className="col">
          <h5 className="py-3">{props.title}</h5>
        </div>
        <div className="col d-flex justify-content-end">
          <GridToolbarContainer>
            <Button
              variant="text"
              component="label"
              color="primary"
              startIcon={<UploadFile />}
              className="btn-icon-link btn"
              data-bs-toggle="modal"
              data-bs-target="#AddActivityBudgetModal"
            >
              Upload CSV
            </Button>
            <GridToolbarExport
              color="primary"
              className="btn-icon-link btn"
              csvOptions={{
                fileName: 'Activity & Budget List',
                utf8WithBom: true,
              }}
              printOptions={{ disableToolbarButton: true }} />
          </GridToolbarContainer>
        </div>
      </div>
    </>
  );
}
//#endregion

export default function ActivityBudgetList() {
  const [rows, setRows] = React.useState(initialRows);
  const [activityBudgetList, setActivityBudgetList] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [manageActivityBudget, setManageActivityBudget] = React.useState(defaultValues);
  const [isLoadActivityBudgetData, setisLoadActivityBudgetData] =
    React.useState<boolean>(false);
  const [isUploadFile, setIsUploadFile] = React.useState<boolean>(true);
  const [importActivityBudgetListErrorMessages, setImportActivityBudgetListErrorMessages] =
    React.useState<string>("");
  const [projects, setProjects] = React.useState<IProjectsModel[]>([]);
  const [errProjects, setErrProjects] = React.useState<boolean>(false);
  const [errMessageProject, setErrMessageProject] = React.useState("");
  const [inputProjectPrams, setInputProjectParams] = React.useState("");

  useEffect(() => {
    setisLoadActivityBudgetData(false);
    getProjects();
    getActivityBudgetList();
    setManageActivityBudget(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjects = async () => {
    CrewServices.GetProjects(0).then((res) => {
      if (!res.hasErrors && res.value) {
        const localProjects = res.value.filter((x) => x.parent === null && x.isActive === true && x.isTracked === true);
        setProjects(localProjects);
      }
    });
  };

  const getActivityBudgetList = async () => {
    ActivityBudgetListServices.GetActivityBudgetList().then((res) => {
      if (!res.hasErrors && res.value) {
        res.value.forEach((element, index) => {
          element.id = index + 1;
          setManageActivityBudget({
            ...manageActivityBudget,
            id: element.id,
            projectCode: element.projectCode,
            startDate: element.startDate,
            endDate: element.endDate,
            costCode: element.costCode,
            costCodeTitle: element.costCodeTitle,
            projectTitle: element.projectTitle,
            group: element.group,
            scope: element.scope,
            activity: element.activity,
            area: element.area,
            activityIsTracked: element.activityIsTracked,
            activityIsActive: element.activityIsActive,
            activityStartDate: element.activityStartDate,
            activityEndDate: element.activityEndDate,
            cost: element.cost,
            manHours: element.manHours,
            quantity: element.quantity,
            unit: element.unit,
            costOverride: element.costOverride,
            manHoursOverride: element.manHoursOverride,
            qauntityOverride: element.qauntityOverride,
            unitOverride: element.unitOverride,
            overrideNotes: element.overrideNotes
          });
        });
        setActivityBudgetList(res.value);
        setRows(res.value);
        setisLoadActivityBudgetData(true);
      }
    });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    setActivityBudgetList(activityBudgetList.map((row) => (row.id === newRow.id ? updatedRow : activityBudgetList)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  //#region Validation for required cell

  //#endregion Validation

  const columns: GridColDef[] = [
    {
      field: "projectCode",
      headerName: "Project Number",
      width: 150,
      editable: false,
    },
    {
      field: "projectTitle",
      headerName: "Project Title",
      width: 150,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleDateString("en-US") : null,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleDateString("en-US") : null,
    },
    {
      field: "group",
      headerName: "Group",
      width: 150,
      editable: false,
    },
    {
      field: "scope",
      headerName: "Scope",
      width: 150,
      editable: false,
    },
    {
      field: "activity",
      headerName: "Activity",
      width: 150,
      editable: false,
    },
    {
      field: "area",
      headerName: "Area",
      width: 150,
      editable: false,
    },
    {
      field: "activityIsTracked",
      headerName: "Tracked",
      width: 100,
      editable: false,
    },
    {
      field: "activityIsActive",
      headerName: "Active",
      width: 100,
      editable: false,
    },
    {
      field: "activityStartDate",
      headerName: "Activity Start Date",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleDateString("en-US") : null,
    },
    {
      field: "activityEndDate",
      headerName: "Activity End Date",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        params?.value ? new Date(params?.value).toLocaleDateString("en-US") : null,
    },
    {
      field: "costCode",
      headerName: "Cost Code",
      width: 150,
      editable: false,
    },
    {
      field: "costCodeTitle",
      headerName: "Cost Code Title",
      width: 150,
      editable: false,
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        params?.value ? "$" + params.value : null,
    },
    {
      field: "manHours",
      headerName: "Man Hours",
      width: 150,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      editable: false,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 120,
      editable: false,
    },
    {
      field: "costOverride",
      headerName: "Cost Override",
      width: 150,
      editable: false,
    },
    {
      field: "manHoursOverride",
      headerName: "Man Hours Override",
      width: 150,
      editable: false,
    },
    {
      field: "qauntityOverride",
      headerName: "Quantity Override",
      width: 150,
      editable: false,
    },
    {
      field: "unitOverride",
      headerName: "Override Units",
      width: 150,
      editable: false,
    },
    {
      field: "overrideNotes",
      headerName: "Override Notes",
      width: 150,
      editable: false,
    },
  ];

  //#region Upload CSV
  const handleFilesChange = async (e: any) => {
    if (e.target.files.length !== 0) {
      setIsUploadFile(false);
      let isValid = filesValidate(e.target.files[0]);
      if (isValid) {
        // setDisplayFiles(e.target.files[0]);
        let formData = new FormData();
        formData.append("files", e.target.files[0]);

        let res = (await ActivityBudgetListServices.UploadActivityBudgetist(formData)).value;
        if (res && res.isSuccess) {
          setImportActivityBudgetListErrorMessages("");
          notify("success", Messages.ActivityBudgetList.success);
          getActivityBudgetList();
          e.target.value = null;
          document.getElementById("bulkActivityBudgetListCancel")?.click();
        } else {
          if (res.customerrors.length > 0) {
            let errors = "";
            res.customerrors.forEach((element: string) => {
              const allErrors = element.split("~");
              allErrors.forEach((text, index) => {
                if (index === 0) {
                  errors = errors + text + "<br/><ul>";
                } else {
                  errors = errors + "<li>" + text + "</li>";
                }
              });

              if (allErrors.length > 0) {
                errors = errors + "</ul>";
              }
            });
            setImportActivityBudgetListErrorMessages(errors);
          } else {
            notify("error", Messages.ActivityBudgetList.error);
          }
          e.target.value = "";


        }
      }
      setIsUploadFile(true);
    }
  };

  function filesValidate(results: any) {
    const file = results;

    const _validFileExtensions = ["csv"];
    let isValid =
      _validFileExtensions.indexOf(file.name.split(".").pop().toLowerCase()) >
      -1;
    return isValid;
  }

  const closeModal = () => {
    // setImportBillingPeriodErrorMessages("");
  };

  const handleChange = (value: any) => {
    if (value === undefined || value === null || value === "" || value.length === 0) {
      setRows(activityBudgetList);
      setInputProjectParams("");
    } else {
      setInputProjectParams(value);
      setErrProjects(false);
    }
  }

  const resetErrorState = async () => {
    setErrProjects(false);
  };

  const searchActivityBudgetList = async () => {
    resetErrorState();
    let isValid = true;

    if (inputProjectPrams === undefined || inputProjectPrams === null || inputProjectPrams === "") {
      setErrProjects(true);
      setErrMessageProject(Messages.Reports.requiredProjectCode);
      isValid = false;
    }

    if (isValid) {
      const filterData = activityBudgetList.filter(function (item) {
        return inputProjectPrams.indexOf(item.projectCode) >= 0;
      });
      setRows(filterData);
    }
  };
  //#endregion

  return (
    <>
      {window.RoleId !== UserRoleType.FieldSupervisor.toString() ? (
        <><div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h1 className="page-title">Activity & Budget Management</h1>
            </div>
          </div>
        </div><div className="row">
            <div className="col-12">
              <div className="card user-detail-card pages-detail-card">
                <div className="card-body">
                  <div className="dataTables_wrapper">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 d-flex mb-2 gap-3">
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="drpProjects"
                          options={projects.map((option) => option.projectCode)}
                          filterSelectedOptions
                          sx={{ minWidth: 300 }}
                          onChange={(event: any, newValue: string[] | null) => {
                            handleChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Project Number"
                              required
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                placeholder: "Project",
                              }}
                              error={errProjects}
                              helperText={errProjects ? errMessageProject : ""}
                            />
                          )} />
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-icon btn-sm"
                            onClick={searchActivityBudgetList}
                          >
                            Search <SearchIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid_wrapper autoheightcell">
                    <Box
                      sx={{
                        height: 500,
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                      }}
                    >
                      {isLoadActivityBudgetData ? (
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          disableRowSelectionOnClick={true}
                          editMode="row"
                          className="user-details-grid custom-details-grid gridActivityMgmtTbl"
                          rowModesModel={rowModesModel}
                          // apiRef={apiRef}
                          onRowModesModelChange={handleRowModesModelChange}
                          onRowEditStop={handleRowEditStop}
                          processRowUpdate={processRowUpdate}
                          slots={{
                            toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: { setRows, setRowModesModel },
                          }} />
                      ) : (
                        <div className="loader">
                          <Loading />
                        </div>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div><div
            className="modal fade"
            id="AddActivityBudgetModal"
            aria-labelledby="AddActivityBudgetModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="AddActivityBudgetModalLabel">
                    Upload a CSV file to add activity and budget list.
                  </h5>
                  <button
                    type="button"
                    id="bulkActivityBudgetListCancel"
                    className="btn-close"
                    onClick={closeModal}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="pt-3 px-3">
                    <div className="form-group">
                      <form>
                        <div className="form-group">
                          <p className="text-muted font-14">
                            The following columns are required: Project#,
                            Cost Code, Cost Code Title, Group, Tracked, Active.
                            <br /> <br /> The date format should be MM/DD/YYYY
                          </p>
                          <hr className="my-4" />
                          {/* <p className="text-muted font-14">
      The date format should be MM/DD/YYYY
    </p> */}
                        </div>
                        {!isUploadFile ? (
                          <div className="loader">
                            <Loading />
                          </div>
                        ) : (
                          <div className="form-group attachFile">
                            <div className="file-input">
                              <label
                                className="file-input__label"
                                htmlFor="file-input"
                              >
                                <UploadIcon />
                                &nbsp;&nbsp;<span>Upload File</span>
                              </label>
                              <input
                                type="file"
                                name="file-input"
                                id="file-input"
                                className="file-input__input"
                                multiple
                                onChange={handleFilesChange} />
                            </div>
                            {importActivityBudgetListErrorMessages.length > 0 ? (
                              <div className="card mt-4">
                                <div
                                  className="card-body"
                                  style={{ maxHeight: "200px", overflow: "auto" }}
                                >
                                  <h5 className="card-title">Errors</h5>
                                  <p
                                    className="card-text"
                                    dangerouslySetInnerHTML={{
                                      __html: importActivityBudgetListErrorMessages,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></>
      ) : (
        <div>
          <UnAuthorized />
        </div>
      )}
    </>
  );
}
