import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CreateIcon from '@mui/icons-material/Create';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, FormControlLabel, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Loading } from "../../Shared/Loading";
import { Messages, NotifyType } from "../../Shared/constant";
import { AlertDialogBox } from "../../Shared/AlertDialogBox";
import Notify from "../../Shared/notify";
import { IManageCrewModel } from "../../CrewDetail/ICrew";
import CrewServices from "../../CrewDetail/CrewServices";
import { IManageUserModel, IProjectsModel, IRoleModel } from "../../models/IUser";
import UserServices from "../../services/UserServices";
import { ICrewSelectionModel } from "./ICrewSelection";

//#region Model Declares
export type IProps = {
  DialogTitle: string;
  DialogContentText: string;
  ButtonYes: string;
  ButtonNo: string;
  onCancel?: any | null;
  Open: boolean;
  onClose?: any | null;
  IsEditMode: boolean;
  rowData: IManageCrewModel;
  OpenCrewModalEdit?: any | null;
  setReloadModalPopup: any;
  ReloadModalPopup: boolean;
  gridRowData: ICrewSelectionModel[];
  projectId: number;
};

const defaultValues: IManageCrewModel = {
  id: 0,
  name: "",
  projectId: 0,
  projectName: "",
  projectTitle: "",
  supervisorName: "",
  supervisor: 0,
  isActive: true,
};

//#endregion
export const CrewSelectionModalDialog: React.FunctionComponent<IProps> = (props) => {
  //#region Variable Declaration

  //let declaration
  // let inputRef;

  //const declaration
  const { onClose } = props;
  const { onCancel } = props;
  const [manageCrew, setManageCrew] = React.useState(defaultValues);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoadSaveCrew, setIsLoadSaveCrew] = React.useState<boolean>(false);
  const [errProjects, setErrProjects] = React.useState<boolean>(false);
  const [disabledControls, setDisabledControls] = React.useState<boolean>(true);
  const [isFormInvalid, setIsFormInvalid] = React.useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [project, setProjects] = React.useState<IProjectsModel[]>([]);
  const [supervisor, setSupervisor] = React.useState<IManageUserModel[]>([]);
  const [errName, setErrName] = React.useState<boolean>(false);
  //#endregion

  //#region UserEffect
  React.useEffect(() => {
    setManageCrew(defaultValues);
    getProjects();
    getSupervisor();
  }, []);

  React.useEffect(() => {
    setManageCrew({ ...manageCrew, projectId: props.projectId, });
  }, [props.Open]);

  React.useEffect(() => {
    setManageCrew(defaultValues);

    if (props.rowData.id > 0) {
      setManageCrew({
        ...manageCrew,
        id: props.rowData.id,
        name: props.rowData.name,
        projectId: props.rowData.projectId,
        projectName: props.rowData.projectName,
        supervisorName: props.rowData.supervisorName,
        supervisor: props.rowData.supervisor,
        isActive: props.rowData.isActive,
      });
      props.setReloadModalPopup(false);

      props.OpenCrewModalEdit();
      // getUserById();
    }
  }, [props.rowData.id, props.ReloadModalPopup]);
  //#endregion

  //#region PageLoad Methods
  const getProjects = async () => {
    CrewServices.GetProjects(0).then((res) => {
      if (!res.hasErrors && res.value) {
        setProjects(res.value.filter(x => x.parent == null && x.isActive == true && x.isTracked == true));
      }
    });
  };

  const getSupervisor = async () => {
    //setIsLoaded(false);
    UserServices.GetUsers().then((res) => {
      if (!res.hasErrors && res.value) {
        setSupervisor(res.value.filter(x => x.roleId == 2 || x.roleId === 7));
        //  setIsLoaded(true);
      }
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setManageCrew({
      ...manageCrew,
      [name]: value,
    });
  };

  const validateCrewName = (name: string, projectId: number, id: number) => {
    resetErrorState();
    let isValid: boolean = true;

    if (name !== "") {
      setIsFormInvalid(false);
      const obj = props.gridRowData.filter((x) => x.crewName.toLocaleLowerCase() === name.toLocaleLowerCase().trim() && x.id !== id);
      if (obj.length !== 0) {
        setIsFormInvalid(true);
        setErrorMsg(name + " already exist!");
        // return false;
        isValid = false;
      }
    }
    if (projectId === 0 || projectId === undefined || projectId === null) {
      setErrProjects(true);
      setIsFormInvalid(true);
      //setErrMessageRole(Messages.ManageUser.requiredProjects);
      isValid = false;
    }
    if (name === "" || name === undefined || name === null) {
      setErrorMsg("Name required");
      setIsFormInvalid(true);
      isValid = false;
    }
    // else {
    //   setErrorMsg("Name required");
    //   setIsFormInvalid(true);
    //   isValid = false;
    // }

    if (isValid) {
      return true;
    }
    return false;

  };

  const saveCrew = async () => {
    if (validateCrewName(manageCrew.name, manageCrew.projectId, manageCrew.id)) {
      let saveCrew: IManageCrewModel = {
        id: manageCrew.id,
        name: manageCrew.name,
        projectId: manageCrew.projectId,
        projectName: manageCrew.projectName,
        projectTitle: manageCrew.projectTitle,
        supervisorName: manageCrew.supervisorName === "" ? null : manageCrew.supervisorName,
        supervisor: manageCrew.supervisor === 0 ? null : manageCrew.supervisor,
        isActive: manageCrew.isActive,
        createdBy: window.UserId
      };
      setIsLoadSaveCrew(true);
      var response = await CrewServices.SaveCrew(saveCrew);
      if (response.success && !response.hasErrors && response.value) {
        setIsLoadSaveCrew(false);
        Notify(
          NotifyType.success,
          props.IsEditMode ? Messages.Crew.updated : Messages.Crew.saved
        );
        setManageCrew(defaultValues);
        setDisabledControls(false);
        onClose();
      } else {
        setIsLoadSaveCrew(false);
        Notify(NotifyType.error, Messages.Crew.error);
      }
    }
  };

  //#endregion

  //#region internal methods

  const resetErrorState = async () => {
    setErrName(false);
    setErrProjects(false);
  };

  const resetUser = async () => {
    resetErrorState();
    setDisabledControls(true);
    setManageCrew(defaultValues);
    onCancel();
  };

  const closeAlertDialogBox = () => {
    setAlertOpen(false);
    setAlertMessage("");
    // inputRef.focus();
  };

  const handleErrorsRole = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleErrorsName = (value: any) => {
    if (value === undefined || value === null || value === "" || value === 0) {
      return true;
    } else {
      return false;
    }
  };
  //#endregion

  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.onCancel}
        className="confirm-dialog addForm custom-modal"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {props.DialogTitle}
          <IconButton
            aria-label="close"
            onClick={resetUser}
            sx={{
              position: "absolute",
              right: 10,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {!isLoadSaveCrew ? (
            <div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  name="name"
                  value={manageCrew.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  required
                  error={isFormInvalid}
                  helperText={isFormInvalid ? errorMsg : ""}
                  onBlur={(e) => {
                    setIsFormInvalid(handleErrorsName(e.target.value));
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <FormControl fullWidth error={errProjects && true}>
                  <InputLabel id="demo-simple-select-label">Project Number</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="drpProjects"
                    name="projectId"
                    value={manageCrew.projectId}
                    onChange={(e) => {
                      setManageCrew({
                        ...manageCrew,
                        projectId: parseInt(e.target.value.toString()),
                      });
                    }}
                    label="Project Number"
                    onBlur={(e) => {
                      setErrProjects(handleErrorsRole(e.target.value));
                    }}
                  >
                    {project.map((project: IProjectsModel, index: number) => (
                      <MenuItem key={index} value={project.id}>{project.projectCode}</MenuItem>
                    ))}
                  </Select>
                  {errProjects && (
                    <FormHelperText>
                      {errProjects ? Messages.ManageUser.requiredProjects : ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="form-group mb-4">
                <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Supervisor</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="drpSupervisor"
                    name="supervisor"
                    value={manageCrew.supervisor}
                    onChange={(e) => {
                      setManageCrew({
                        ...manageCrew,
                        supervisor: parseInt(e.target.value.toString()),
                      });
                    }}
                    label="Supervisor"
                  // onBlur={(e) => {
                  //   setErrProjects(handleErrorsRole(e.target.value));
                  // }}
                  //disabled={window.AdminRoleId === +window.RoleId ? false : true}
                  >
                    {supervisor.map((supervisor: IManageUserModel, index: number) => (
                      <MenuItem key={index} value={supervisor.id}>{supervisor.firstName} {supervisor.lastName}</MenuItem>
                    ))}
                  </Select>
                  {/* {errProjects && (
                    <FormHelperText>
                      {errProjects ? Messages.ManageUser.requiredProjects : ""}
                    </FormHelperText>
                  )} */}
                </FormControl>
              </div>
              <div className="form-group mb-4">
                {
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={manageCrew.isActive}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setManageCrew({
                              ...manageCrew,
                              isActive: e.target.checked,
                            });
                          } else {
                            setManageCrew({
                              ...manageCrew,
                              isActive: e.target.checked,
                            });
                          }
                        }}
                      />
                    }
                    label="Active"
                  />
                }
              </div>
            </div>
          ) : (
            <div className="loader">
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary btn-icon btn-sm"
            onClick={resetUser}
          >
            Close <CloseIcon />
          </button>
          {!props.IsEditMode ? (
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={saveCrew} //validateUser
            >
              Add <PersonAddAlt1Icon />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={saveCrew} //validateUser
            >
              Update <CreateIcon />
            </button>
          )}
        </DialogActions>
      </Dialog>
      <AlertDialogBox
        Open={alertOpen}
        DialogTitle={Messages.ManageUser.userAlert}
        DialogContentText={alertMessage}
        onClose={closeAlertDialogBox}
      />
    </div>
  );
};
