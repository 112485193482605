import * as React from "react";
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CreateIcon from '@mui/icons-material/Create';
import { Dialog, DialogTitle, IconButton, DialogContent, TextField, FormControlLabel, Checkbox, DialogActions } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AlertDialogBox } from "../Shared/AlertDialogBox";
// import { Loading } from "../Shared/Loading";
import Notify from "../Shared/notify";
import { Messages, NotifyType } from "../Shared/constant";
import { IManageProjectModel } from "./IProject";
import ProjectServices from "./ProjectServices";

export type IProps = {
    DialogTitle: string;
    DialogContentText: string;
    ButtonYes: string;
    ButtonNo: string;
    onCancel?: any | null;
    Open: boolean;
    onClose?: any | null;
    IsEditMode: boolean;
    rowData: IManageProjectModel;
    OpenProjectModalEdit?: any | null;
    setReloadModalPopup: any;
    ReloadModalPopup: boolean;
    gridRowData: IManageProjectModel[];
  };

const defaultValues: IManageProjectModel = { id: 0, projectCode: "", projectTitle: "", description: "", parent: null, isTracked: true, isActive: true, startDate:  null, endDate: null, createdBy: +window.UserId };

export const ProjectModalDialog: React.FunctionComponent<IProps> = (props) => {
    const { onClose } = props;
    const { onCancel } = props;
    const [manageProject, setManageProject] = React.useState(defaultValues);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [errProjectCode, setErrProjectCode] = React.useState<boolean>(false);
    const [errProjectTitle, setErrProjectTitle] = React.useState<boolean>(false);
    const [errDescription, setErrDescription] = React.useState<boolean>(false);
    const [errStartDate, setErrStartDate] = React.useState<boolean>(false);
    const [errEndDate, setErrEndDate] = React.useState<boolean>(false);
    const [errMessageProjectCode, setErrMessageProjectCode] = React.useState("");
    const [errMessageProjectTitle, setErrMessageProjectTitle] = React.useState("");
    const [errMessageDescription, setErrMessageDescription] = React.useState("");
    const [errMessageStartDate, setErrMessageStartDate] = React.useState("");
    const [errMessageEndDate, setErrMessageEndDate] = React.useState("");
    const [isReadOnly, setIsReadOnly] = React.useState(false);
    
    React.useEffect(() => {
      setIsReadOnly(true);
      setManageProject(defaultValues);
    }, []);

    React.useEffect(() => {
        setManageProject(defaultValues);

        if (props.rowData.id > 0) {
          setManageProject({
            ...manageProject,
            id: props.rowData.id,
            projectCode: props.rowData.projectCode,
            projectTitle: props.rowData.projectTitle,
            description: props.rowData.description,
            startDate: props.rowData.startDate,
            endDate: props.rowData.endDate,
            isTracked: props.rowData.isTracked,
            isActive: props.rowData.isActive,
            createdBy: props.rowData.createdBy
          });
          props.setReloadModalPopup(false);
    
          props.OpenProjectModalEdit();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rowData.id, props.ReloadModalPopup]);

    const resetProject = async () => {
      resetErrorState();
      setManageProject(defaultValues);
      onCancel();
    };

    const closeAlertDialogBox = () => {
      setAlertOpen(false);
      setAlertMessage("");
    };

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setManageProject({
        ...manageProject,
        [name]: value,
      });
    };

    const handleChange = (newValue: Dayjs | null, cntrlName: string) => {
        if (newValue === undefined || newValue === null) {
            switch (cntrlName) {
                case 'StartDate':
                    setErrStartDate(true);
                    setErrMessageStartDate(Messages.ManageProject.requiredStartDate);
                    break;
                case 'EndDate':
                    setErrEndDate(true);
                    setErrMessageEndDate(Messages.ManageProject.requiredEndDate);
                    break;
            }
        } else {
            switch (cntrlName) {
                case 'StartDate':
                    setManageProject({
                        ...manageProject,
                        startDate: dayjs(newValue).format("YYYY-MM-DDThh:mm:ss"),
                      });
                    break;
                case 'EndDate':
                    setManageProject({
                        ...manageProject,
                        endDate: dayjs(newValue).format("YYYY-MM-DDThh:mm:ss"),
                      });
                    break;
            }
        }
    };

    const handleErrors = (value: any, cntrlName: string) => {
        if (value === undefined || value === null || value === "" || value === 0) {
            switch (cntrlName) {
                case 'ProjectCode':
                    setErrProjectCode(true);
                    setErrMessageProjectCode(Messages.ManageProject.requiredProjectCode);
                    break;
                case 'ProjectTitle':
                    setErrProjectTitle(true);
                    setErrMessageProjectTitle(Messages.ManageProject.requiredProjectTitle);
                    break;
                case 'Description':
                    setErrDescription(true);
                    setErrMessageDescription(Messages.ManageProject.requiredDescription);
                    break;
                case 'StartDate':
                    setErrStartDate(true);
                    setErrMessageStartDate(Messages.ManageProject.requiredStartDate);
                    break;
                case 'EndDate':
                    setErrEndDate(true);
                    setErrMessageEndDate(Messages.ManageProject.requiredEndDate);
                    break;
            }
        }
    }

    const resetErrorState = async () => {
        setErrProjectCode(false);
        setErrProjectTitle(false);
        setErrDescription(false);
        setErrStartDate(false);
        setErrEndDate(false);
    };

    const validateProjectForm = (project: IManageProjectModel) => {
        resetErrorState();
        let isValid = true;
        //var sDate = manageProject.startDate;
        //var eDate = manageProject.endDate;

        if (project.projectTitle === undefined || project.projectTitle === null || project.projectTitle === "") {
          setErrProjectTitle(true);
          setErrMessageProjectTitle(Messages.ManageProject.requiredProjectTitle);
          isValid = false;
      }

        if (project.description === undefined || project.description === null || project.description === "") {
            setErrDescription(true);
            setErrMessageDescription(Messages.ManageProject.requiredDescription);
            isValid = false;
        }

        //if (project.startDate === undefined || project.startDate === null || project.startDate === "") {
        //    setErrStartDate(true);
        //    setErrMessageStartDate(Messages.ManageProject.requiredStartDate);
        //    isValid = false;
        //}

        //if (project.endDate === undefined || project.endDate === null || project.endDate === "") {
        //    setErrEndDate(true);
        //    setErrMessageEndDate(Messages.ManageProject.requiredEndDate);
        //    isValid = false;
        //}

        //if (sDate !== null && eDate !== null) {
        //    if (eDate < sDate) {
        //        setErrEndDate(true);
        //        setErrMessageEndDate(Messages.ManageProject.greaterThanEndDate);
        //        isValid = false;
        //    }
        //}

        if (project.projectCode === undefined || project.projectCode === null || project.projectCode === "") {
            setErrProjectCode(true);
            setErrMessageProjectCode(Messages.ManageProject.requiredProjectCode);
            isValid = false;
        }
        else {
            const obj = props.gridRowData.filter(
                (x) => x.projectCode != null && x.projectCode.toLocaleLowerCase() === project.projectCode.toLocaleLowerCase().trim() && x.id !== project.id);
              if (obj.length > 0) {
                setErrProjectCode(true);
                setErrMessageProjectCode(Messages.ManageProject.existsProjectCode);
                isValid = false;
                return false;
              }

            if (project.projectCode.length > 8 || project.projectCode.length < 8) {
              setErrProjectCode(true);
              setErrMessageProjectCode(Messages.ManageProject.invalidProjectCodeLength);  
              isValid = false;      
              return false; 
              }
            if (project.projectCode.length > 0 && obj.length === 0) {
                const regex = /^[0-9]{8}$/g;
                if (!project.projectCode.match(regex)) {
                    setErrProjectCode(true);
                    setErrMessageProjectCode(Messages.ManageProject.invalidProjectCode);  
                    isValid = false;                  
                }
            } 
        }

        if (isValid) {
            return true;
        } else {
            return false;
        }
    };

    const saveProject = async () => {
        // check validation for project form
        if (validateProjectForm(manageProject)) {
          let saveProject: IManageProjectModel = {
            id: manageProject.id,
            projectCode: manageProject.projectCode,
            projectTitle: manageProject.projectTitle,
            description: manageProject.description,
            isTracked: manageProject.isTracked,
            startDate: manageProject.startDate,
            endDate: manageProject.endDate,
            isActive: manageProject.isActive,
            createdBy: +window.UserId
          };

          // save parent project
          var response = await ProjectServices.SaveProject(saveProject);
          if (response.success && !response.hasErrors && response.value) {
            Notify(NotifyType.success, props.IsEditMode ? Messages.ManageProject.updated : Messages.ManageProject.saved);
            setManageProject(defaultValues);
            onClose();
          } else {
            Notify(NotifyType.error, Messages.ManageProject.error);
          }
        }
    };

    return (
        <div>
          <Dialog
            open={props.Open}
            onClose={props.onCancel}
            className="confirm-dialog addForm custom-modal"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {props.DialogTitle}
              <IconButton
                aria-label="close"
                onClick={resetProject}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 13,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
            <div>
                  <div className="form-group mb-4">
                    <TextField
                      id="outlined-basic"
                      label="Project Number"
                      placeholder="Project number format : 12345000"
                      name="projectCode"
                      value={manageProject.projectCode}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      required
                      helperText={errProjectCode ? errMessageProjectCode : ""}
                      error={errProjectCode}
                      onBlur={(e) => {
                        handleErrors(e.target.value, 'ProjectCode');
                      }}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <TextField
                      id="outlined-basic"
                      label="Project Title"
                      name="projectTitle"
                      value={manageProject.projectTitle}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      required
                      helperText={errProjectTitle ? errMessageProjectTitle : ""}
                      error={errProjectTitle}
                      onBlur={(e) => {
                        handleErrors(e.target.value, 'ProjectTitle');
                      }}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <TextField
                      id="outlined-basic"
                      label="Description"
                      name="description"
                      value={manageProject.description}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      required
                      helperText={errDescription ? errMessageDescription : ""}
                      error={errDescription}
                      onBlur={(e) => {
                        handleErrors(e.target.value, 'Description');
                      }}
                    />
                  </div>
                  {props.IsEditMode ? (null
                  // <div className="row" hidden={true}>
                  //     <div className="col-12 col-sm-6">
                  //       <div className="form-group mb-4 fullwidth">
                  //         <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //             <DesktopDatePicker
                  //                 label="Start Date"
                  //                 inputFormat="MM/DD/YYYY"
                  //                 value={manageProject.startDate}
                  //                 onChange={(e) => {handleChange(e, 'StartDate')}}
                  //                 renderInput={(params) => <TextField {...params }
                  //                 inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }} 
                  //                 helperText={errStartDate ? errMessageStartDate : ""}
                  //                 //required
                  //                 error={errStartDate}
                  //                 onBlur={(e) => {
                  //                   handleErrors(e.target.value, 'StartDate');
                  //                 }} />}
                  //                 disabled={isReadOnly}
                  //             />
                  //         </LocalizationProvider>
                  //       </div>
                  //     </div>
                  //     <div className="col-12 col-sm-6">
                  //       <div className="form-group mb-4 fullwidth">
                  //         <LocalizationProvider dateAdapter={AdapterDayjs}>
                  //             <DesktopDatePicker
                  //                 label="End Date"
                  //                 inputFormat="MM/DD/YYYY"
                  //                 value={manageProject.endDate}
                  //                 onChange={(e) => {handleChange(e, 'EndDate')}}
                  //                 renderInput={(params) => <TextField {...params} 
                  //                 inputProps={{ ...params.inputProps, placeholder: "MM/DD/YYYY" }} 
                  //                 //required
                  //                 helperText={errEndDate ? errMessageEndDate : ""}
                  //                 error={errEndDate}
                  //                 onBlur={(e) => {
                  //                     handleErrors(e.target.value, 'EndDate');
                  //                 }}/>}
                  //                 disabled={isReadOnly}
                  //             />
                  //         </LocalizationProvider>
                  //       </div>
                  //     </div>
                  // </div>
                   ) : (
                    null
                  )}
                  
                  <div>
                    {
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={manageProject.isTracked}
                            onChange={(e) => {
                                setManageProject({
                                  ...manageProject,
                                  isTracked: e.target.checked,
                                });
                            }}
                          />
                        }
                        label="Tracked"
                      />
                    }
                  </div>
                  <div>
                    {
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={manageProject.isActive}
                            onChange={(e) => {
                                setManageProject({
                                  ...manageProject,
                                  isActive: e.target.checked,
                                });
                            }}
                          />
                        }
                        label="Active"
                      />
                    }
                  </div>
                </div>
              {/* {!isLoadSaveProject ? (
              ) : (
                <div className="loader">
                   <Loading /> 
                </div>
              )} */}
            </DialogContent>
            <DialogActions>
              <button
                type="button"
                className="btn btn-secondary btn-icon btn-sm"
                onClick={resetProject}
              >
                Close <CloseIcon />
              </button>
              {!props.IsEditMode ? (
                <button
                  type="button"
                  className="btn btn-primary btn-icon btn-sm"
                  onClick={saveProject} //validateUser
                >
                  Add <PersonAddAlt1Icon />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-icon btn-sm"
                  onClick={saveProject} //validateUser
                >
                  Update <CreateIcon />
                </button>
              )}
            </DialogActions>
          </Dialog>
          <AlertDialogBox
            Open={alertOpen}
            DialogTitle={Messages.ManageUser.userAlert}
            DialogContentText={alertMessage}
            onClose={closeAlertDialogBox}
          />
        </div>
    );
};