import {
  IProjectsModel
} from "../models/IUser";
import { Result } from "../models/Result";
import { ServiceBase } from "../services/ServiceBase";
import { IManageCrewModel } from "./ICrew";

export default class CrewServices extends ServiceBase {
  public static async GetCrewDetails(
    supervisorId: number
  ): Promise<Result<IManageCrewModel[]>> {
    var result = await this.requestJson<IManageCrewModel[]>({
      url: `api/crew/getCrewDetails/${supervisorId}`,
      method: "GET",
    });
    return result;
  }

  public static async GetProjects(
    projectId: number
  ): Promise<Result<IProjectsModel[]>> {
    var result = await this.requestJson<IProjectsModel[]>({
      url: `api/project?id=${projectId}`,
      method: "GET",
    });
    return result;
  }

  public static async SaveCrew(manageCrew: IManageCrewModel): Promise<Result<IManageCrewModel>> {
    var result = await this.requestJson<IManageCrewModel>({
      url: `api/crew/Upsert`,
      method: "POST",
      data: manageCrew,
    });

    return result;
  }
}
