import React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowsProp, GridRowModesModel, GridEventListener, GridRowEditStopReasons, GridRowModel, GridActionsCellItem } from "@mui/x-data-grid";
import ProjectSelectionServices from "./ProjectSelectionServices";
import { Loading } from "../Shared/Loading";
import { Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, TextField, } from "@mui/material";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { IUpsertFavProjectModel } from "./IProjectSelection";
import Notify from "../Shared/notify";
import { Messages, NotifyType } from "../Shared/constant";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from 'react-router-dom';

const initialRows: GridRowsProp = [];

export default function ProjectSelection() {
  const [rows, setRows] = React.useState(initialRows);
  const [projectList, setProjectList] = React.useState(initialRows);
  const [isLoadProjectSelectionData, setIsLoadProjectSelectionData] = React.useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const navigate = useNavigate();

  useEffect(() => {
    getProjectDetails(true);
  }, []);

  const getProjectDetails = async (isLoad: boolean) => {
    const userId = +window.UserId;
    if (isLoad) {
      setIsLoadProjectSelectionData(false);
    }
    ProjectSelectionServices.GetFavouriteProjects(userId).then((res) => {
      if (!res.hasErrors && res.value) {
        const projects = res.value;
        setProjectList(projects);
        setRows(projects);
        if (isLoad) {
          setIsLoadProjectSelectionData(true);
        }
      }
    });
  };

  // const getFavProjectDetails = async () => {
  //   ProjectSelectionServices.GetFavouriteProjects().then((res) => {
  //     if (!res.hasErrors && res.value) {
  //       const projects = res.value;
  //       setProjectList(projects);
  //       setRows(projects);
  //     }
  //   });
  // };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleFavouriteClick =
    (projectId: number, flag: boolean) => async () => {
      let favProject: IUpsertFavProjectModel = {
        id: projectId,
        userId: +window.UserId,
        isFavourite: flag,
      };
      var response = await ProjectSelectionServices.upsertFavouriteProject(
        favProject
      );
      getProjectDetails(false);
      Notify(NotifyType.success, Messages.ProjectSelection.upsert);
    };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRows(event.target.checked ? projectList.filter((x) => x.isFavourite === event.target.checked) : projectList);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/crewSelection?projectId=${params.id}`);
  };

  function search(value: string) {
    if (value !== "") {
      setRows(projectList.filter((x) => x.projectTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || x.projectCode.includes(value)));
    } else {
      setRows(projectList);
    }
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      type: "actions",
      headerName: "",
      width: 75,
      cellClassName: "actions",
      getActions: (params: any) => {
        if (params.row.isFavourite) {
          return [
            <GridActionsCellItem
              icon={<StarRateIcon />}
              label=""
              className="textPrimary"
              onClick={handleFavouriteClick(params.id, false)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<StarBorderIcon />}
              label=""
              className="textPrimary"
              onClick={handleFavouriteClick(params.id, true)}
              color="inherit"
            />,
          ];
        }
      },
    },
    {
      field: "projectCode",
      headerName: "Project Number",
      width: 150,
      editable: false
    },
    {
      field: "projectTitle",
      headerName: "Project Title",
      width: 250,
      editable: false
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      editable: false,
    },
  ];

  return (
    <>

      <div className="page-title-box">
        <h1 className="page-title">Project Selection</h1>
      </div>

      <div className="card user-detail-card pages-detail-card">
        <div className="card-body">
          <div className="dataTables_wrapper">
            <div className="row mb-2">
              <div className="col-sm-12 col-md-8 d-flex">
                <TextField
                  id="standard-bare"
                  variant="outlined"
                  placeholder="Search..."
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <Box className="fieldset-container" sx={{ display: "flex" }}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox onChange={handleChange} />}
                        label="My Favorite Projects"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
          <div className="grid_wrapper autoheightcell">
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              {isLoadProjectSelectionData ? (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  //autoHeight={true}
                  editMode="row"
                  className="user-details-grid custom-details-grid"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  onRowClick={handleRowClick}
                  slotProps={{
                    toolbar: { setRows, setRowModesModel },
                  }}
                  sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    }
                  }}
                />
              ) : (
                <div className="loader">
                  <Loading />
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>

    </>
  );
}