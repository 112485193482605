import { Result } from "../models/Result";
import { ServiceBase } from "../services/ServiceBase";
import { IAccountSummaryModel, IActivityComplete, IActivityPageReportRequest, IChartData, IManageForecastOverride, IManageSummaryOverride, IReportNotes, ISummaryReportModel, ISummaryReportRequest } from "./IReports";

export default class ReportServices extends ServiceBase {
  public static async GetAccountSummary(projectId: number, weekEndDate: string, isSubmitted: number): Promise<Result<IAccountSummaryModel[]>> {
    var result = await this.requestJson<IAccountSummaryModel[]>({
      url: `api/reports/getAccountSummary?projectId=${projectId}&weekEndDate=${weekEndDate}&isSubmitted=${isSubmitted}`,
      method: "GET",
    });
    return result;
  }

  public static async GetTimeEntrySummary(projectId: string, startDate: string, endDate: string): Promise<Result<IAccountSummaryModel[]>> {
    var result = await this.requestJson<IAccountSummaryModel[]>({
      url: `api/reports/getTimeEntryDataSummary?projectIds=${projectId}&startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });
    return result;
  }

  public static async GetSummaryReport(summaryReportRequest: ISummaryReportRequest): Promise<Result<ISummaryReportModel[]>> {
    var result = await this.requestJson<ISummaryReportModel[]>({
      url: `api/reports/summaryReport`,
      method: "POST",
      data: summaryReportRequest,
    });
    return result;
  }

  public static async UpdateActiivtyComplete(activityModel: IActivityComplete): Promise<Result<boolean>> {
    var result = await this.requestJson<boolean>({
      url: `api/activity/activityComplete`,
      method: "POST",
      data: activityModel,
    });
    return result;
  }

  public static async SaveSummaryOverrides(manageSummaryOverride: IManageSummaryOverride) {
    var result = await this.requestJson<IManageSummaryOverride>({
      url: `api/reports/addsummary`,
      method: "POST",
      data: manageSummaryOverride
    });
    return result;
  }

  public static async SaveForecastOverrides(manageForecastOverride: IManageForecastOverride) {
    var result = await this.requestJson<IManageForecastOverride>({
      url: `api/reports/forecastOverride`,
      method: "POST",
      data: manageForecastOverride
    });
    return result;
  }

  public static async GetReportNotes(activityPageReportRequest: IActivityPageReportRequest): Promise<Result<IReportNotes[]>> {
    var result = await this.requestJson<IReportNotes[]>({
      url: `api/reports/reportNotes`,
      method: "POST",
      data: activityPageReportRequest,
    });
    return result;
  }

  public static async GetReportChartData(activityId: number): Promise<Result<IChartData[]>> {
    var result = await this.requestJson<IChartData[]>({
      url: `api/reports/getchart?activityId=${activityId}`,
      method: "GET",
    });
    return result;
  }
}
