import React from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../assets/NavMenu.css';
import logo from '../../assets/images/hswc-logo.png';
//import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Roles, UserRoleType } from '../Shared/constant';
import { Loading } from "../Shared/Loading";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import UserServices from '../services/UserServices';


export function NavMenu() {

  const [collapsed, setcollapsed] = React.useState<boolean>(true);
  const [isLoadData, setIsLoadData] = React.useState<boolean>(false);

  async function toggleNavbar() {
    setcollapsed(collapsed ? false : true);
  }

  const downloadAPK = async () => {
    setIsLoadData(true);
    await UserServices.DownloadAPK().then((res) => {
      if (!res.hasErrors && res.value) {
        // file downloaded
        setIsLoadData(false);
      }
    });
  }

  const downloadIOS = async () => {
    setIsLoadData(true);
    await UserServices.DownloadIOS().then((res) => {
      if (!res.hasErrors && res.value) {
        // file downloaded
        setIsLoadData(false);
      }
    });
  }

  return (
    <header>
      <div>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white custom-navbar" container light>
          <NavbarBrand tag={Link} to="/">
            <img src={logo} alt="HSWC Logo" className="site-logo" />
          </NavbarBrand>
          {/* <NavbarToggler onClick={toggleNavbar} className="mr-2" /> */}

          <Collapse className="d-sm-inline-flex" isOpen={collapsed} navbar>
            {window.RoleId === UserRoleType.Admin.toString() ||
              window.RoleId === UserRoleType.ProjectManager.toString() ||
              window.RoleId === UserRoleType.Accountant.toString() ||
              window.RoleId === UserRoleType.ProjectEngineer.toString() ||
              window.RoleId === UserRoleType.Supervisor.toString() ? (
              <><ul className="navbar-nav flex-grow custom-navbar-navigation navbar-left-navigation">
                {/* <NavItem className="nav-toggle-link">
                <NavLink tag={Link} to="#" className="nav-icon" onClick={toggleNavbar}><MenuIcon /></NavLink>
              </NavItem> */}


                <><NavItem>
                  <NavLink tag={Link} to="/timeEntry">Timesheet</NavLink>
                </NavItem><UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Reports
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem tag={Link} to="/accountSummary">JDE Grid</DropdownItem>
                      <DropdownItem tag={Link} to="/timeEntryExport">Timedata</DropdownItem>
                      <DropdownItem tag={Link} to="/summaryReport">Work Package Reporting</DropdownItem>
                      <DropdownItem tag={Link} to="/activityPageReport">Activity-on-a-Page</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown></>

              </ul><ul className="navbar-nav flex-grow custom-navbar-navigation navbar-right-navigation">
                  <NavItem>
                    <div className="mobile-apps">
                      <span className="mobile-apps-label">Mobile Apps</span>
                      <NavLink className="mobile-android" onClick={downloadAPK}><AndroidIcon /></NavLink>
                      <NavLink onClick={downloadIOS}><AppleIcon /></NavLink>
                    </div>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Admin
                    </DropdownToggle>
                    <DropdownMenu>
                      {window.RoleId !== UserRoleType.ProjectEngineer.toString() &&
                        window.RoleId !== UserRoleType.Supervisor.toString() ? (
                        <><DropdownItem tag={Link} to="/userDetails">User Management</DropdownItem>
                          <DropdownItem tag={Link} to="/projectDetails">Projects</DropdownItem>
                          <DropdownItem tag={Link} to="/crewDetails">Crews</DropdownItem>
                          <DropdownItem tag={Link} to="/crewList">Craft Employees</DropdownItem>
                          <DropdownItem tag={Link} to="/activityBudgetList">Estimate</DropdownItem></>
                      ) : (
                        <>
                          <DropdownItem tag={Link} to="/crewDetails">Crews</DropdownItem>
                          <DropdownItem tag={Link} to="/crewList">Craft Employees</DropdownItem>
                          <DropdownItem tag={Link} to="/activityBudgetList">Estimate</DropdownItem></>
                      )}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <NavItem>
                  <NavLink tag={Link} to="#" className="nav-icon search-icon"><SearchIcon /></NavLink>
                </NavItem> */}
                  <NavItem className="user-menu">
                    <NavLink tag={Link} to="#" className="nav-icon user-icon"><span className="user-label">{window.UserName + ' (' + window.RoleName + ')'}</span><AccountCircleIcon /></NavLink>
                  </NavItem>
                </ul></>
            ) :
              (
                <><ul className="navbar-nav flex-grow custom-navbar-navigation navbar-left-navigation general-navbar-navigation">
                  {/* <NavItem className="nav-toggle-link">
                  <NavLink tag={Link} to="#" className="nav-icon" onClick={toggleNavbar}><MenuIcon /></NavLink>
                </NavItem> */}


                  <><NavItem>
                    <NavLink tag={Link} to="/timeEntry">Timesheet</NavLink>
                  </NavItem><UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Reports
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag={Link} to="/summaryReport">Work Package Reporting</DropdownItem>
                        <DropdownItem tag={Link} to="/activityPageReport">Activity-on-a-Page</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown></>
                  {/* <NavItem>
                  <NavLink tag={Link} to="#" className="nav-icon search-icon"><SearchIcon /></NavLink>
                </NavItem> */}
                  <NavItem>
                    <div className="mobile-apps">
                      <span className="mobile-apps-label">Mobile Apps</span>
                      <NavLink className="mobile-android" onClick={downloadAPK}><AndroidIcon /></NavLink>
                      <NavLink onClick={downloadIOS}><AppleIcon /></NavLink>
                    </div>
                  </NavItem>
                  <NavItem className="user-menu">
                    <NavLink tag={Link} to="#" className="nav-icon user-icon"><span className="user-label">{window.UserName + ' (' + window.RoleName + ')'}</span><AccountCircleIcon /></NavLink>
                  </NavItem>
                </ul></>
              )}
          </Collapse>
        </Navbar>
      </div>
      <div className={isLoadData? 'header-loader d-block' : 'header-loader d-none'} >
        <Loading />
      </div>
    </header>
  );
}
