  import { Result } from "../../models/Result";
  import { ServiceBase } from "../../services/ServiceBase";
  import { ICrewSelectionModel } from "./ICrewSelection";
  
  export default class CrewSelectionServices extends ServiceBase {
    public static async GetCrewSelectionByProjectId(projectId: number): Promise<Result<ICrewSelectionModel[]>> {
      var result = await this.requestJson<ICrewSelectionModel[]>({
        url: `api/crew/getCrewByProjectId/${projectId}`,
        method: "GET",
      });
      return result;
    }
  }
  