import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Autocomplete } from "@mui/material";
import { Loading } from "../../Shared/Loading";
import { Messages, NotifyType } from "../../Shared/constant";
import Notify from "../../Shared/notify";
import { IManageForecastOverride } from "../IReports";
import ReportServices from "../ReportsServices";

//#region Model Declares
export type IProps = {
  DialogTitle: string;
  DialogContentText: string;
  ButtonYes: string;
  ButtonNo: string;
  onCancel?: any | null;
  Open: boolean;
  onClose?: any | null;
  rowData: any;
  OpenForecastModalEdit?: any | null;
  setReloadModalPopup: any;
  ReloadModalPopup: boolean;
};

const defaultValues: IManageForecastOverride = { activityId: 0, productionAction: undefined, productionForecast: undefined, crewRateAction: undefined, crewRateForecast: undefined, notes: undefined, dateValues: undefined, createdBy: 0 };
//#endregion
export const ForecastOverrideModalDialog: React.FunctionComponent<IProps> = (props) => {
  //#region Variable Declaration
  //const declaration
  const { onClose } = props;
  const { onCancel } = props;
  const [isLoadSaveForecastOverrides, setIsLoadSaveForecastOverrides] = React.useState<boolean>(false);
  const [isShowProductionForecast, setIsShowProductionForecast] = React.useState<boolean>(false);
  const [isShowCrewRateForecast, setIsShowCrewRateForecast] = React.useState<boolean>(false);
  const productionActions: string[] = ['Set to: Manually', 'Set to: Current Date Filtered Values', 'Set to: Current To-Date Rate', 'Set to: Current Projected Rate',
    'Set to: Current Budgeted Rate', 'Set to: Current Break-Even Rate'];
  const crewRateActions: string[] = ['Set to: Manually', 'Set to: Current Date Filtered Values', 'Set to: Current To-Date Rate', 'Set to: Current Projected Rate',
    'Set to: Current Budgeted Rate'];  
  const [manageForecastOverrides, setManageForecastOverrides] = React.useState(defaultValues);
  const isNullUndefined = (value: any) => value === null || value === undefined;
  //#endregion

  //#region UserEffect
  React.useEffect(() => {
    resetForecastOverrides();
  }, []);

  React.useEffect(() => {
    setIsShowProductionForecast(false);
    setIsShowCrewRateForecast(false);
    setManageForecastOverrides(defaultValues);
    props.setReloadModalPopup(false);
    props.OpenForecastModalEdit();
  }, [props.rowData.activityId, props.ReloadModalPopup]);
  //#endregion

  //#region PageLoad Methods
  const handleInputChange = (e: any, cntrlName: string) => {
    const { name, value } = e.target;
    switch (cntrlName) {
      case 'ProductionForecast':
        setManageForecastOverrides({
          ...manageForecastOverrides,
          productionForecast: value
        });
        break;
      case 'CrewRateForecast':
        setManageForecastOverrides({
          ...manageForecastOverrides,
          crewRateForecast: value
        });
        break;
      case 'Notes':
        setManageForecastOverrides({
          ...manageForecastOverrides,
          notes: value
        });
        break;
    }
  };

  const saveForecastOverrides = async () => {
    if (!isNullUndefined(manageForecastOverrides.productionAction) || !isNullUndefined(manageForecastOverrides.crewRateAction)) {
      let saveForecastOverride: IManageForecastOverride = {
        activityId: props.rowData.activityId,
        productionAction: manageForecastOverrides.productionAction,
        productionForecast: manageForecastOverrides.productionForecast,
        crewRateAction: manageForecastOverrides.crewRateAction,
        crewRateForecast: manageForecastOverrides.crewRateForecast,
        notes: manageForecastOverrides.notes,
        dateValues: props.rowData.dateValues,
        createdBy: parseInt(window.UserId)
      };
      setIsLoadSaveForecastOverrides(true);
      var response = await ReportServices.SaveForecastOverrides(saveForecastOverride);
      if (response.success && !response.hasErrors) {
        setIsLoadSaveForecastOverrides(false);
        Notify(NotifyType.success, Messages.ForecastOverride.saved);
        setManageForecastOverrides(defaultValues);
        onClose();
      } else {
        setIsLoadSaveForecastOverrides(false);
        Notify(NotifyType.error, Messages.ManageUser.error);
      }
    };
  }

  //#endregion

  //#region internal methods
  const resetForecastOverrides = async () => {
    setManageForecastOverrides(defaultValues);
    onCancel();
  };

  const handleChange = (value: string, cntrlName: string) => {
    if (value === undefined || value === null || value === "") {
      switch (cntrlName) {
        case 'ProductionForecastAction':
          setManageForecastOverrides({
            ...manageForecastOverrides,
            productionAction: null,
            productionForecast: null
          });
          break;
        case 'CrewRateForecastAction':
          setManageForecastOverrides({
            ...manageForecastOverrides,
            crewRateAction: null,
            crewRateForecast: null
          });
          break;
      }
    } else {
      let newValue = null;
      switch (cntrlName) {
        case 'ProductionForecastAction':
          if (value.toLocaleLowerCase() === productionActions[0].toLocaleLowerCase()) {
            setIsShowProductionForecast(true);
            setManageForecastOverrides({
              ...manageForecastOverrides,
              productionAction: value,
              productionForecast: null
            });
          } else {
            setIsShowProductionForecast(false);
            if (value.toLocaleLowerCase() === productionActions[1].toLocaleLowerCase()) {
              newValue = props.rowData.actualProduction;
            } else if (value.toLocaleLowerCase() === productionActions[2].toLocaleLowerCase()) {
              newValue = props.rowData.toDateProduction;
            } else if (value.toLocaleLowerCase() === productionActions[3].toLocaleLowerCase()) {
              newValue = props.rowData.projectedProduction;
            } else if (value.toLocaleLowerCase() === productionActions[4].toLocaleLowerCase()) {
              newValue = props.rowData.budgetProduction;
            } else {
              newValue = props.rowData.requiredProduction;
            }
            setManageForecastOverrides({
              ...manageForecastOverrides,
              productionAction: value,
              productionForecast: newValue,
            });
          }
          break;
        case 'CrewRateForecastAction':
          if (value.toLocaleLowerCase() === crewRateActions[0].toLocaleLowerCase()) {
            setIsShowCrewRateForecast(true);
            setManageForecastOverrides({
              ...manageForecastOverrides,
              crewRateAction: value,
              crewRateForecast: null
            });
          } else {
            setIsShowCrewRateForecast(false);
            if (value.toLocaleLowerCase() === crewRateActions[1].toLocaleLowerCase()) {
              newValue = props.rowData.actualCrewRate;
            } else if (value.toLocaleLowerCase() === crewRateActions[2].toLocaleLowerCase()) {
              newValue = props.rowData.toDateCrewRate;
            } else if (value.toLocaleLowerCase() === crewRateActions[3].toLocaleLowerCase()) {
              newValue = props.rowData.projectedCrewRate;
            } else {
              newValue = props.rowData.budgetCrewRate;
            }
            setManageForecastOverrides({
              ...manageForecastOverrides,
              crewRateAction: value,
              crewRateForecast: newValue
            });
          }
          break;
      }
    }
  };

  //#endregion

  return (
    <div>
      <Dialog
        open={props.Open}
        onClose={props.onCancel}
        className="confirm-dialog addForm custom-modal"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {props.DialogTitle}
          <IconButton
            aria-label="close"
            onClick={resetForecastOverrides}
            sx={{
              position: "absolute",
              right: 10,
              top: 13,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          {!isLoadSaveForecastOverrides ? (
            <div>
              <div className="form-group mb-4">
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Activity"
                  defaultValue={props.rowData.combinedName}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className="form-group mb-4">
                <Autocomplete
                  limitTags={2}
                  id="drpProjects"
                  options={productionActions}
                  value={manageForecastOverrides.productionAction}
                  filterSelectedOptions
                  sx={{ minWidth: 200 }}
                  onChange={(event: any, newValue: string | null) => {
                    handleChange(newValue, 'ProductionForecastAction');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Production Forecast Action"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        placeholder: "Production Forecast Action",
                      }} />
                  )} />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="Production Forecast"
                  name="productionForecast"
                  type="number"
                  value={manageForecastOverrides.productionForecast}
                  onChange={(event: any) => {
                    handleInputChange(event, 'ProductionForecast');
                  }}
                  variant="outlined"
                  hidden={!isShowProductionForecast}
                  disabled={!isShowProductionForecast}
                  fullWidth
                />
              </div>
              <div className="form-group mb-4">
                <Autocomplete
                  limitTags={2}
                  id="drpProjects"
                  options={crewRateActions}
                  value={manageForecastOverrides.crewRateAction}
                  filterSelectedOptions
                  sx={{ minWidth: 200 }}
                  onChange={(event: any, newValue: string | null) => {
                    handleChange(newValue, 'CrewRateForecastAction');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Crew Rate Action"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        placeholder: "Crew Rate Action",
                      }} />
                  )} />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-basic"
                  label="CrewRate Forecast"
                  name="crewRateForecast"
                  type="number"
                  value={manageForecastOverrides.crewRateForecast}
                  onChange={(event: any) => {
                    handleInputChange(event, 'CrewRateForecast');
                  }}
                  variant="outlined"
                  hidden={!isShowCrewRateForecast}
                  disabled={!isShowCrewRateForecast}
                  fullWidth
                />
              </div>
              <div className="form-group mb-4">
                <TextField
                  id="outlined-textarea"
                  aria-label="maximum height"
                  placeholder="Notes...."
                  name="notes"
                  value={manageForecastOverrides.notes}
                  onChange={(event: any) => {
                    handleInputChange(event, 'Notes');
                  }}
                  multiline={true}
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: { resize: "both" } }}
                />
              </div>
            </div>
          ) : (
            <div className="loader custom-loader">
              <Loading />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary btn-icon btn-sm"
            onClick={resetForecastOverrides}
          >
            Close <CloseIcon />
          </button>
          {
            <button
              type="button"
              className="btn btn-primary btn-icon btn-sm"
              onClick={saveForecastOverrides}
            >
              Save <PersonAddAlt1Icon />
            </button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};
