import { Result } from "../models/Result";
import { ServiceBase } from "../services/ServiceBase";
import { IActivityBudgetModel } from "./IActivityBudget";


export default class ActivityBudgetListServices extends ServiceBase {

    public static async GetActivityBudgetList(): Promise<Result<IActivityBudgetModel[]>> {
        var result = await this.requestJson<IActivityBudgetModel[]>({
            url: `api/activitybudget/getActivityBudget`,
            method: "GET"
        });
        return result;
    }

    public static async UploadActivityBudgetist(form: FormData): Promise<Result<any>> {
        var result = await this.sendFormData<any>({
            url: `api/activitybudget/fileupload`,
            data: form,
            method: "POST"
        });
        return result;
    }
}